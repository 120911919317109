const getters = {
  device: (state) => state.app.device,
  appname: (state) => localStorage.getItem("appName") || state.app.appname,
  language: (state) => state.app.language,
  pageInfo: (state) => state.app.pageInfo,
  gameUrl: (state) => state.app.gameUrl,
  token: (state) => state.user.token,
  balance: (state) => state.user.balance,
  openLogin: (state) => state.user.openLogin,
  showReg: (state) => state.user.showReg,
  userInfo: (state) => state.user.userInfo,
  openOperate: (state) => state.user.openOperate,
  operate: (state) => state.user.operate,
  showPop: (state) => state.user.showPop,
};
export default getters;
