<template>
  <div class="recharge">
    <div class="recharge-top">
      <!-- 标题 -->
      <div class="header-wrap">
        <div class="header-wrap-header">
          <page-header
            :isHome="false"
            :isTabPage="false"
            :pageTitle="pageData?.pageName"
          />
        </div>
      </div>
      <!-- 通知 -->
      <van-notice-bar
        color="#ED7B2F"
        background="#FEF3E6"
        left-icon="volume-o"
        mode="closeable"
        v-show="notice"
      >
        {{ notice }}
      </van-notice-bar>

      <!-- 输入框 -->
      <div class="recharge-block">
        <div class="blockWidth recharge-block-mainTitle">
          {{ pageData?.listName }}
        </div>
        <van-field
          v-model="rechargeAmount"
          :label="pageData?.listOptionName"
          placeholder=""
          :border="false"
          class="blockWidth account-field"
          label-class="account-field-label"
          type="number"
        />
        <div class="blockWidth recharge-desc recharge-desc-short">
          {{ $t("wallet.eachRechargeLimit") }}: {{ pageData?.minAmount }}-{{
            pageData?.maxAmount
          }}
        </div>

        <!-- 快捷选择 -->
        <div class="blockWidth recharge-choose">
          <div
            :class="[
              'recharge-choose-item',
              { choosed: item.amount == rechargeAmount },
            ]"
            v-for="item in amountList"
            :key="item.amount"
            @click="onChooseAmount(item.amount)"
          >
            <p class="right-top-fixed" v-show="item.activity">
              {{ item.activity }}
            </p>
            {{ item.amount }}
          </div>
        </div>
      </div>

      <div class="blockWidth recharge-block-mainTitle">
        {{ pageData?.optionName }}
      </div>
      <!-- 折叠面板 -->
      <div class="recharge-block2">
        <van-collapse
          v-model="activeNames"
          :is-link="false"
          :border="false"
          v-for="item in paymentList"
          :key="item.typeName"
        >
          <van-collapse-item
            :title="item.typeName"
            :name="item.typeName"
            :border="false"
            title-class="collapse-label"
          >
            <van-radio-group v-model="payMethodCode">
              <custom-radio
                :radioOption="i.paymentCode"
                :key="i.paymentCode"
                v-for="i in item.paymentList"
                :logo="i.paymentLogo"
                :text="i.paymentName"
                @onChoosed="onChoosed"
              >
                {{ i.paymentName }}
              </custom-radio>
            </van-radio-group>
          </van-collapse-item>
        </van-collapse>
      </div>

      <!-- 说明 -->
      <!-- <div class="blockWidth">Pengingat</div> -->
      <div class="blockWidth recharge-desc">
        {{ pageData?.readme }}
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="page-bottom">
      <div class="page-bottom-fixed">
        <van-button
          class="hasColor"
          block
          :loading="loading"
          :disabled="rechargeAmount <= 0 || !payMethodCode"
          @click="goConfirm"
        >
          {{ pageData?.buttonName }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NoticeBar,
  Field,
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
} from "vant";
import CustomRadio from "./CustomRadio.vue";
import { rechargePage, apiRecharge } from "@api/wallet";

export default {
  name: "rechargeView",
  components: {
    CustomRadio,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  props: {},
  data() {
    return {
      rechargeAmount: 0,
      notice: "",
      activeNames: [],
      payMethodCode: "",
      payMethodName: "",
      pageData: null,
      paymentList: [],
      amountList: [],
      loading: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    onChooseAmount(amount) {
      this.rechargeAmount = amount;
    },
    onChoosed(e) {
      this.payMethodName = e;
    },
    onGetData() {
      rechargePage().then((res) => {
        this.pageData = res?.data;
        this.amountList = res.data.amounts;
        this.rechargeAmount = res.data.defaultAmount;
        this.notice = res.data?.notice?.noticeContent;
        this.paymentList = res.data?.payment;
        this.activeNames.push(res.data?.payment[0].typeName);
        this.fillRadio(this.paymentList);
      });
    },
    fillRadio(list) {
      list.forEach((element) => {
        element.paymentList.forEach((item) => {
          if (item.choose) {
            this.payMethodCode = item.paymentCode;
          }
        });
      });
    },
    goConfirm() {
      this.loading = true;
      apiRecharge({
        paymentChannel: this.payMethodCode,
        amount: this.rechargeAmount,
      })
        .then((res) => {
          if (res.code == 200) {
            let type = res.data.type,
              tips = "",
              copyNo = "",
              htmlData = "";
            this.transNo = res.data.transNo;

            switch (type) {
              case "url":
                this.$toLink(res.data.data);
                this.loading = false;
                // window.location.href = res.data.data;
                break;
              case "qrcode":
                tips = res.data.howToPay;
              // eslint-disable-next-line no-fallthrough
              case "orderNo":
                copyNo = res.data.data;
              // eslint-disable-next-line no-fallthrough
              case "html":
                htmlData = res.data.data;
                this.loading = false;
                this.$router.push({
                  path: "/confirm",
                  query: {
                    amount: this.rechargeAmount,
                    text: this.payMethodName,
                    unit: this.pageData.listOptionName,
                    tips: tips,
                    copyNo: copyNo,
                    htmlData: htmlData,
                  },
                });

                break;
              default:
                break;
            }
          } else {
            this.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
      // this.$router.push({
      //   path: "/confirm",
      //   query: {
      //     amount: this.rechargeAmount,
      //     code: this.payMethodCode,
      //     text: this.payMethodName,
      //     unit: this.pageData.listOptionName,
      //   },
      // });
    },
  },
  created() {
    this.onGetData();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.recharge {
  &-top {
    flex: 1;
    overflow: auto;
    padding-bottom: 40px;
  }
  // height: 100vh;
  color: $text-gray4;
  position: relative;
  font-size: 28px;
  @include flexColumn;
  align-items: unset;
  &-desc {
    font-size: 24px;
    line-height: 1.5;
    white-space: pre-wrap;
  }
  &-desc-short {
    padding: 0 18px;
    margin: 30px auto;
    box-sizing: border-box;
  }
  &-block {
    padding: 20px 0;
    background: $block-main;

    margin-bottom: 42px;
    &-mainTitle {
      color: #fff;
      padding: 20px 0 12px 0;
      font: {
        size: 32px;
        weight: bold;
      }
    }
  }
  &-block2 {
    @extend .recharge-block;
    padding: 0;
  }
  &-choose {
    @include flexRow;
    gap: 22px 10px;
    flex-wrap: wrap;
    margin: {
      top: 30px;
    }
    &-item {
      cursor: pointer;
      border: 2px solid #111423;
      background: #111423;
      border-radius: 12px;
      width: 226px;
      height: 85px;
      font-weight: 500;
      @include flexRowCenter;
      color: $text-gray;
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      .right-top-fixed {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        background: #159046;
        border-radius: 0 0 0 12px;
        font-size: 15px;
        color: #fff;
        width: fit-content;
        padding: 4px 8px;
      }
    }
    &-item.choosed {
      border: 2px solid #159046;
    }
  }
}
</style>
