<template>
  <div id="app">
    <router-view />
    <component v-bind:is="currentLogin"></component>
    <!-- <login />
    <pc-login /> -->
    <pc-operate />
    <page-footer v-show="device != 'desktop'" />
  </div>
</template>
<script>
import ResizeMixin from "@/mixins/ResizeHandler";
import { mapGetters } from "vuex";
import MobileLogin from "@views/account/login.vue";
import PcLogin from "@views/account/pcLogin.vue";
import pcOperate from "@views/wallet/pcOperate.vue";
export default {
  components: { MobileLogin, PcLogin, pcOperate },
  name: "App",
  mixins: [ResizeMixin],
  props: {},
  data() {
    return {
      // loading: false
      currentLogin: "MobileLogin",
    };
  },
  computed: {
    ...mapGetters(["device"]),
  },
  watch: {
    device: {
      handler(value) {
        if (value == "mobile") {
          this.currentLogin = MobileLogin;
        } else {
          this.currentLogin = PcLogin;
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
#app {
  // font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // width: 100%;
  // height: 100vh;
  background: $main-color;
  --scale: 1;
}
._compLoaing {
  position: absolute;
  top: 40vh;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
}
.isMobile {
  @media screen and (min-width: 720px) {
    display: none !important;
  }
}
.isPc {
  display: none;
  @media screen and (min-width: 720px) {
    display: block;
  }
}
.van-toast {
  @media screen and (min-width: 720px) {
    font-size: 24px;
  }
}
.pop-close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    transform: rotate(-90deg);
    transition: all 0.5s;
  }
}

.logoutStyle {
  .van-dialog__footer .van-button {
    border: none;
  }
  .van-dialog__header {
    color: #fff;
    font-size: 36px;
  }
}
p {
  margin: 0;
}
.container {
  // flex: 1;
  // overflow: scroll;
}
.blockWidth {
  width: calc(100% - 40px) !important;
  margin: auto;
}
.blockWidth60 {
  width: calc(100% - 60px) !important;
  margin: auto;
}
.header-wrap {
  height: 100px;
  @media screen and (min-width: 720px) {
    display: none;
  }
  &-header {
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: 100%;
  }
}
.page-bottom {
  height: 146px;
  width: 100%;
  background: #1c1f2e;
  position: relative;
  @include flexRowCenter;
  .page-bottom-fixed {
    position: fixed;
    @include flexRowCenter;
    bottom: 0;
    left: 0;
    height: 146px;
    right: 0;
    background: #1c1f2e;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.4);
  }
  .hasColor {
    background: $btn-green;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 38px;
    border-radius: 16px;
    height: 104px;
    margin: 0 30px;
  }
}
.van-progress__pivot {
  font-weight: bold;
}
.van-progress__portion {
  border-radius: 0 !important;
}

.wide {
  overflow-x: scroll;
  @include flexRow;
  // padding-right: 20px;
  margin-left: 20px;
  flex-wrap: nowrap;
  gap: 0 20px;
  @media screen and (min-width: 720px) {
    margin-right: 20px;
    overflow-y: visible;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
.profileView .van-cell::after {
  left: 0;
  right: 0;
}
.profileView .van-field__control {
  font-weight: 500;
  font-size: 32px;
}
.login-btn {
  @include flexRowCenter;
  color: #fff;
  font-weight: bold;
  font-size: 42px;
  height: 104px;
  border-radius: 16px;
  margin: 20px auto;
  background: none;
  font-size: 30px;
  border: 2px solid rgba(255, 255, 255, 0.3);

  img {
    width: 54px;
    height: 54px;
    margin-left: 35px;
  }

  &-text {
    flex: 1;
    text-align: left;
    padding-left: 100px;
  }
}
.withdraw {
  .van-notice-bar {
    height: 62px;
    line-height: 62px;
  }
}
.recharge {
  .van-notice-bar {
    height: 62px;
    line-height: 62px;
  }
  .van-cell--clickable:active {
    background-color: #1c1f2e !important;
  }
  .collapse-label {
    color: $text-gray4;
  }
  .van-collapse-item::before {
    content: "";
    width: 100%;
    height: 20px;
    background: #121625;
    display: block;
  }
}

.account-field {
  background: #121625;
  border-radius: 12px;
  font-weight: bold;
  color: #fff;
  font-size: 42px;
  height: 104px;
  line-height: normal;
  align-items: center;

  &-label {
    color: #fff !important;
    width: fit-content !important;
    text-align: left;

    font: {
      weight: bold;
      size: 42px;
    }
  }
}

.wallet-level {
  width: calc(100% - 80px);
  box-sizing: border-box;
  padding: 0 20px;
  height: 92px;
  margin: 20px auto 0 auto;

  background: {
    size: 100% 100%;
    repeat: no-repeat;
    position: left top;
  }

  @include flexRowBetween;

  &-left {
    color: #4d6b0f;
    @include flexRowCenter;
    line-height: normal;
    // align-items: end;
    font: {
      size: 42px;
      weight: 900;
    }

    img {
      width: 54px;
      height: 54px;
      margin-right: 20px;
      display: block;
    }
  }

  &-right {
    @include flexRowCenter;
    color: #a73c00;
    // align-items: end;
    line-height: normal;
    font: {
      size: 22px;
      weight: bold;
    }
  }
}
.withdrawf {
  .van-dropdown-menu__item {
    justify-content: space-between;
  }
  .van-dropdown-menu__bar {
    border-radius: 16px;
  }
  .van-popup {
    background: #222637;
  }
  .van-dropdown-item__content {
    width: calc(100% - 80px);
    margin: auto 40px;
    border-radius: 0 0 8px 8px;
  }
  .van-dropdown-menu__title {
    padding: 0 32px;
  }
  .van-cell::after {
    left: 0;
    right: 0;
  }
  .van-cell {
    color: #fff;
  }
  // .van-dropdown-menu__title::after {
  //   right: -440px;
  // }
}
</style>
