import request from "@/service/request";

export function walletPage() {
  return request({
    url: "/user/dana/page_v2",
    method: "get",
  });
}

export function apiRecord(params) {
  return request({
    url: "/trx/history",
    method: "get",
    params,
  });
}

export function rechargePage(params) {
  return request({
    url: "/setting/deposit-amount_v2",
    method: "get",
    params,
  });
}

export function apiRecharge(data) {
  return request({
    url: "/trx/depositSelf",
    method: "post",
    data,
  });
}

export function withdrawPage(params) {
  return request({
    url: "/trx/withdrawal/first-details-v2",
    method: "get",
    params,
  });
}

export function withdrawFormPage(params) {
  return request({
    url: "/trx/withdrawal/second-details",
    method: "get",
    params,
  });
}

export function withdrawBankList(params) {
  return request({
    url: "/user/bank/available-channel",
    method: "get",
    params,
  });
}

export function userWithdrawInfo(params) {
  return request({
    url: "/user/bank",
    method: "get",
    params,
  });
}

export function apiWithdraw(data) {
  return request({
    url: "/trx/withdrawal",
    method: "post",
    data,
  });
}
