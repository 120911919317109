import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as filters from "./filters"; // global filters
import PageHeader from "@components/PageHeader.vue";
import PageNav from "@components/PageNav.vue";
import PageFooter from "@components/PageFooter.vue";
import GameCollect from "@components/GameCollect.vue";
import GameItem from "@components/GameItem.vue";
import BlockTitle from "@components/BlockTitle.vue";
import compLoading from "@/components/loading.vue";
import "@/utils/flexible.js";
import "normalize.css";
import i18n from "./lang";
// 桌面端mouse事件
import "@vant/touch-emulator";
// import Vconsole from "vconsole";
// new Vconsole();
import { Lazyload, Icon, Button, Toast, Loading } from "vant";
import clipboard from "./directive/clipboard";
import { link } from "@/utils/link.js";
Vue.use(Lazyload).use(Icon).use(Button).use(Toast).use(Loading).use(clipboard);

Vue.component("page-header", PageHeader);
Vue.component("page-nav", PageNav);
Vue.component("page-footer", PageFooter);
Vue.component("game-collect", GameCollect);
Vue.component("game-item", GameItem);
Vue.component("block-title", BlockTitle);
Vue.component("comp-loading", compLoading);
// Vue.config.productionTip = false;

function px2rem(px) {
  // var reg1 = new RegExp("px", "g");
  // let fontSize = document.documentElement.style.fontSize.replace(reg1, "");
  if (/%/gi.test(px)) {
    // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px;
  } else {
    return parseFloat(px) / 37.5 + "rem";
  }
}
Vue.prototype.$px2rem = px2rem;
Vue.prototype.$toLink = link;
Vue.prototype.$openl = () => store.dispatch("user/setOpen", true);

const whiteList = ["/", "/promosi", "/promosiDetail", "/account", "/download"];
router.beforeEach((to, from, next) => {
  if (store.getters.token) {
    next();
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next();
    } else {
      store.dispatch("user/setOpen", true).then(() => {
        // next();
      });
    }
  }
});

// 注册全局 过滤方法 filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
