<template>
  <div :class="['block-title', { 'block-title-pc': isPcRight }]">
    <div class="block-title-left"><img :src="icon" alt="" />{{ title }}</div>
    <div class="block-title-right" v-show="hasRight" @click="goPage">
      <template v-if="right != ''">{{ right }} </template>
      <template v-else>
        {{ $t("message.viewmore") }}
        <van-icon name="arrow" color="#B8B9BE" size="15" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockTitle",
  components: {},
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    hasRight: {
      type: Boolean,
      default: false,
    },
    isPcRight: {
      type: Boolean,
      default: false,
    },
    right: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    goPage() {
      if (this.link) {
        this.$toLink(this.link);
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.block-title {
  margin: auto;
  padding: 44px 0 33px 0;
  @include flexRowBetween;
  @media screen and (min-width: 720px) {
    padding: 20px;
    margin: 30px auto 0 auto;
  }

  &-left {
    @include flexRow;
    color: #fff;

    font: {
      size: 42px;
      weight: bold;
    }
    @media screen and (min-width: 720px) {
      font-size: 28px;
    }

    img {
      width: 34px;
      height: 34px;
      margin-right: 12px;
      @media screen and (min-width: 720px) {
        width: 42px;
        height: 42px;
      }
    }
  }

  &-right {
    @include flexRow;
    color: $text-gray;
    cursor: pointer;

    font: {
      size: 25px;
      weight: bold;
    }

    img {
      width: 31px;
      height: 31px;
      margin-left: 8px;
    }
  }
}
.block-title-pc {
  margin: auto;
  padding: 0 0 16px 0;
  @include flexRowBetween;
  .block-title-left {
    @include flexRow;
    color: #fff;
    font: {
      size: 20px;
      weight: bold;
    }
    img {
      width: 34px;
      height: 34px;
      margin-right: 12px;
    }
  }
  .block-title-right {
    @include flexRow;
    color: $text-gray;

    font: {
      size: 18px;
      weight: bold;
    }

    img {
      width: 31px;
      height: 31px;
      margin-left: 8px;
    }
  }
}
</style>
