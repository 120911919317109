// 印尼
export default {
  account: {
    welcome: "Selamat datang di Go Lucky777",
    loginTip: "Silakan Login",
    gLogin: "Login tanpa Akun",
    or: "Atau",
    orpc: "Masuk langsung dengan",
    aLogin: "Login dengan Akun",
    fLogin: "Login dengan Facebook",
    googleLogin: "Login dengan Google",

    register: "Daftar Akun Baru",
    resetPwd: "Mendapat kembali kata sandi",
    namePlace: "Username",
    pwdPlace: "Kata Sandi",
    confirmPwdPlace: "Konfirmasi kata sandi",
    codePlace: "Kode Undangan",
    emailTitle: "E-mail",
    phoneTitle: "No Ponsel",

    emailPlace: "Email",
    pwdValidate: "8-32 karakter, masukkan kata sandi baru Anda",
    nameValidate: "6-12 huruf, tanpa simbol",
    forgetPwd: "Lupa kata sandi?",
    smsCodePlace: "Kode verifikasi",
    loginBtn: "Login",
    login: "Login",
    regBtn: "Daftar Sekarang",
    submitBtn: "Kirim",
    noAccount: "Belum punya akun?",
    regNow: "Daftar sekarang",
    protocalAccept: "Saya berusia 18 tahun dan menerima ",
    protocal: "kebijakan privasi serta syarat dan ketentuan",
    hasAccount: "Sudah memiliki akun?",
    logShowFav: "Login untuk lihat game favoritmu",
    noFav: "Kamu belum menyimpan game apa pun",
    goLogin: "Daftar atau masuk",
    level: "Level",
    currentLevel: "Level saat ini",
    exceed: "Melebihi",
    upgrade: "Syarat Upgrade",
    rechargeAmount: "Nominal Top-Up",
    wagerAmount: "Nominal Taruhan",
    benefit: "Keuntungan",
    withPerDay: "Jumlah penarikan per hari",
    amountPerDay: "Nominal penarikan per hari",
    nextLevel: "Level berikut",
    clicktoLogin: "Klik untuk masuk",
    profile: "Profil",
    accountname: "Nama akun",
    signOut: "Log Out",
    save: "Simpan",
    confirmSignOut: "Konfirmasi untuk log out?",
    cancel: "Cancel",
    success: "keberhasilan",
    wrongEmail: "Kotak pesan salah format",
    pwdNotComplete: "Kata sandi tidak konsisten",
    checkFirst: "Harap baca dan terima perjanjian terlebih dahulu",
    fill: "Silakan isi terlebih dahulu",
    changepwd: "Ganti kata sandi",
    originalpassword: "Kata sandi asli",
    newpassword: "Kata sandi baru",
    newpassword1: "8-32 karakter masukkan kata sandi baru Anda",
    confirmpassword: "Konfirmasi sandi",
    faq: "FAQ",
    enterquestion: "Silakan masukkan pertanyaan Anda",
    uploading: "Mengunggah...",
    uploadpic: "tolong unggah gambar",
    write: "Tulis pesan...",
    service: "Waktu layanan: Setiap hari selama 24 jam",
    contactus: "HUBUNGI KAMI",
    allGames: "All Games",
    bonus: "bonus",
    invitefri: "Undang Teman",
  },
  game: {
    fav: "Favorit",
    title: "Permainan",
    placeholder: "Silakan masukkan permainan yang ingin Anda mainkan.",
    demo: "Demo",
    play: "Main",
    recentBets: "Taruhan Terbaru",
    myBets: "taruhan saya",
    player: "Pemain",
    bets: "Taruhan(Rp)",
    profit: "Untung(Rp)",
    provider: "Penyedia",
    more: "Lainnya",
  },
  app: {
    home: "Beranda",
    invite: "Undang",
    wallet: "Dompet",
    promosi: "Promosi",
    account: "Akun",
  },
  invite: {
    referencecode: "Kode referensi",
    record: "Histori Undangan",
  },
  wallet: {
    wtips:
      "Mengisi ulang dan bermain dapat meningkatkan level Anda. Maka Anda bisa mendapatkan lebih banyak waktu dan jumlah penarikan",
    // TODO
    moreBonus: "Dapatkan Lebih Banyak Bonus",
    trans: "Transaksi",
    // TODO
    eachRechargeLimit: "Setiap batas isi ulang",
    maxWithdraw: "Jumlah maksimum yang dapat ditarik adalah ",
    enternfullame: "Silakan masukkan nama lengkap Anda",
    enterphonenumber: "Harap masukkan nomor telepon",
    enteremail: "Masukkan email Anda",
    ok: "Ok",
    deposit: "Menyetorkan",
    withdraw: "Menarik",
  },
  promosi: {
    details: "Rincian",
  },
  qa: {
    regist: {
      title: "Daftar",
      q0: "Tidak bisa daftar, ada masalah pada username",
      q1: "Tidak bisa daftar, kata sandi yang dimasukkan salah",
      q2: "Tidak bisa daftar, format email salah",
      q3: "Tidak bisa daftar, batas pendaftaran telah tercapai",
      q4: "Tidak bisa daftar, kesalahan sistem",
      q5: "Cara log in dengan Facebook",
      q6: "Cara log in dengan Google",
      q7: "Apa itu login tamu dan bagaimana cara mengembalikan akun login tamu",
      a0: "Ada yang salah dengan format username yang Anda masukkan. Silakan masukkan 6-12 digit angka atau huruf tanpa simbol. Kemungkinan juga username yang Anda masukkan sudah ada. Silakan masukkan username baru lainnya untuk mencoba.",
      a1: "Ada yang salah dengan format password yang Anda masukkan. Silakan masukkan lebih dari 6 digit angka atau huruf, tidak termasuk simbol. Bisa juga password yang Anda masukkan untuk kedua kalinya tidak sesuai dengan password yang Anda masukkan pertama kali. Silakan coba kembali.",
      a2: "Ada yang salah dengan format email yang Anda masukkan, silakan masukkan kembali.",
      a3: "Ada batas maksimal jumlah akun yang dapat didaftarkan setiap pengguna. Anda dapat menggunakan akun yang telah didaftarkan sebelumnya untuk bermain. Jika Anda masih ingin mendaftarkan akun baru, silakan ganti perangkat dan coba.",
      a4: "Ini mungkin kelainan sementara pada jaringan atau sistem. Anda dapat mengubah lingkungan jaringan dan memulai ulang aplikasi atau masuk kembali ke halaman web. Anda juga dapat mencobanya di lain waktu.",
      a5: "Pada halaman login/registrasi, Anda dapat mengklik ikon Facebook dan ikuti petunjuk untuk mendaftar, Anda akan otomatis login setelah registrasi berhasil.",
      a6: "Pada halaman login/pendaftaran, Anda dapat mengklik ikon Google dan ikuti petunjuk untuk mendaftar, Anda akan otomatis login setelah pendaftaran berhasil.",
      a7: "Halo, login tamu adalah metode pendaftaran akun cepat yang kami sediakan untuk Anda. Setelah registrasi dan login, akun akan terikat ke perangkat Anda. Selama Anda menggunakan perangkat, Anda dapat berhasil login tanpa mengisi informasi pendaftaran. Namun, kami tetap menyarankan Anda menambahkan informasi pribadi Anda setelah pendaftaran.Silahkan isi informasi pribadi dan alamat email Anda pada halaman tersebut untuk mencegah akun Anda hilang.",
    },
    login: {
      title: "Log-in",
      q0: "Nama pengguna/kata sandi login salah",
      q1: "Akun telah diblokir",
      q2: "Tidak dapat log in dengan Facebook",
      q3: "Tidak dapat log in dengan Google",
      q4: "Apa yang harus dilakukan jika lupa kata sandi",
      a0: "Username dan password yang Anda masukkan tidak cocok, silakan masukkan kembali",
      a1: "Akun Anda mungkin telah melanggar perjanjian pengguna atau kode etik kami. Kami telah mengambil tindakan tertentu pada akun Anda. Tindakan tersebut dapat berlangsung selama jangka waktu tertentu dan kemudian secara otomatis tidak diblokir. Anda dapat masuk lagi untuk bermain; mungkin juga demikian permanen. Kami harap Anda mampu mematuhi peraturan platform.",
      a2: "Silakan periksa apakah status akun Facebook Anda normal, jika normal dan Anda masih tidak bisa login, silakan tinggalkan pesan kepada kami.",
      a3: "Silakan periksa apakah status akun Google Anda normal. Jika normal dan Anda masih tidak bisa login, silakan tinggalkan pesan kepada kami.",
      a4: "Anda dapat mengklik 'Lupa Kata Sandi' dan isi informasi langkah demi langkah, kami akan mengirimkan kode verifikasi ke email Anda. Setelah memasukkan kode verifikasi yang benar, Anda dapat mengubah kata sandi Anda. Catatan: Jika Anda belum pernah mengisi alamat email Anda di platform, Anda mungkin tidak dapat menggunakan fungsi lupa kata sandi.",
    },
    topUp: {
      title: "Isi ulang",
      q0: "Top-up saya berhasil dipotong dan pesanan dibuat, tetapi saldo tidak bertambah.",
      q1: "Uang isi ulang sudah dipotong, namun pesanan tidak ditemukan di daftar pesanan.",
      q2: "Tidak ada respon saat klik Next dan tidak bisa ke halaman selanjutnya",
      q3: "Bisakah saya melakukan isi ulang menggunakan kartu kredit?",
      q4: "Metode isi ulang apa yang saat ini didukung?",
      q5: "Berapa batas nominal untuk 1x isi ulang?",
      q6: "Apakah ada batas isi ulang harian?",
      q7: "Berapa lama waktu yang dibutuhkan hingga uang isi ulang sampai ke akun saya?",
      q8: "Kapan saya dapat melakukan isi ulang?",
      q9: "Apakah ada promo isi ulang?",
      a0: "Jika Anda baru saja mengisi ulang, Anda dapat mencoba menunggu beberapa saat, mungkin tertunda beberapa saat karena alasan saluran atau jaringan.",
      a1: "Jika Anda sudah mengisi ulang lebih dari beberapa jam dan akun Anda belum masuk, silakan kirim pesan kepada kami.",
      a2: "Harap konfirmasi apakah jumlah top-up yang Anda masukkan berada dalam kisaran yang valid dan informasi yang diisi sudah benar. Jika semua hal di atas benar, hal ini mungkin disebabkan oleh fluktuasi jaringan atau kelainan sistem sementara. Anda dapat mengubah lingkungan jaringan dan memulai ulang aplikasi atau masuk kembali ke halaman web. Anda juga dapat mencoba lagi nanti.",
      a3: "Halo, isi ulang kartu kredit saat ini tidak didukung, kami akan mencoba untuk merealisasikannya.",
      a4: "Saat ini metode isi ulang populer yang didukung adalah DANA, BRI, BNI, dll, yang akan disesuaikan dengan dukungan saluran, Anda juga dapat memeriksa metode isi ulang yang didukung saat ini di halaman isi ulang.",
      a5: "Batasan top-up dalam 1 transaksi adalah: Rp10.000 - Rp50.000.000. Anda juga dapat memeriksa petunjuk di halaman isi ulang.",
      a6: "Saat ini tidak ada batasan jumlah transaksi, tetapi mungkin ada batasan saluran tertentu. Disarankan agar Anda bermain secukupnya.",
      a7: "Dalam keadaan normal, top-up akan langsung masuk di akun Anda. Jika Anda mengalami fluktuasi jaringan atau penundaan sistem, pesanan mungkin akan tiba dalam beberapa jam. Jika pesanan top-up Anda tidak sampai dalam waktu lama, silakan tinggalkan pesan.",
      a8: "Anda dapat top-up kapan saja dan kami akan menanganinya untuk Anda tepat waktu",
      a9: "Untuk kegiatan top-up, Anda dapat memperhatikan bagian Promosi atau pemberitahuan pesan sistem pada Aplikasi atau halaman web.",
    },
    withDraw: {
      title: "Penarikan",
      q0: "Saya sudah mengajukan permohonan penarikan, tetapi belum sampai.",
      q1: "Permohonan penarikan gagal",
      q2: "Tidak dapat mengajukan permohonan penarikan, tertulis 'Melebihi batas limit'",
      q3: "Tidak dapat mengajukan permohonan penarikan , tertulis 'Telah melewati jumlah penarikan'",
      q4: "Berapa lama penarikan sampai ke akun Anda?",
      q5: "Apa yang harus dilakukan jika batas penarikan sudah habis?",
      q6: "Batas penarikan harian dan kapan batas ini dihapuskan",
      q7: "Bisakah saldo akun bonus ditarik?",
      a0: "Mohon konfirmasi bahwa informasi penarikan yang Anda kirimkan sudah benar dan formatnya benar. Mohon konfirmasi bahwa tidak ada kelainan pada status akun yang Anda kreditkan. Jika Anda baru saja mengirimkan pesanan, Anda dapat memeriksa saldo akun Anda nanti. Transaksi mungkin tertunda karena fluktuasi jaringan atau penundaan sistem.",
      a1: "Jika Anda mengonfirmasi bahwa informasi Anda benar dan belum menerima uang penarikan selama lebih dari beberapa hari, silakan tinggalkan pesan",
      a2: "Halo, ada banyak penyebab kegagalan penarikan, jika terjadi kesalahan saat penarikan yang menandakan Anda gagal, harap periksa apakah informasi penarikan yang Anda isi sudah benar, apakah formatnya sudah benar, dan apakah status rekening penerima normal. Harap konfirmasi jumlah penarikan Anda, dan apakah jumlah penarikan berada dalam kisaran batas hari itu. Jika Anda mengonfirmasi bahwa informasi di atas benar, Anda dapat mencoba menarik uang lagi nanti.",
      a3: "Jika Anda masih belum menerima uang setelah berhasil melakukan penarikan, harap bersabar menunggu. Jika informasi akun Anda benar, biasanya transaksi akan segera tiba; dalam keadaan khusus, transaksi mungkin tertunda karena fluktuasi jaringan atau penundaan sistem , dan intervensi manual mungkin diperlukan saat ini, mungkin diperlukan waktu hingga 7 hari kerja untuk tiba, harap dipahami.",
      a4: "Halo, batas penarikan harian dan jumlah penarikan di akun Anda disesuaikan dengan level Anda saat ini. Anda dapat memeriksa level Anda saat ini dan jumlah penarikan terkait di halaman informasi pribadi, dompet, atau penarikan di Aplikasi atau halaman web, seperti jumlah penarikan pada hari itu. Setelah batas penarikan habis, Anda tidak dapat lagi memulai aplikasi penarikan. Anda dapat meningkatkan level Anda untuk mendapatkan lebih banyak manfaat penarikan atau menunggu pengaturan ulang harian pukul 24:00.",
      a5: "Halo, dalam keadaan normal, transaksi penarikan akan segera tiba. Dalam keadaan khusus, penarikan mungkin tertunda karena fluktuasi jaringan atau penundaan sistem. Saat ini, mungkin ada intervensi manual, dan mungkin memerlukan waktu paling lambat 7 hari kerja.",
      a6: "Halo, jumlah penarikan harian dan batas penarikan diperbarui pada pukul 24:00 setiap hari.",
      a7: "Halo, saldo akun Bonus saat ini tidak mendukung penarikan tunai. Anda dapat menggunakannya secara normal di dalam game. Untuk detailnya, silakan lihat petunjuk di halaman dompet.",
    },
    level: {
      title: "Level",
      q0: "Cara naik level",
      q1: "Apa manfaat naik level?",
      q2: "Saya telah memenuhi persyaratan naik level, tetapi belum berhasil naik level",
      q3: "Bisakah batas level dibuka?",
      a0: "Halo, pengisian ulang yang berhasil dan perputaran taruhan permainan adalah syarat utama untuk peningkatan saat ini. Anda dapat memasuki pintu masuk level pada halaman informasi pribadi, dompet, penarikan, dan lainnya untuk melihat persyaratan jumlah peningkatan yang terperinci.",
      a1: "Setelah level ditingkatkan, kami akan meningkatkan jumlah dan nominal penarikan harian untuk Anda, memberi Anda layanan VIP pribadi, dan hak dan kepentingan lainnya.Untuk detailnya, Anda dapat masuk ke menu Level pada informasi pribadi, dompet, penarikan dan halaman lain untuk dilihat, atau hubungi layanan pelanggan pribadi",
      a2: "Halo, jika Anda sudah memenuhi ketentuan upgrade, Anda dapat mencoba keluar dari halaman dan masuk kembali. Mungkin level Anda sudah ditingkatkan tetapi halaman belum di-refresh. Jika Anda masih belum mengupgrade, Anda dapat memeriksa lagi nanti. Bisa saja turnover atau taruhan isi ulang anda. Turnover masih diselesaikan secara statistik, ada penundaan tertentu, dan belum dimasukkan ke dalam level anda.",
      a3: "Halo, level tertinggi saat ini adalah LV 8, dan level yang lebih tinggi belum dibuka. Jika Anda telah mencapai level ini, isi ulang dan turnover taruhan Anda juga akan dihitung secara normal. Setelah level baru dibuka, peningkatan akan secara otomatis dinilai .",
    },
    invite: {
      title: "Undang Teman",
      q0: "Bagaimana cara mengundang teman untuk bergabung dalam platform ini?",
      q1: "Apa yang diperlukan agar undangan dianggap berhasil?",
      q2: "Hadiah apa yang akan saya dapatkan jika undangan berhasil?",
      q3: "Hadiah apa yang akan teman saya dapatkan?",
      q4: "Cara melihat catatan undangan",
      q5: "Cara melihat catatan hadiah",
      q6: "Cara menggunakan hadiah yang didapat",
      q7: "Undangan saya berhasil, tetapi saya tidak menerima hadiahnya",
      a0: "Anda dapat menemukan pintu masuknya pada halaman acara atau halaman Undangan. Bagikan tautan atau kode undangan Anda kepada teman-teman Anda. Teman Anda akan berhasil mendaftar menggunakan tautan undangan Anda, atau isi kode undangan Anda saat mendaftar. Setelah teman berhasil mendaftar dan pertama kali berhasil top-up, undangan dianggap berhasil, dan Anda dapat menerima hadiah undangan. Anda dapat melihat aturan detailnya di halaman acara.",
      a1: "Teman Anda berhasil mendaftar menggunakan link undangan Anda, atau mengisi kode undangan Anda saat mendaftar. Undangan akan dianggap berhasil setelah teman berhasil mendaftar dan berhasil melakukan isi ulang untuk pertama kalinya.",
      a2: "Setelah berhasil mengundang teman, Anda bisa mendapatkan Bonus, kupon, dll. Untuk detailnya, silakan periksa halaman Aplikasi atau halaman Aktivitas",
      a3: "Setelah berhasil mengundang teman, teman Anda juga bisa mendapatkan Bonus, kupon, bonus isi ulang pertama kali, dll. Untuk detailnya, silakan periksa halaman Aplikasi atau aktivitas web",
      a4: "Anda dapat mengklik data statistik apa pun di halaman Aplikasi atau aktivitas web untuk melihat detailnya.",
      a5: "Anda dapat mengklik data statistik apa pun di halaman Aplikasi atau aktivitas web untuk melihat detailnya.",
      a6: "Jika Anda mendapatkan hadiah Bonus, Anda dapat menggunakannya untuk mengurangi uang tunai saat bermain game; untuk kupon, bonus setoran pertama, dll., silakan periksa halaman acara untuk detailnya",
      a7: "Halo, teman Anda harus berhasil mendaftar menggunakan tautan atau kode undangan yang Anda bagikan dan menyelesaikan top-up terlebih dahulu agar dianggap sebagai undangan berhasil. Harap konfirmasi bahwa teman Anda telah memenuhi ketentuan di atas. Hadiah undangan akan didistribusikan ke akun Anda setelah statistik selesai. Mungkin ada penundaan tertentu, harap menunggu dengan sabar.",
    },
    play: {
      title: "Main Game",
      q0: "Bagaimana cara main game?",
      q1: "Apa itu mode demo?",
      q2: "Apakah ada saldo simulasi?",
      q3: "Saat memasuki permainan, layar menjadi putih, permainan tidak berhasil dimuat dan tidak dapat dimainkan.",
      q4: "Tidak bisa masuk ke game, layar hitam",
      q5: "Permainan terhenti dan loading lambat",
      q6: "Bagaimana cara menambahkan game ke favorit",
      q7: "Bagaimana cara melihat daftar game favorit?",
      a0: "Halo, Anda dapat mengklik game mana saja di lobi untuk masuk, pilih Mainkan untuk bermain, dan cara memainkan game tersebut dapat dilihat dengan mengklik pengenalan detail dalam game tersebut. Beberapa game juga menyediakan mode uji coba. Klik Demo untuk mencobanya. Selama demo,  Anda akan menerima  mata uang simulasi untuk mencoba permainan sebanyak yang Anda inginkan",
      a1: "Halo, mode Demo adalah mode uji coba. Beberapa game menyediakan opsi mode Demo. Klik Demo untuk mencobanya. Mata uang simulasi akan diberikan selama uji coba, sehingga Anda dapat mencobanya.",
      a2: "Halo, mode Demo adalah mode uji coba. Beberapa game menyediakan opsi mode Demo. Klik Demo untuk mencobanya. Mata uang simulasi akan diberikan selama uji coba, sehingga Anda dapat mencobanya.",
      a3: "Halo, jika Anda mengalami masalah seperti layar menjadi putih atau hitam saat memasuki game, mungkin karena fluktuasi jaringan atau kelainan sistem. Anda dapat mencoba mengubah jaringan, keluar dari aplikasi, dan masuk kembali. Jika masalah masih berlanjut , Anda dapat mencoba mengubah jaringan. ",
      a4: "Halo, jika Anda mengalami masalah seperti layar menjadi putih atau hitam saat memasuki game, mungkin karena fluktuasi jaringan atau kelainan sistem. Anda dapat mencoba mengubah jaringan, keluar dari aplikasi, dan masuk kembali. Jika masalah masih berlanjut , Anda dapat mencoba mengubah jaringan. ",
      a5: "Halo, jika Anda mengalami masalah seperti layar menjadi putih atau hitam saat memasuki game, mungkin karena fluktuasi jaringan atau kelainan sistem. Anda dapat mencoba mengubah jaringan, keluar dari aplikasi, dan masuk kembali. Jika masalah masih berlanjut , Anda dapat mencoba mengubah jaringan. ",
      a6: "Halo, saat ini ada dua cara untuk menambahkan game ke daftar favorit Anda: 1. Klik ⭐️ di halaman detail game; 2. Setelah masuk ke dalam game, Anda dapat mengklik ⭐️ di atas game. Setelah menambah favorit, Anda dapat menemukan daftar favorit di pusat pribadi dan mengelola koleksi permainan Anda di daftar tersebut.",
      a7: "Setelah menambah favorit, Anda dapat menemukan daftar favorit di pusat pribadi dan mengelola koleksi permainan Anda di daftar tersebut.",
    },
    promosi: {
      title: "Promosi",
      q0: "Di mana saya bisa memeriksa aktivitas?",
      q1: "Bagaimana cara berpartisipasi dalam kegiatan promosi?",
      q2: "Bagaimana cara mendapatkan hadiah dari kegiatan promosi?",
      a0: "Halo, Anda dapat memeriksa aktivitas saat ini dan yang akan datang di beranda Aplikasi atau web dan halaman Promosi. Partisipasi saat ini dalam aktivitas game juga akan ditampilkan di setiap halaman detail game. Beberapa aktivitas tidak memerlukan registrasi dan bisa ikut secara otomatis; Beberapa kegiatan mungkin memerlukan pendaftaran, Anda dapat memilih untuk berpartisipasi sesuai  keinginan Anda",
      a1: "Anda dapat mengklik halaman pengenalan acara untuk memasukkan detail acara. Acara yang tidak memerlukan registrasi akan otomatis diikuti. Beberapa aktivitas mungkin memerlukan registrasi dalam periode acara. Anda dapat memilih untuk berpartisipasi dalam periode acara sesuai dengan situasi Anda.",
      a2: "Halo, hadiah acara akan bervariasi tergantung pada konten dan aturan acara. Beberapa hadiah acara mungkin diberikan secara real-time, sementara yang lain mungkin perlu menunggu hingga acara berakhir. Disarankan agar Anda memeriksa detail aturan di halaman acara.",
    },
    other: {
      title: "Lainnya",
      q0: "Bagaimana cara mereset kata sandi?",
      q1: "Bagaimana cara mengubah informasi pribadi?",
      q2: "Bagaimana cara memulihkan akun yang saya lupa kata sandinya?",
      a0: "Halo, Anda dapat menemukan pengaturan ulang kata sandi di halaman informasi pribadi. Masukkan kata sandi lama Anda untuk verifikasi dan kemudian masukkan kata sandi baru untuk menyelesaikan pengaturan ulang.",
      a1: "Halo, Anda dapat menemukan menu modifikasi informasi pribadi di halaman informasi pribadi. Setelah modifikasi selesai, klik Simpan untuk menyelesaikan modifikasi. Sangat disarankan agar Anda menambahkan informasi email. Email ini dapat membantu Anda mengatur ulang kata sandi jika Anda lupa.",
      a2: "Halo, jika Anda lupa kata sandi, Anda dapat menggunakan fungsi Lupa Kata Sandi untuk mengatur ulang; jika Anda lupa nama pengguna, Anda dapat meninggalkan pesan dan memberi kami alamat email Anda, catatan isi ulang, catatan penarikan dan informasi akun lainnya, dan kami akan membantu Anda memulihkan akun Anda.",
    },
  },
  message: {
    // TODO
    ept: "Kosong",
    empty: "Saat ini tidak ada sejarah",
    loading: "Loading",
    copied: "kesuksesan yang disalin",
    copyfailed: "Penyalinan gagal",
    refreshLoose: "Rilis untuk menyegarkan",
    refreshPull: "Tarik ke bawah untuk menyegarkan",
    viewmore: "lihat lebih banyak",

    guestlogin: "Login tamu",
    donhaveanaccount: "Belum punya akun?",
    usernameeamil: "Nama pengguna",
    alreadyhave: "Sudah memiliki akun? ",
    pleaselogin: "Silakan Login",
    retrievepassword: "Mendapat kembali kata sandi",
    choosepaymethod: "Pilih metode pembayaran",
    yearsold: "Saya telah berusia 18 tahun dan",
    privacypolicy: "menerima kebijakan privasi & Syarat dan ketentuan",
    accountregistration: "Daftar akun",
    registernow: "Daftar sekarang",
    byclickingon: "Dengan klik 'Pengunjung/Akun Anda telah membaca dan",
    termsandconditions:
      "setuju syarat dan ketentuan serta kebijakan privasi xxx",
    takeascreenshot: "Ambil tangkapan layar dan simpan",
    ownloadqrcode: "Unduh kode QR",
    clicktocopyvanumber: "Klik untuk salin nomor VA",
    nomorvirtualaccount: "Nomor Virtual Account",
    Ihavepaid: "Saya telah membayar Qris",
    aftercopying: "Setelah salin, silakan buka aplikasi untuk membayar",
    copyvanumber: "Salin Nomor VA",
    totalpayment: "Total pembayaran",
    howtopay: "Bagaimana cara membayar?",
    pleasetransferto: "Silakan transfer ke",
    orcontinuewith: "Atau lanjut dengan",
    signinwithaccount: "Masuk dengan akun",
    connectwithfacebook: "Terhubung dengan Facebook",
    verificationcode: "Kode verifikasi",
    welcome: "Selamat datang",
    phone: "Nomor telepon",
    pwd: "Kata sandi",
    confirmpwd: "konfirmasi sandi",
    forgetpwd: "Lupa kata sandi",
    login: "Gabung",
    confirm: "Ok",
    // "privacypolicy": "Saya berusia 18 tahun dan menerima kebijakan privasi $ Syarat dan ketentuan",
    subrigister: "Daftar untuk mendapatkan 10.000",
    policytitle: "Kebijakan pribadi",
    net_sucess: "Berhasil",
    withdraw: "Menarik",
    faq: "FAQ",
    deposit: "Menyetorkan",
    recharge: "Isi ulang",
    selectpayment: "Pilih jenis akun",
    reminder: "Pengingat",
    total: "Total",
    avaiblewithdraw: "Tersedia untuk ditarik",
    amount: "Jumlah",
    enteramount: "Masukkan jumlahnya",
    personalcenter: "Profil saya",
    username: "Nama belakang",
    changepwd: "Ganti kata sandi",
    signout: "Keluar",
    modifyinfo: "Ubah Informasi",
    ok: "Ok",
    changepasssword: "Ganti kata sandi",
    originalpassword: "Kata sandi asli",
    newpassword: "Kata sandi baru",
    originalpassword1: "8-32 karakter",
    newpassword1: "8-32 karakter masukkan kata sandi baru Anda",
    confirmpassword: "Konfirmasi sandi",
    confirmpassworddesc: "konfirmasi kata sandi baru Anda",
    amount1: "Jumlah",
    status: "Status",
    date: "Tanggal",
    rechargeone: "1. Hanya pengisian mata uang lokal Indonesia yang didukung.",
    rechargetwo: "2. Nilai minimal satu kali isi ulang adalah 10.000.",
    rechargethree:
      "3. Isi ulang akun segera. Jika akun tidak sampai dalam 30 menit, harap hubungi layanan pelanggan",
    next: "Lanjut",
    determine: "Lanjut",
    verifyidentity: "Verifikasi identitas",
    tryagain: "Coba lagi nanti",
    entermobile: "Silakan masukkan nomor ponsel Anda",
    enteremail: "Masukkan email Anda",
    reset: "Mengatur ulang",
    enterquestion: "Silakan masukkan pertanyaan Anda",
    uploading: "Mengunggah...",
    uploadpic: "tolong unggah gambar",
    pleaseinputrechargeamount: "Harap masukkan jumlah isi ulang!",
    rechargeamountmustbegreater:
      "Jumlah isi ulang harus lebih besar dari 10.000",
    maxamountis: "jumlah maksimal adalah 10000000",
    pleaseinputphonenumber: "Harap masukkan nomor telepon",
    wrongemail: "Email yang salah",
    donotclickanybuttons: "Memuat, jangan klik tombol apa pun",
    inputqustion: "Silahkan masukan pertanyaan!",
    uploadpicture: "Mengunggah gambar",
    passwordcannotbelessthaneightdigits:
      "Kata sandi tidak boleh kurang dari delapan digit",
    inconsistentpasswords: "Kata sandi tidak konsisten",
    pleaseagreetoprivacyagreement: "Harap setujui perjanjian privasi",
    currencysign: "IDR",
    pleaseenteroriginalpassword: "Silakan masukkan kata sandi asli",
    pleaseenterpassword: "Silakan masukkan kata sandi",
    pleaseenterpasswordagain: "Silakan masukkan kata sandi lagi",
    thetwopasswordsaredifferent: "Kedua kata sandi itu berbeda",
    youdonthaveenoughbalance: "Anda tidak memiliki cukup saldo",
    submittedsuccessfully: "Berhasil dikirim",
    pleaseinputnumber: "mohon masukan nomor",
    register: "Daftar",
    branddesc1: "6-12 huruf, tanpa simbol",
    branddesc2: "Setidaknya 6 karakter",
    email: "Surel",
    inputusername: "masukan nama pengguna",
    gametitle1: "MENYARANKAN GAME",
    home: "RUMAH",
    games: "PERMAINAN",
    promos: "PROMOSI",
    referral: "RUJUKAN",
    info: "INFO",
    contactus: "HUBUNGI KAMI",
    back: "KEMBALI",
    slots: "SLOTS",
    sports: "OLAHRAGA",
    casino: "KASINO",
    p2p: "P2P",
    fishhunter: "PEMBURU IKAN",
    lottery: "LOTRE",
    egame: "E-GAME",
    cockfight: "Adu Ayam",
    left: "<![CDATA[<]]:",
    right: "<![CDATA[:",
    slotsproviders: "PENYEDIA SLOT",
    demo: "DEMO",
    playnow: "MAIN SEKARANG",
    news: "BARU",
    tops: "ATAS",
    alls: "SEMUA",
    gametitle2: "GAME PANAS",
    gametitle3: "PERMAINAN BARU",
    contactdesc:
      "Spesialis Dukungan Pelanggan tersedia setiap hari selama 24 jam.",
    nowhatsapp: "instal WhatsApp",
    entername: "Silahkan masukan nama anda",
    membershiplevel: "Tingkat Keanggotaan",
    accountname: "Nama akun",
    referencecode: "Kode referensi",
    walletbalance: "SALDO DOMPET",
    copiedtomemory: "disalin ke memori",
    enteracountno: "masukkan nomor rekening",
    resend: "Kirim ulang",
    verificationcodebeen:
      "Kode Verifikasi telah dikirim, buka email Anda untuk mendapatkannya",
    enternfullame: "Silakan masukkan nama lengkap Anda",
    serviceprivileges: "HAK ISTIMEWA LAYANAN",
    averagetime: "Waktu rata-rata",
    mins: "1 Menit",
    mins3: "3 Menit",
    user: "Pengguna",
    numberbet: "taruhan angka",
    online: "On line",
    putinfo: "PUSAT INFORMASI",
    more: "Lagi",
    sportsbookplayer: "Pemain SportsBook",
    slotplaying: "Bermain Slot",
    depositingmethod: "Metode Deposit",
    withdrawmethod: "Metode Penarikan",
    termsconditions: "<![CDATA[SYARAT & KETENTUAN]]>:",
    howtoplay: "CARA BERMAIN",
    memberservice: "LAYANAN ANGGOTA",
    whatsapp: "WHATSAPP",
    supporttime: "24/7 dukung",
    serviceproviderplatform: "Platform Penyedia Layanan",
    paymentmethod: "Cara Pembayaran",
    dana: "DANA",
    memo: "MEMO",
    live: "HIDUP",
    language: "BAHASA",
    english: "Bahasa inggris",
    indonesian: "Bahasa Indonesia",
    pressagaintoexitthe: "Tekan lagi untuk keluar ",
    invitedfriend: "Mengundang teman-teman",
    invitecode: "Kode Referensi / Afiliasi",
    step: "Melangkah",
    step01: "Langkah 01",
    invite1: "Berikan tautan ke teman Anda",
    invite2: "Undang sekarang",
    invite3: "Langkah 02",
    invite4: "Teman menerima undangan Anda",
    invite5: "Salin kode undangan",
    invite6:
      "Teman isi kode undangan di tambang saya - Undang teman - Ikat dan undang teman untuk mengikat",
    invite7: "Langkah 03",
    invite8: "Teman daftar dan selesaikan isi ulang pertama",
    invite9: "Langkah 04",
    invite10: "Dapatkan hadiah sekarang",
    invite11: "Hadiah utama pertama",
    invite12: "Jumlah orang yang diisi ulang pertama yang diundang",
    invite13: "Pengundang mendapatkan hadiah",
    invite14: "Aturan",
    invite15:
      "1.Jumlah bonus hanya dapat digunakan oleh pengguna untuk bertaruh, tidak dapat ditarik langsung, jumlah yang menang dapat ditarik kapan saja<br><br>2.Teman yang Anda undang berhasil mendaftar, setelah teman Anda mengisi ulang berhasil, jumlah tersebut akan didistribusikan ke akun hadiah Anda<br><br>3.Semakin tinggi jumlah isi ulang pertama per teman, semakin tinggi hadiah yang Anda dapatkan<br><br>4.Acara undangan hanya bisa mendapatkan hadiah pertama dari seorang teman. Pada saat yang sama, itu dapat dikumpulkan dengan hadiah lain.<br><br>5. Hak interpretasi akhir dari semua aktivitas adalah milik platform. Untuk detailnya, hubungi layanan pelanggan platform.<br><br>",
    invite16:
      "Daftar untuk mendapatkan kupon kuis Piala Dunia dalam jumlah tertentu:",
    invite17:
      "1.Daftar untuk mendapatkan **kupon kuis Piala Dunia, bisa langsung digunakan dan dipotong, berlaku selama 3 hari<br><br>2.Kupon kuis Piala Dunia hanya digunakan untuk bertaruh pada beberapa permainan dalam kelompok hadiah: kuis 16 besar, kuis 8 besar, kuis 4 besar, cara juara dan runner-up untuk bermain juara.<br><br>3.Opsi taruhan, jumlah kemenangan yang dapat ditarik, memenangkan kumpulan miliaran secara gratis;<br><br>4.Siapa pun yang berniat buruk untuk mendaftar hadiah platform, sistem akan mengurangi hadiah yang sesuai, jika termasuk dalam kasus serius, maka akun akan dihapus.<br><br>5.Perangkat yang sama dan IP yang sama akan dianggap sebagai akun yang sama; Hak interpretasi akhir dari semua aktivitas adalah milik platform. Untuk detailnya, hubungi layanan pelanggan platform.<br><br>",
  },
};
