<template>
  <div class="radio-item">
    <img :src="logo" alt="" class="radio-item-img" />
    <van-radio
      :name="radioOption"
      label-position="left"
      checked-color="#17CC5F"
      @click="onClick(text)"
    >
      <template #icon="props">
        <span :class="['radio-icon', props.checked ? 'activeIcon' : '']" />
      </template>
      <slot></slot>
    </van-radio>
  </div>
</template>

<script>
import { Radio } from "vant";
export default {
  name: "CustomRadio",
  components: {
    [Radio.name]: Radio,
  },
  props: {
    radioOption: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    onClick(e) {
      this.$emit("onChoosed", e);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss">
.radio-item {
  @include flexRowBetween;
  color: #dddddd;
  font-size: 28px;
  padding: 30px 0;

  img {
    height: 50px;
  }
  .radio-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #555f7c;
  }
  .activeIcon {
    position: relative;
    border: 2px solid #17cc5f;
  }
  .activeIcon::before {
    content: "";
    width: 16px;
    height: 16px;
    background: #17cc5f;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border-radius: 50%;
  }
  .van-radio__icon {
    height: 36px;
  }
}
</style>
