<template>
  <div class="accountOperate">
    <van-icon
      name="arrow-left"
      color="#c8c9cc"
      class="login-back"
      size="22"
      v-show="showReg || showFind"
      @click="
        showReg = false;
        showFind = false;
      "
    />
    <div class="popUp-title">
      {{
        showReg
          ? $t("account.register")
          : showFind
          ? $t("account.resetPwd")
          : $t("account.aLogin")
      }}
    </div>

    <!-- 账号登录 -->
    <Transition name="slide-fade2" mode="out-in">
      <div
        class="accountOperate-body accountLog"
        v-show="!showReg && !showFind"
      >
        <van-field
          v-model="username"
          :placeholder="$t('account.namePlace')"
          class="roundField"
          :border="false"
        />
        <van-field
          v-model="password"
          :border="false"
          :placeholder="$t('account.pwdPlace')"
          class="roundField"
          maxlength="32"
        />
        <div class="accountLog-forget" @click="showFind = true">
          {{ $t("account.forgetPwd") }}
        </div>
        <van-button
          class="login-btn hasColor"
          @click="handleLogin"
          block
          :disabled="!username || !password"
          :loading="loginLoading"
        >
          {{ $t("account.loginBtn") }}
        </van-button>
        <div>{{ $t("account.noAccount") }}</div>
        <div class="accountLog-forget accountLog-reg" @click="showReg = true">
          {{ $t("account.regNow") }}
        </div>
      </div>
    </Transition>

    <!-- 注册 -->
    <Transition name="slide-fade" mode="out-in">
      <div class="accountOperate-body accountReg" v-show="showReg">
        <van-field
          v-model="reg.username"
          :placeholder="$t('account.namePlace')"
          class="roundField"
          :border="false"
          required
          maxlength="12"
        />
        <p class="login-validate-tips">{{ $t("account.nameValidate") }}</p>
        <van-field
          v-model="reg.email"
          :placeholder="$t('account.emailPlace')"
          class="roundField"
          :border="false"
        />
        <van-field
          v-model="reg.password"
          :placeholder="$t('account.pwdPlace')"
          class="roundField"
          :border="false"
          required
          maxlength="32"
        />
        <p class="login-validate-tips">{{ $t("account.pwdValidate") }}</p>
        <van-field
          v-model="reg.confirmPassword"
          :placeholder="$t('account.confirmPwdPlace')"
          class="roundField"
          :border="false"
          required
          maxlength="32"
        />
        <van-field
          v-model="reg.invitedCode"
          :placeholder="$t('account.codePlace')"
          class="roundField"
          :border="false"
        />
        <van-checkbox
          v-model="checked"
          shape="square"
          checked-color="#159046"
          class="login-protocal"
          icon-size="15"
        >
          {{ $t("account.protocalAccept") }}
          <span> {{ $t("account.protocal") }} </span>
        </van-checkbox>
        <van-button
          class="login-btn hasColor"
          block
          :disabled="!reg.username || !reg.password || !reg.confirmPassword"
          :loading="regLoading"
          @click="handleRegister"
          >{{ $t("account.regBtn") }}
        </van-button>
        <div class="login-tips">
          {{ $t("account.hasAccount") }}
          <span @click="showReg = false">{{ $t("account.login") }}</span>
        </div>
      </div>
    </Transition>

    <!-- 找回密码 -->
    <Transition name="slide-fade" mode="out-in">
      <div class="accountOperate-body accountReg" v-show="showFind">
        <van-field
          v-model="find.email"
          :placeholder="$t('account.emailPlace')"
          class="roundField"
          :border="false"
        />
        <div class="loginline">
          <van-field
            v-model="find.verifyCode"
            :placeholder="$t('account.smsCodePlace')"
            class="roundField"
            :border="false"
          />
          <van-button
            class="login-btn hasColor"
            :disabled="!find.email"
            :loading="sendLoading"
            @click="handleSend"
          >
            {{ btnText }}
          </van-button>
        </div>
        <van-field
          v-model="find.resetPassword"
          :placeholder="$t('account.pwdPlace')"
          class="roundField"
          :border="false"
          maxlength="32"
        />
        <p class="fieldRule">{{ $t("account.pwdValidate") }}</p>
        <van-field
          v-model="find.confirmResetPassword"
          :placeholder="$t('account.confirmPwdPlace')"
          class="roundField"
          :border="false"
        />
        <van-button
          class="login-btn hasColor"
          block
          :loading="resetLoading"
          @click="handleReset"
          :disabled="
            !find.verifyCode ||
            !find.confirmResetPassword ||
            !find.resetPassword ||
            !find.email
          "
          >{{ $t("account.submitBtn") }}
        </van-button>
      </div>
    </Transition>
  </div>
</template>

<script>
import { Field, Checkbox } from "vant";
import logAReg from "@/mixins/LogAReg.vue";
export default {
  name: "accountLog",
  components: {
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
  },
  mixins: [logAReg],
  props: {},
  data() {
    return {
      // 登录

      //注册

      // 找回密码

      showReg: false,
      showFind: false,
      btnText: "Get",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.login-back {
  position: absolute;
  z-index: 2;
  left: 36px;
  top: 36px;
}
.login-protocal {
  margin: 30px;
  font-size: 24px;
  text-align: left;
  span {
    text-decoration: underline;
    color: #159046;
    text-underline-offset: 10px;
  }
}
.loginline {
  @include flexRowCenter;
  margin: 30px;
  gap: 0 20px;
  .login-btn {
    margin: 0;
    flex: 1;
  }
  .roundField {
    margin: 0;
    flex: 2;
  }
}
.fieldRule {
  color: $text-gray;
  text-align: right;
  font-size: 26px;
  margin: -30px 30px;
}
.login-tips {
  font-size: 28px;
  padding-bottom: 80px;
  color: #fff;
  span {
    color: #019b83;
  }
}
.login-validate-tips {
  color: $text-gray4;
  font-size: 24px;
  text-align: right;
  margin: -30px auto -30px auto;
  width: calc(100% - 60px);
}
.slide-fade-enter-active,
.slide-fade2-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
.slide-fade2-enter,
.slide-fade2-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.accountOperate {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  box-sizing: border-box;
  color: $text-gray;
  font-size: 28px;
  text-align: center;
  @include flexColumn;
  align-items: inherit;
  background: linear-gradient(to bottom, #003033 0%, #000000 100%);
  &-body {
    flex: 1;
    overflow: auto;
  }
}

.accountLog {
  &-forget {
    margin: 40px 30px 40px auto;
    // text-align: left;
    @include flexRow;
    justify-content: end;
    text-decoration: underline;
    font-size: 28px;
    color: #fff;
    margin: 0 30px;
    opacity: 0.4;
    text-underline-offset: 12px;
    cursor: pointer;
  }
  &-reg {
    color: #019b83;
    justify-content: center;
    margin-block: 20px;
    opacity: 1;
  }
}
.popUp-title {
  color: #fff;
  font-size: 38px;
  text-align: center;
  padding-bottom: 10px;
}
.login-btn {
  width: calc(100% - 60px);
  background: $btn-green;
  border: none;
  margin: {
    top: 120px;
    bottom: 100px;
  }
}
.roundField {
  width: calc(100% - 60px);
  margin: 50px auto;
  background: #0d2d2f;
  border-radius: 16px;
  padding: 30px 40px;
}
</style>
