import { render, staticRenderFns } from "./MoreSupport.vue?vue&type=template&id=823472b0&scoped=true"
import script from "./MoreSupport.vue?vue&type=script&lang=js"
export * from "./MoreSupport.vue?vue&type=script&lang=js"
import style0 from "./MoreSupport.vue?vue&type=style&index=0&id=823472b0&prod&lang=css"
import style1 from "./MoreSupport.vue?vue&type=style&index=1&id=823472b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "823472b0",
  null
  
)

export default component.exports