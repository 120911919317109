// 英语
export default {
  account: {
    welcome: "Welcome to Brando88",
    loginTip: "Please Login",
    gLogin: "Guest Login",
    or: "Or continue with",
    orpc: "Log in directly with",
    aLogin: "Continue with Account",
    fLogin: "Continue with Facebook",
    googleLogin: "Continue with Google",
    register: "Register an account",
    resetPwd: "Retrieve password",
    namePlace: "User name",
    pwdPlace: "Password",
    confirmPwdPlace: "Confirm Password",
    codePlace: "Invite Code",
    emailTitle: "E-mail",
    phoneTitle: "Phone Number",
    emailPlace: "Email address",
    nameValidate: "6-12 letters, no symbols",
    pwdValidate: "8-32 characters enter your new password",
    forgetPwd: "Forget the password ?",
    smsCodePlace: "Verification code",
    loginBtn: "Login",
    login: "Log in",
    regBtn: "Register",
    submitBtn: "Submit",
    noAccount: "Don’t have an account?",
    regNow: "Register now",
    protocalAccept: "I‘m 18 years old and accept the ",
    protocal: "privacy policy & Terms and conditions",
    hasAccount: "Already have an account?",
    logShowFav: "Log in to view your favorites",
    noFav: "You haven't bookmarked any games yet.",
    goLogin: "Click to register or login",
    level: "Level",
    currentLevel: "Current level",
    exceed: "Exceeded",
    upgrade: "Upgrade requirements",
    rechargeAmount: "Recharge amount",
    wagerAmount: "Wager amount",
    benefit: "Benefit",
    withPerDay: "Withdraw times per day",
    amountPerDay: "Amount withdrawn per day",
    nextLevel: "Next Level",
    clicktoLogin: "Click to Login",
    profile: "Profile",
    accountname: "Account Name",
    signOut: "Sign Out",
    confirmSignOut: "Confirm to sign out?",
    save: "Save",
    cancel: "Cancel",
    success: "success",
    wrongEmail: "Incorrectly formatted mailbox",
    pwdNotComplete: "Passwords are inconsistent",
    checkFirst: "Please read and accept the agreement first",
    fill: "Please fill first",
    changepwd: "Change Password",
    originalpassword: "Original password",
    newpassword: "New password",
    newpassword1: "8-32 characters enter your new password",
    confirmpassword: "Confirm password",
    faq: "FAQ",
    enterquestion: "Please enter your question",
    uploading: "Upload...",
    uploadpic: "Please upload picture",
    write: "write a message...",
    service: "Service time: Everyday for 24 hours",
    contactus: "CONTACT US",
    allGames: "All Games",
    bonus: "bonus",
    invitefri: "Invite Friends",
  },
  game: {
    fav: "Favorites",
    title: "Games",
    placeholder: "Please enter the game you want to play",
    demo: "Demo",
    play: "Play",
    recentBets: "Recent Bets",
    myBets: "My Bets",
    player: "Player",
    bets: "Bets(Rp)",
    profit: "Profit(Rp)",
    provider: "Providers",
    more: "More Games",
  },
  app: {
    home: "Home",
    invite: "Invite",
    wallet: "Wallet",
    promosi: "Promosi",
    account: "Account",
  },
  invite: {
    referencecode: "Reference Code",
    record: "Invitation record",
  },
  wallet: {
    wtips:
      "Recharging and playing can improve your level. Then you can get more withdrawal times and amounts",
    moreBonus: "Get More Bonus",
    trans: "Transaction",
    eachRechargeLimit: "Each recharge limit",
    maxWithdraw: "The maximum amount that can be withdrawn is ",
    enternfullame: "Please enter your full name",
    enterphonenumber: "Please input phone number",
    enteremail: "Please enter your email",
    ok: "Ok",
    deposit: "Deposit",
    withdraw: "Withdraw",
  },
  promosi: {
    details: "Details",
  },
  qa: {
    regist: {
      title: "Register",
      q0: "Unable to register, problem with  username",
      q1: "Unable to register, password entered incorrectly",
      q2: "Unable to register, email format is wrong",
      q3: "Unable to register, registration limit has been reached",
      q4: "Unable to register, system error",
      q5: "How to log in with Facebook",
      q6: "How to log in with Google",
      q7: "What is guest login and how to retrieve guest login account",
      a0: "There is something wrong with the username format you entered. Please enter 6-12 numbers or letters without symbols. It is also possible that the username you entered already exists. Please enter another new username to try.",
      a1: "There is something wrong with the password format you entered. Please enter more than 6 digits numbers or letters, excluding symbols. It is also possible that the password you entered for the second time does not match the password you entered for the first time. Please re-enter it.",
      a2: "There is something wrong with the email format you entered, please re-enter it.",
      a3: "There is an upper limit on the number of accounts that each user can register. You can use the previously registered account to play. If you still want to register a new account, please change the device and try.",
      a4: "It may be a temporary abnormality in the network or system. You can change the network environment and restart the app or re-enter the web page. You can also try it at another time.",
      a5: "On the login/registration page, you can click the Facebook icon and follow the prompts to register. You will automatically log in after successful registration.",
      a6: "On the login/registration page, you can click the Google icon and follow the prompts to register. You will automatically log in after successful registration.",
      a7: "Hello, guest login is a quick account registration method we provide you. After registration and login, it will be bound to your device. As long as you use the device, you can log in successfully without filling in the registration information. However, we still recommend that you add your personal information after registration. Please fill in your personal information and email address on the page to prevent your account information from being lost.",
    },
    login: {
      title: "Log in",
      q0: "Username/password is incorrect",
      q1: "Account has been banned",
      q2: "Can't log in with Facebook",
      q3: "Can't log in with Google",
      q4: "What to do if I forget my password",
      a0: "The username and password you entered do not match, please re-enter",
      a1: "Your account may have violated our registered user agreement or code of conduct. We have taken certain measures on your account. The measures may last for a period of time and then be automatically unblocked. You can log in again to play; it may also be permanent. We hope you Ability to abide by platform rules.",
      a2: "Please check whether your Facebook account status is normal. If it is normal and you still cannot log in, please leave us a message.",
      a3: "Please check whether the status of your Google account is normal. If it is normal and you still cannot log in, please leave us a message.",
      a4: "You can click 'Forgot Password' to fill in the information step by step, and we will send a verification email to your email. After entering the correct verification code, you can change your password. Note: If you have never filled in your email address on the platform, you may not be able to use the forgotten password function.",
    },
    topUp: {
      title: "Top up",
      q0: "My top-up order was deducted successfully and the order was generated, but the balance did not increase.",
      q1: "Top-up money has been deducted, but the order is not found in the order list.",
      q2: "No response after clicking Next",
      q3: "Can I top up using a credit card?",
      q4: "What are the currently supported top-up methods?",
      q5: "What is the limit for a single top-up?",
      q6: "Is there a daily top-up limit?",
      q7: "How long does it take for top-up transaction to be credited?",
      q8: "During which time periods can I top-up?",
      q9: "Are there any top-up promotions?",
      a0: "If you have just top-up, you can try to wait for a while, it may be delayed for a while due to channel or network reasons.",
      a1: "If you have top-up for more than a few hours and has not arrived, please leave a message.",
      a2: "Please confirm whether the top-up amount you entered is within the valid range and the information filled in is correct. If all the above are correct, it may be due to network fluctuations or temporary system abnormalities. You can change the network environment and restart the app or re-enter the web page. You can also try again later.",
      a3: "Hello, credit card top-up is currently not supported, we are working hard to make it happen.",
      a4: "Currently, the main top-up methods supported include DANA, BRI, BNI, etc., which will be adjusted according to channel support. You can also check the currently supported top-up methods on the top-up page.",
      a5: "The current single top-up limit is: 10.000 - 50.000.000. You can also check the prompts on the top-up page.",
      a6: "There is currently no transaction limit, but there may be certain channel restrictions. It is recommended that you play in moderation.",
      a7: "Under normal circumstances, it will arrive immediately. If there is a very small chance that you encounter network fluctuations or system delays, it may arrive within a few hours. If your top-up order does not arrive for a long time, please leave a message.",
      a8: "You can top-up at any time and we will handle it for you in a timely manner",
      a9: "For recharge activities, you can pay attention to the Promosi section or system message notification on the App or web page.",
    },
    withDraw: {
      title: "Withdraw",
      q0: "I have submitted a withdrawal request, but it has not been credited yet.",
      q1: "Withdrawal request failed.",
      q2: "Unable to submit a withdrawal request, showing 'exceeding limit range'.",
      q3: "Unable to submit a withdrawal request, showing 'exceeding withdrawal frequency'.",
      q4: "How long does it take for a withdrawal to be credited?",
      q5: "What should I do if I have used up my withdrawal limit and frequency?",
      q6: "Daily withdrawal limit and frequency refresh time.",
      q7: "Can I withdraw from the bonus account balance?",
      a0: "Please confirm that the withdrawal information you submitted is correct and the format is correct. Please confirm that there is no abnormality in the status of your credited account. If you have just submitted an order, you can check your account balance later. The transaction may be delayed due to network fluctuations or system delays.",
      a1: "If you confirm that your information is correct and it has not been credited for more than a few days, please leave a message",
      a2: "Hello, there are many reasons for withdrawal failure. If an error occurs when you withdraw, indicating that you failed, please check whether the withdrawal information you filled in is correct, whether the format is correct, and whether the receiving account status is normal. Please confirm your withdrawal amount. and whether the number of withdrawals is within the limit range for the day. If you confirm that the above information is correct, you can try to withdraw money again later.",
      a3: "If you has not received money for a long time after successfully submitting the application, please wait patiently. If the information is correct, it will usually arrive immediately; in special circumstances,may be delayed due to network fluctuations or system delays, and manual intervention may be required at this time , it may take up to 7 working days to arrive, please understand.",
      a4: "Hello, the daily withdrawal limit and number of withdrawals in your account are adjusted according to your current level. You can check your current level and corresponding withdrawal benefits on the personal information, wallet or withdrawal pages in the App or web page, such as the number of withdrawals on the day. Once the withdrawal limit is used up, you will no longer be able to initiate a withdrawal application. You can upgrade your level to obtain more withdrawal benefits or wait for the daily 24:00 reset.",
      a5: "Hello, under normal circumstances, withdrawals arrive immediately. In special circumstances, the withdrawal may be delayed due to network fluctuations or system delays. At this time, there may be manual intervention, and it may take 7 working days at the latest. We are trying our best. Please understand",
      a6: "Hello, the daily number of withdrawals and the withdrawal limit are refreshed at 24:00 every day.",
      a7: "Hello, the current Bonus account balance does not support cash withdrawal. You can consume it normally in the game. For details, please see the instructions on the wallet page.",
    },
    level: {
      title: "Level",
      q0: "How to increase my level?",
      q1: "What are the benefits of leveling up?",
      q2: "I have met the level upgrade requirements, but have not upgraded yet.",
      q3: "Can the level cap be raised?",
      a0: "Hello, successful recharge and game betting turnover are the main conditions for upgrading at present. You can enter the level entrance on the personal information, wallet, withdrawal and other pages to view the detailed upgrade amount requirements.",
      a1: "After the level is upgraded, we will increase the number and amount of daily withdrawals for you, provide you with private VIP services, and other more rights and interests. For details, you can enter the level entrance on the personal information, wallet, withdrawal and other pages to view, or contact private customer service",
      a2: "Hello, if you have met the upgrade conditions, you can try to exit the page and re-enter. It may be that your level has been improved but the page has not been refreshed. If you still have not upgraded, you can check again later. It may be your recharge turnover or bet. The turnover is still being settled statistically, there is a certain delay, and it has not yet been included in your level.",
      a3: "Hello, the current highest level is LV 8, and higher levels have not been opened yet. If you have reached this level, your recharge and betting turnover will also be counted normally. Once a new level is opened, the upgrade will be done automatically",
    },
    invite: {
      title: "Invite",
      q0: "How do I invite friends to join this platform?",
      q1: "What counts as a successful invitation?",
      q2: "What rewards will I receive for successful invitations?",
      q3: "What rewards will my friends receive?",
      q4: "How to view invitation records?",
      q5: "How to view reward records?",
      q6: "How to use the rewards obtained?",
      q7: "I successfully invited someone, but did not receive the reward.",
      a0: "You can find the entrance on the event page or Invite page, and share your link or invitation code with your friends. Your friends will successfully register using your invitation link, or fill in your invitation code when registering. After the friend successfully registers and After the first successful recharge, the invitation is deemed successful, and you can receive invitation rewards. You can view the detailed rules on the event page.",
      a1: "Your friend successfully registers using your invitation link, or fills in your invitation code when registering. The invitation will be deemed successful after the friend registers successfully and recharges successfully for the first time.",
      a2: "After successfully inviting friends, you can get Bonus, coupons, etc. For details, please check the App or web activity page",
      a3: "After successfully inviting friends, your friends can also get Bonus, coupons, first-time recharge bonus, etc. For details, please check the App or web activity page",
      a4: "You can click on any statistical data in the App or web activity page to view details.",
      a5: "You can click on any statistical data in the App or web activity page to view details.",
      a6: "If you get a Bonus reward, you can use it to deduct cash when playing games; for coupons, first deposit bonuses, etc., please check the event page for details",
      a7: "Hello, your friend needs to successfully register using the link or invitation code you shared and complete the first recharge to be considered a successful invitation. Please confirm that your friend has met the above conditions. The invitation rewards will be distributed to your account after the statistics are completed. There may be a certain delay, please wait patiently.",
    },
    play: {
      title: "Play Game",
      q0: "How do I play games?",
      q1: "What is demo mode?",
      q2: "Is there simulated money?",
      q3: "The game screen turns white upon entry, not loaded successfully, unable to play the game.",
      q4: "Unable to enter the game, black screen.",
      q5: "Game lagging, slow loading.",
      q6: "How to add games to favorites?",
      q7: "How to view the list of favorite games?",
      a0: "Hello, you can click on any game in the lobby to enter, select Play to play, and how to play the game can be viewed by clicking on the detailed introduction in the game. Some games also provide a trial mode. Click Demo to try it out. During the trial, it will provide simulated amount currency for you to experience as much as you want",
      a1: "Hello, the Demo mode is a trial mode. Some games provide a Demo mode option. Click Demo to try it out. Simulated currency will be provided during the trial, so you can experience it to your heart's content.",
      a2: "Hello, the Demo mode is a trial mode. Some games provide a Demo mode option. Click Demo to try it out. Simulated currency will be provided during the trial, so you can experience it to your heart's content.",
      a3: "Hello, if you encounter problems such as the screen turning white or black when entering the game, it may be due to network fluctuations or system abnormalities. You can try changing the network, exiting the app and re-entering. If the problem still persists, you can try changing the network. Play again for a while",
      a4: "Hello, if you encounter problems such as the screen turning white or black when entering the game, it may be due to network fluctuations or system abnormalities. You can try changing the network, exiting the app and re-entering. If the problem still persists, you can try changing the network. Play again for a while",
      a5: "Hello, if you encounter problems such as the screen turning white or black when entering the game, it may be due to network fluctuations or system abnormalities. You can try changing the network, exiting the app and re-entering. If the problem still persists, you can try changing the network. Play again for a while",
      a6: "Hello, there are currently two ways to add a game to your favorites list: 1. Click ⭐️ on the game details page; 2. After entering the game, you can click ⭐️ above the game. After adding to the collection, you can find the collection list in the personal center and manage your collection of games in the list.",
      a7: "After adding to the collection, you can find the collection list in the personal center and manage your collection of games in the list.",
    },
    promosi: {
      title: "Promosi",
      q0: "Where to check for activities?",
      q1: "How to participate in promotion activities?",
      q2: "How to obtain promotion activity rewards?",
      a0: "Hello, you can check the current and upcoming activities on the App or web homepage and Promosi page. The current participation in activities of the game will also be displayed on each game details page. Some activities do not require registration and will automatically participate; Some activities may require registration, you can choose to participate according to your own circumstances",
      a1: "You can click on the event introduction page to enter the event details. Events that do not require registration will be automatically participated. Some activities may require registration within the event period. You can choose to participate within the event period according to your own situation.",
      a2: "Hello, event rewards will vary depending on the event content and rules. Some event rewards may be issued in real time, while others may need to wait for the event to end. It is recommended that you check the detailed rules on the event page.",
    },
    other: {
      title: "Other",
      q0: "How to reset the password?",
      q1: "How to modify personal information?",
      q2: "How to recover a forgotten account?",
      a0: "Hello, you can find the password reset entrance on the personal information page. Enter your old password for verification and then enter the new password to complete the reset.",
      a1: "Hello, you can find the personal information modification entrance on the personal information page. After the modification is completed, click Save to complete the modification. It is strongly recommended that you add email information. This email can help you reset your password if you forget it.",
      a2: "Hello, if you have forgotten your password, you can use the Forgot Password function to reset it; if you have forgotten your username, you can leave a message and provide us with your email address, recharge records, withdrawal records and other account information, and we will help you retrieve your account.",
    },
  },
  message: {
    ept: "Empty",
    empty: "There is currently no history",
    loading: "loading",
    copied: "copied success",
    copyfailed: "copy failed",
    refreshLoose: "Release to refresh",
    refreshPull: "Pull down to refresh",
    viewmore: "view more",
    guestlogin: "Guest Login",
    donhaveanaccount: "Don't have an account?",
    retrievepassword: "Retrieve password",
    pleaselogin: "Please Login",
    choosepaymethod: "Choose Pay Method",
    alreadyhave: "Already have an account?",
    yearsold: "I'm 18 years old and accept the",
    privacypolicy: "privacy policy & Terms and conditions",
    accountregistration: "Account registration",
    registernow: "Register now",
    byclickingon:
      "By clicking on 'Visitor/Account/Facebook', you have read and agree to the ",
    termsandconditions:
      "terms and conditions as well as the privacy policy of xxxx.",
    clicktocopyvanumber: "Click to copy VA Number",
    copyvanumber: "Copy VA Number ",
    Ihavepaid: "I Have Paid",
    ownloadqrcode: "ownload Qrcode",
    takeascreenshot: "Take a screenshot and save it",
    aftercopying: "After copying, please go to the app to make the payment",
    nomorvirtualaccount: "Nomor Virtual Account",
    howtopay: "How to pay?",
    pleasetransferto: "Please Transfer to",
    totalpayment: "Total payment",
    orcontinuewith: "Or continue with",
    signinwithaccount: "Sign in with account",
    connectwithfacebook: "Connect with  Facebook",
    verificationcode: "Verification code",
    usernameeamil: "User name",
    welcome: "Welcome to Lucky Casino",
    phone: "Phone number",

    pwd: "Password",

    confirmpwd: "Confirm Password",

    forgetpwd: "Forget the password",

    login: "Login",

    // "privacypolicy": "I am 18 years old and accept the privacy policy $ Terms and conditions",

    subrigister: "Register to get 10000",

    confirm: "Ok",

    policytitle: "Privacy policy",

    net_sucess: "Succeed",

    withdraw: "Withdraw",

    faq: "FAQ",

    deposit: "Deposit",

    recharge: "Recharge",

    selectpayment: "Select account type",

    reminder: "Reminder",

    total: "Total",

    language: "LANGUAGE",

    avaiblewithdraw: "Available for withdraw",

    amount: "Amount",

    enteramount: "Please enter the amount",

    personalcenter: "My Profile",

    username: "User Name",

    changepwd: "Change Password",

    signout: "Sign out",

    modifyinfo: "Modify Infomation",

    ok: "Ok",

    changepasssword: "Change Password",

    originalpassword: "Original password",

    newpassword: "New password",

    originalpassword1: "8-32 characters",

    newpassword1: "8-32 characters enter your new password",

    confirmpassword: "Confirm password",

    confirmpassworddesc: "confirm your new password",

    amount1: "Amount",

    status: "Status",

    date: "Date",

    rechargeone: "1. Only Indonesian local currency recharging is supported.",

    rechargetwo: "2. The minimum value of a single recharging is 10000.",

    rechargethree:
      "3. Recharge the account immediately. If the account does not arrive within 30 minutes, please contact customer service",

    next: "Next",

    verifyidentity: "Verify identity",

    tryagain: "Try again later",

    entermobile: "Please enter your mobile number",

    enteremail: "Please enter your email",

    reset: "Reset",

    enterquestion: "Please enter your question:",

    uploading: "Upload...",

    uploadpic: "please upload picture",

    pleaseinputrechargeamount: "Please input recharge amount!",

    rechargeamountmustbegreater: "Recharge amount must be greater than 10000",

    maxamountis: "max amount is 10000000",

    pleaseinputphonenumber: "Please input phone number",

    wrongemail: "Wrong Email",

    donotclickanybuttons: "Loading,do not click any buttons",

    inputqustion: "Please input qustion!",

    uploadpicture: "Upload picture",

    passwordcannotbelessthaneightdigits:
      "Password cannot be less than eight digits",

    inconsistentpasswords: "Inconsistent passwords",

    pleaseagreetoprivacyagreement: "Please agree to privacy agreement",

    currencysign: "IDR",

    pleaseenteroriginalpassword: "Please enter original password",

    pleaseenterpassword: "Please enter password",

    pleaseenterpasswordagain: "Please enter password again",

    thetwopasswordsaredifferent: "The two passwords are different",

    youdonthaveenoughbalance: "You don't have enough balance",

    submittedsuccessfully: "Submitted successfully",

    pleaseinputnumber: "please input number",

    register: "Register",

    branddesc1: "6-12 letters, no symbols",

    branddesc2: "At lleast 6 charaters",

    email: "Email",

    inputusername: "input user name",

    gametitle1: "RECOMMEND GAMES",

    home: "HOME",

    games: "GAMES",

    promos: "PROMOSI",

    referral: "REFERRAL",

    info: "INFO",

    contactus: "CONTACT US",

    back: "BACK",

    slots: "SLOTS",

    sports: "SPORTS",

    casino: "CASINO",

    p2p: "P2P",

    fishhunter: "FISH-HUNTER",

    lottery: "LOTTERY",

    egame: "E-GAMES",

    cockfight: "COCK FIGHT",

    left: " -<![CDATA[<]]>",
    right: " -<![CDATA[>]]>",

    slotsproviders: "SLOTS PROVIDERS",

    demo: "DEMO",

    playnow: "PLAY NOW",

    news: "NEW",

    tops: "TOP",

    alls: "ALL",

    gametitle2: "HOT GAMES",

    gametitle3: "NEW GAMES",

    contactdesc:
      "Customer Support Specialists are available everyday for 24 hours. ",

    nowhatsapp: "install WhatsApp",

    entername: "Please enter your name",

    membershiplevel: "Membership Level",

    accountname: "Account Name",

    referencecode: "Reference Code",

    walletbalance: "WALLET BALANCE",

    copiedtomemory: "copied to memory",

    enteracountno: "enter acount no",

    resend: "Resend",

    verificationcodebeen:
      "Verification Code have been send,open your email to get it",

    enternfullame: "Please enter your full name",

    serviceprivileges: "SERVICE PRIVILEGES",

    averagetime: "Average time",

    mins: "1 Mins",

    mins3: "3 Mins",

    user: "User",

    numberbet: "number bet",

    online: "Online",

    putinfo: "INFO CENTER",

    more: "More",

    sportsbookplayer: "SportsBook Player",

    slotplaying: "Slot Playing",

    depositingmethod: "Depositing Method",

    withdrawmethod: "Withdraw Method",
    termsconditions: " -<![CDATA[TERMS&CONDITIONS]]>",

    howtoplay: "HOW TO PLAY",
    determine: "determine",
    memberservice: "MEMBER SERVICE",

    whatsapp: "WHATSAPP",

    supporttime: "24/7 support",

    serviceproviderplatform: "Service Provider Platform",

    paymentmethod: "Payment Method",

    dana: "DANA",

    memo: "MEMO",

    live: "LIVE",

    english: "English",

    indonesian: "Indonesian",

    pressagaintoexitthe: "Press again to exit ",

    invitedfriend: "Invite friends",

    invitecode: "Reference Code / Afiliation",

    step: "Step",

    step01: "Step 01",

    invite1: "Give a link to your friend",

    invite2: "Invite now",

    invite3: "Step 02",

    invite4: "Friends accept your invitation",

    invite5: "Copy invitation code",

    invite6:
      "Friends fill in your invitation code in mine - Invite friends - Bind and invite friends to bind",

    invite7: "Step 03",

    invite8: "Friends register and finish the first reload",

    invite9: "Step 04",

    invite10: "Get a present now",

    invite11: "First major prize",

    invite12: "Number of first re-filled people invited",

    invite13: "Inviters get gifts",

    invite14: "Rule",

    invite15:
      "1.The amount of bonuses can only be used by the user to bet, not possible for direct withdrawal, the amount that wins can be withdrawn at any time; <br><br> 2.A friend you invite succeeds in listing, after your friend's refill succeeds, that amount will be distributed to your gift account;<br><br>3.The higher the number of first reloads per friend, the higher the reward you get;<br><br>4.Invitation events can only get the first rewards of a friend. At the same time, it can be gathered with other gifts.<br><br>5.The final interpretation right of all activities belongs to the platform. For details, call platform customer service.<br><br>",

    invite16: "List to get a World Cup quiz coupon in certain amounts:",

    invite17:
      "1.Daftar untuk mendapatkan **kupon kuis Piala Dunia, bisa langsung digunakan dan dipotong, berlaku selama 3 hari; <br><br>2.World Cup quiz coupons are only used to bet on some games in the prize group: big 16 quiz, big 8 quiz, big 4 quiz, champion and runner-up way to play champion. <br><br>3.The bet option, the number of wins that can be drawn, wins a pool of billions free of charge; <br><br>4.Whoever badly intends to register for a platform prize, the system will reduce the appropriate prize, if included in a serious case, then the account will be deleted. <br><br>5.The same device and the same IP will be considered as the same account; The final interpretation right of all activities belongs to the platform. For details, call platform customer service.",
  },
};
