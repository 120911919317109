var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"game-item",style:({
    width: _vm.$px2rem(_vm.imgWidth + 'px'),
    'flex-basis': _vm.$px2rem(_vm.imgWidth + 'px'),
  })},[_c('img',{style:({
      width: _vm.$px2rem(_vm.imgWidth + 'px'),
      height: _vm.$px2rem(_vm.imgWidth + 'px'),
    }),attrs:{"src":_vm.imgUrl,"alt":_vm.title}}),_c('span',{staticClass:"game-item-name",attrs:{"title":_vm.title}},[_vm._v(_vm._s(_vm.title))])])
}
var staticRenderFns = []

export { render, staticRenderFns }