import router from "@/router";
import { apiUserInfo, apiUserBalance } from "@api/account";
import { fbCookie } from "@api/home";
const state = {
  userInfo: null,
  token: "",
  showPop: false,
  openLogin: false,
  showReg: false,
  openOperate: false,
  operate: "",
  balance: 0,
};

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_USERBALANCE: (state, balance) => {
    state.balance = balance;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_OPEN: (state, open) => {
    if (open == 2) {
      state.openLogin = true;
      state.showReg = true;
    } else {
      state.openLogin = open;
      state.showReg = false;
    }
  },
  SET_POP: (state, pop) => {
    state.showPop = pop;
  },
  SET_OpenOearate: (state, open) => {
    state.openOperate = open;
  },
  SET_TYPE: (state, operate) => {
    state.operate = operate;
  },
  set_showReg: (state, show) => {
    state.showReg = show;
  },
  logOut: (state) => {
    state.token = "";
    state.userInfo = null;
    state.balance = 0;
    // state.showPop = false;
    router.replace("/");
  },
};

const actions = {
  setOpen: ({ commit }, open) => {
    commit("SET_OPEN", open);
    return new Promise((resolve, reject) => {
      var allcookies = document.cookie,
        cookiearray = allcookies.split(";");
      var _fbc = "",
        _fbp = "";
      // 获取cookie中的fbp、fbc
      for (var i = 0; i < cookiearray.length; i++) {
        var cookie = cookiearray[i].replace(" ", "");
        var name = cookie.split("=")[0],
          value = cookie.split("=")[1];
        if ("_fbp" == name) {
          _fbp = value;
          continue;
        }
        if ("_fbc" == name) {
          _fbc = value;
          continue;
        }
      }
      fbCookie({
        url: window.location.href,
        fbc: _fbc,
        fbp: _fbp,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setToken: ({ commit }, token) => {
    commit("SET_TOKEN", token);
  },
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      apiUserInfo({})
        .then((res) => {
          commit("SET_USERINFO", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUserBalance({ commit }) {
    return new Promise((resolve, reject) => {
      apiUserBalance({})
        .then((res) => {
          commit("SET_USERBALANCE", res.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user login //{commit}
  // login(userInfo) {
  //   const { username, password, captcha } = userInfo;
  //   return new Promise((resolve, reject) => {
  //     login({ username: username.trim(), password: password, captcha })
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
  // 账号密码登录
  // accountPasswordLogin({ commit }, userInfo) {
  //   const { enterpriseUserName, password } = userInfo;
  //   return new Promise((resolve, reject) => {
  //     enterpriseUserIdPassword({
  //       enterpriseUserName: enterpriseUserName.trim(),
  //       password: password,
  //     })
  //       .then((res) => {
  //         commit("SET_ACCOUNTNUMBER", res.data.enterpriseUserName);
  //         resolve(res);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
  // 手机号、验证码登录
  // verificationCodeLogin({ commit }, userInfo) {
  //   const { phoneNum, verificationCode } = userInfo;
  //   return new Promise((resolve, reject) => {
  //     verificationCodeType({
  //       phoneNum: phoneNum,
  //       verificationCode: verificationCode,
  //     })
  //       .then((res) => {
  //         commit("SET_ACCOUNTNUMBER", res.data.enterpriseUserName);
  //         resolve(res);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
