import request from "@/service/request";

export function homeCard() {
  return request({
    url: "/version/config/index/card",
    method: "get",
  });
}

export function collectPage(params, postUrl) {
  return request({
    url: postUrl,
    method: "get",
    params,
  });
}

export function gameList(params) {
  return request({
    url: "/game/play",
    method: "get",
    params,
  });
}

export function gameDetail(params) {
  return request({
    url: "/game/detail",
    method: "get",
    params,
  });
}

export function supplierList(params) {
  return request({
    url: "/game/supplier",
    method: "get",
    params,
  });
}

export function typeList(params) {
  return request({
    url: "/game/type",
    method: "get",
    params,
  });
}

export function apiCollect(data, url, method) {
  return request({
    url,
    method,
    data,
  });
}

export function gameUrl(data) {
  return request({
    url: "/game/url",
    method: "post",
    data,
  });
}

export function serviceConfig(params) {
  return request({
    url: "/service/config",
    method: "get",
    params,
  });
}

export function serviceList(data) {
  return request({
    url: "/service/list",
    method: "post",
    data,
  });
}

export function homePop(params) {
  return request({
    url: "/version/config/index/popup",
    method: "get",
    params,
  });
}

export function fbCookie(data) {
  return request({
    url: "/app/fb",
    method: "post",
    data,
  });
}
