<script>
import { jwtDecode } from "jwt-decode";
import { otherLogin } from "@api/account";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "minxin-google-login",
  data() {
    return {
      clientId:
        "582861086537-a0e028nm5gubmv9f4vsdsp91uk7d3178.apps.googleusercontent.com",
    };
  },
  computed: {
    ...mapGetters(["device", "appname"]),
  },
  methods: {
    ...mapActions("user", [
      "setToken",
      "setOpen",
      "getUserInfo",
      "getUserBalance",
    ]),
    handleCredentialResponse(res) {
      const responsePayload = jwtDecode(res.credential);
      otherLogin({
        token: "",
        invitedCode: "",
        appName: this.appname,
        collapse: "0",
        thirdParty: 2,
        gddddd: "",
        network: "",
        googleUserId: responsePayload.sub,
        googleUserName: responsePayload.name,
        googleUserEmail: responsePayload.email,
      }).then((res) => {
        if (res.code == 200) {
          this.$toast({
            message: res.message,
            onClose: () => {},
          });
          if (this.device == "mobile") {
            this.onlogSuccess(res.data.authToken);
          } else {
            this.setToken(res.data.authToken);
            this.setOpen(false);
            this.getUserInfo();
            this.getUserBalance();
          }
        }
      });
    },
  },
  mounted() {
    let googleScript = document.createElement("script");
    googleScript.src = "https://accounts.google.com/gsi/client";
    document.head.appendChild(googleScript);

    googleScript.onload = () => {
      window.google.accounts.id.initialize({
        client_id: this.clientId,
        callback: this.handleCredentialResponse,
        ux_mode: "popup",
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signin_button"),
        {
          size: "large",
          locale: "en-GB",
          theme: "filled_black",
          width: "300",
        } // customization attributes
      );
      // window.google.accounts.id.prompt();
    };

    // window.addEventListener("load", () => {
    //   console.log(window.google, document.getElementById("pcGLogin"));
    //   window.google.accounts.id.initialize({
    //     client_id: this.clientId,
    //     callback: this.handleCredentialResponse,
    //     ux_mode: "popup",
    //   });

    //   window.google.accounts.id.renderButton(
    //     document.getElementById("signin_button"),
    //     {
    //       size: "large",
    //       locale: "en-GB",
    //       theme: "filled_black",
    //       width: "300",
    //     } // customization attributes
    //   );
    //   window.google.accounts.id.prompt();
    // });

    // google.accounts.id.initialize({
    //   client_id: "xxx",
    //   callback: (loginData) => {
    //     // 此时loginData里面包含了用户的一些信息，可以发送给后端交换我们自己的用户信息数据
    //     console.log(loginData);
    //   },
    // });
  },
};
</script>
