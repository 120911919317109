<template>
  <div class="withdrawf">
    <div class="withdrawf-top">
      <!-- 标题 -->
      <div class="header-wrap">
        <div class="header-wrap-header">
          <page-header
            :isHome="false"
            :isTabPage="false"
            :pageTitle="pageData?.pageName"
          />
        </div>
      </div>
      <!-- 表单 -->
      <div class="blockWidth withdrawf-block">
        <div class="withdrawf-title">{{ pageData?.optionName }}</div>
        <div class="withdrawf-optionList-parent">
          <van-field
            v-model="availableName"
            placeholder=""
            :border="false"
            class="account-field"
            readonly
            @click="showPicker = true"
            right-icon="arrow-down"
          />
          <div
            class="selectOverlay"
            v-show="showPicker"
            @click="showPicker = false"
          ></div>
          <div :class="['withdrawf-optionList', { listAnimate: showPicker }]">
            <p
              v-for="item in list"
              :key="item.value"
              @click="handleSelect(item.value)"
            >
              {{ item.text }}
              <img
                v-show="availableName == item.value"
                src="@icon/check.png"
                alt=""
              />
            </p>
          </div>
        </div>

        <!-- <van-dropdown-menu active-color="#159046">
          <van-dropdown-item v-model="availableName" :options="list" />
        </van-dropdown-menu> -->

        <div class="withdrawf-title">{{ pageData?.firstLineName }}</div>
        <van-field
          v-model="accountNo"
          placeholder=""
          :border="false"
          class="account-field"
        />
      </div>
      <div class="blockWidth withdrawf-block">
        <div class="withdrawf-title">{{ $t("wallet.enternfullame") }}</div>
        <van-field
          v-model="name"
          placeholder=""
          :border="false"
          class="account-field"
        />
        <div class="withdrawf-title">{{ $t("wallet.enterphonenumber") }}</div>
        <van-field
          v-model="phone"
          placeholder=""
          :border="false"
          class="account-field"
        />
        <div class="withdrawf-title">{{ $t("wallet.enteremail") }}</div>
        <van-field
          v-model="email"
          placeholder=""
          :border="false"
          class="account-field"
        />
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="page-bottom">
      <div class="page-bottom-fixed">
        <van-button
          class="hasColor"
          block
          :loading="loading"
          @click="handelWidthdraw"
          :disabled="!name || !phone || !email || !availableName || !accountNo"
        >
          {{ $t("wallet.ok") }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, DropdownMenu, DropdownItem } from "vant";
import {
  withdrawFormPage,
  withdrawBankList,
  userWithdrawInfo,
  apiWithdraw,
} from "@api/wallet";
import { checkEmail } from "@/utils/tools";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "withdrawForm",
  components: {
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  props: {
    amountW: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pageData: null,
      showPicker: false,
      list: [],
      name: "",
      email: "",
      phone: "",
      accountNo: "",
      availableName: "",
      userSaveList: [],
      loading: false,
      amount: 0,
    };
  },
  watch: {
    availableName(val) {
      this.userSaveList.forEach((element) => {
        if (element.accountName == val) {
          this.accountNo = element.accountNo;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["device"]),
  },
  methods: {
    ...mapMutations("user", ["SET_OpenOearate", "SET_TYPE"]),
    handleSelect(value) {
      this.availableName = value;
      this.showPicker = false;
    },
    onGetData() {
      withdrawFormPage().then((res) => {
        this.pageData = res.data;
      });
      withdrawBankList().then((res) => {
        this.list = res.data.map((item) => {
          return { text: item, value: item };
        });
        this.availableName = this.list[0].value;
        userWithdrawInfo().then((res) => {
          if (res.code == 200 && res.data.length > 0) {
            this.userSaveList = res.data;
            this.availableName = this.userSaveList[0].accountName;
            this.accountNo = this.userSaveList[0].accountNo;
          }
        });
        // this.availableName = this.list[0].value;
      });
    },
    handelWidthdraw() {
      if (!checkEmail(this.email)) {
        return this.$toast(this.$t("account.wrongEmail"));
      }
      this.loading = true;
      apiWithdraw({
        name: this.name,
        phone: this.phone,
        email: this.email,
        amount: this.amount,
        accountName: this.availableName,
        accountNo: this.accountNo,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.loading = false;
              },
            });
            if (this.device == "desktop") {
              this.SET_OpenOearate(false);
            } else {
              this.$router.go(-1);
            }
          } else {
            this.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
  created() {
    this.amount = this.$route.query.amount || this.amountW;
    this.onGetData();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.withdrawf {
  @include flexColumn;
  align-items: normal;
  height: 100%;
  &-optionList-parent {
    position: relative;
    .account-field {
      background: #222637;
    }
  }
  &-optionList {
    height: 0;
    background: #222637;
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 100px;
    z-index: 3;
    border-radius: 0 0 8px 8px;
    transition: all 0.2s;
    p {
      height: 88px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.1);
      color: #fff;
      font-size: 32px;
      padding-left: 20px;
      @include flexRowBetween;
    }
    img {
      width: 32px;
      height: 32px;
      margin-right: 30px;
    }
  }
  .selectOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .listAnimate {
    height: 528px;
  }
  .account-field {
    font-weight: 400;
    font-size: 32px;
  }
  &-title {
    color: $text-gray;
    font-size: 32px;
    margin: 30px 0 10px 0;
  }
  &-block {
    background: #1c1f2e;
    border-radius: 17px 17px 17px 17px;
    padding: 2px 20px 32px;
    box-sizing: border-box;
    margin: 20px auto;
  }
}
.withdrawf-top {
  flex: 1;
  overflow: auto;
  padding-bottom: 40px;
}
</style>
