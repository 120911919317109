<template>
  <div class="SwiperCombine">
    <swiper
      ref="mySwiper"
      :class="['my-swiper', classname]"
      :options="swiperOptions"
      @slide-change="onChange"
    >
      <!-- @slide-change="onChange" -->
      <!--  -->
      <swiper-slide v-for="(item, index) in bannerList" :key="index">
        <slot :item="item" :index="index">
          <img
            :data-link="item.link"
            :src="item.imageUrl || item.banner"
            :alt="item.title || ''"
            class="my-swiper-img"
          />
          <!-- v-lazy="item.imageUrl" -->
        </slot>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev swiper-button-custom" slot="button-prev">
        <img src="@icon/left.png" alt="" />
      </div>
      <div class="swiper-button-next swiper-button-custom" slot="button-next">
        <img src="@icon/right.png" alt="" />
      </div>
    </swiper>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "SwiperCombine",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    bannerList: {
      type: Array,
      default: () => [],
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    classname: {
      type: String,
      default: "",
    },
    swiperOptions: {
      type: Object,
      default: () => {
        return {
          spaceBetween: 10,
          centeredSlides: true,
          effect: "coverflow",
          slidesPerView: "auto",
          loop: true, //循环
          observer: true,
          observeParents: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          on: {},
        };
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    activeIndex: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.mySwiper.swiper.slideTo(newVal);
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["device"]),
  },
  methods: {
    onChange() {
      let activeIndex = this.$refs.mySwiper.swiper.activeIndex;
      this.$emit("onSlideChange", activeIndex);
    },
  },
  created() {
    this.deviceName = this.device;
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.SwiperCombine {
  margin-top: 14px;
  @media screen and (min-width: 720px) {
    margin-top: 0;
  }
}
::v-deep.swiper-pagination {
  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
  }

  .swiper-pagination-bullet-active {
    background-color: $text-green;
  }
}
::v-deep.bottomUnder .swiper-wrapper {
  padding-bottom: 35px;
}
.swiper-slide {
  width: calc(100% - 40px);
  // margin: auto;
}
.my-swiper {
  // @media screen and (min-width: 720px) {
  //   height: 246px;
  // }

  &-img {
    width: 100%;
    border-radius: 8px;
    height: 296px;
    display: block;
    // @media screen and (min-width: 720px) {
    //   height: 246px;
    // }
  }
}
.pcLevel {
  @media screen and (min-width: 720px) {
    width: calc(100% - 40px);
    margin: auto;
  }
  .swiper-wrapper {
    @media screen and (min-width: 720px) {
      align-items: stretch;
    }
  }
  .my-swiper {
    @media screen and (min-width: 720px) {
      height: 394px;
    }
  }
  .swiper-slide {
    @media screen and (min-width: 720px) {
      width: 888px;
      height: 1405px;
    }
  }
  .swiper-button-custom {
    @media screen and (min-width: 720px) {
      display: none;
    }
  }
}
.swiper-button-custom {
  width: 45px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  height: 45px;
  display: none;
  img {
    width: 26px;
    height: 26px;
  }
  @media screen and (min-width: 720px) {
    @include flexRowCenter;
  }
}
</style>
