<template>
  <div class="pcLogin">
    <van-popup
      :value="openLogin"
      :close-on-click-overlay="false"
      :style="{ width: '55%', height: $px2rem('450px'), background: 'none' }"
    >
      <div class="pcLogin-wrap">
        <van-icon
          name="cross"
          color="#71737C"
          size="20"
          class="pop-close"
          @click="handleClose"
        />
        <div class="pcLogin-aside pcLogin-wrap-left">
          <!-- <img src="@imgs/loginBg.png" alt="game" /> -->
          <div class="pcLogin-aside-content">
            <p>WELCOME TO</p>
            <img src="@icon/indialogo.png" alt="" />
            <p>Start your game journey now</p>
          </div>
        </div>
        <div class="pcLogin-aside pcLogin-wrap-right">
          <!-- tab -->
          <van-tabs
            v-model="active"
            background="none"
            color="#17CC5F"
            title-active-color="#fff"
            title-inactive-color="rgba(255,255,255,.4)"
            line-width="calc(100% / 2)"
            line-height="2"
            v-show="!isForget"
          >
            <van-tab title="Sign in">
              <!-- 登录 -->
              <van-field
                v-model="username"
                label=""
                :border="false"
                :placeholder="$t('account.namePlace')"
              />
              <van-field
                v-model="password"
                label=""
                :border="false"
                :placeholder="$t('account.pwdPlace')"
              />
              <p class="pcLogin-tips" @click="isForget = true">
                {{ $t("account.forgetPwd") }}
              </p>
              <van-button
                class="pcLogin-btn"
                @click="handleLogin"
                block
                :disabled="!username || !password"
                :loading="loginLoading"
              >
                {{ $t("account.loginBtn") }}
              </van-button>
              <p class="pcLogin-tips">
                {{ $t("account.noAccount") }}
                <b @click="active = 1">{{ $t("account.regNow") }}</b>
              </p>
              <van-divider class="pcLogin-split">
                {{ $t("account.orpc") }}
              </van-divider>
              <div id="pcGLogin" class="login-custom"></div>
            </van-tab>
            <van-tab title="Register">
              <!-- 注册 -->
              <van-field
                v-model="reg.username"
                :placeholder="$t('account.namePlace')"
                :border="false"
                maxlength="12"
              />
              <p class="pcLogin-validate">{{ $t("account.nameValidate") }}</p>
              <van-field
                v-model="reg.email"
                :placeholder="$t('account.emailPlace')"
                :border="false"
              />
              <van-field
                v-model="reg.password"
                :placeholder="$t('account.pwdPlace')"
                :border="false"
              />
              <p class="pcLogin-validate">{{ $t("account.pwdValidate") }}</p>
              <van-field
                v-model="reg.confirmPassword"
                :placeholder="$t('account.confirmPwdPlace')"
                :border="false"
              />
              <van-field
                v-model="reg.invitedCode"
                :placeholder="$t('account.codePlace')"
                :border="false"
              />
              <van-checkbox
                v-model="checked"
                shape="square"
                checked-color="#159046"
                class="pcLogin-check"
                icon-size="15"
              >
                {{ $t("account.protocalAccept") }}
                <span> {{ $t("account.protocal") }} </span>
              </van-checkbox>
              <van-button
                class="pcLogin-btn pcLogin-s-m-t"
                block
                :disabled="
                  !reg.username || !reg.password || !reg.confirmPassword
                "
                :loading="regLoading"
                @click="handleRegister"
                >{{ $t("account.regBtn") }}
              </van-button>
              <div class="pcLogin-tips" style="margin-top: 0">
                {{ $t("account.hasAccount") }}
                <b @click="active = 0">{{ $t("account.login") }}</b>
              </div>
            </van-tab>
          </van-tabs>
          <div v-show="isForget">
            <p class="pcLogin-resetTitle">
              <van-icon name="arrow-left" @click="isForget = false" size="22" />
              {{ $t("account.resetPwd") }}
            </p>
            <van-field
              v-model="find.email"
              :placeholder="$t('account.emailPlace')"
              :border="false"
            />
            <div class="pcLogin-oneLine">
              <van-field
                v-model="find.verifyCode"
                :placeholder="$t('account.smsCodePlace')"
                :border="false"
                style="flex: 1"
              />
              <van-button
                class="pcLogin-sendBtn"
                @click="handleSend"
                :disabled="!find.email"
                :loading="sendLoading"
              >
                {{ btnText }}
              </van-button>
            </div>
            <van-field
              v-model="find.resetPassword"
              :placeholder="$t('account.pwdPlace')"
              :border="false"
            />
            <p class="pcLogin-validate">At least 6 characters</p>
            <van-field
              v-model="find.confirmResetPassword"
              :placeholder="$t('account.confirmPwdPlace')"
              :border="false"
            />
            <van-button
              class="pcLogin-btn"
              @click="handleReset"
              block
              :disabled="
                !find.email ||
                !find.resetPassword ||
                !find.verifyCode ||
                !find.confirmResetPassword
              "
              :loading="resetLoading"
            >
              {{ $t("account.loginBtn") }}
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Popup, Tab, Tabs, Field, Divider, Checkbox } from "vant";
import logAReg from "@/mixins/LogAReg.vue";
import googleLogin from "@/mixins/googleLogin.vue";
export default {
  name: "pcLogin",
  mixins: [logAReg, googleLogin],
  components: {
    [Popup.name]: Popup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Divider.name]: Divider,
    [Checkbox.name]: Checkbox,
  },
  props: {},
  data() {
    return {
      active: 0,
      isForget: false,
      btnText: "Send",
    };
  },
  watch: {
    showReg(val) {
      if (val) {
        this.active = 1;
      } else {
        this.active = 0;
      }
    },
    active: {
      handler(val) {
        if (val == 0 && !document.getElementById("pcGLogin")) {
          this.rejectBtn();
        }
      },
      immediate: true,
    },
    openLogin: {
      handler(val) {
        if (val && !document.getElementById("pcGLogin")) {
          this.rejectBtn();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["openLogin", "showReg"]),
  },
  methods: {
    ...mapActions("user", ["setToken", "setOpen", "getUserInfo"]),
    handleClose() {
      this.resetValue();
      this.setOpen(false);
    },
    rejectBtn() {
      setTimeout(() => {
        if (window.google) {
          window.google.accounts.id.renderButton(
            document.getElementById("pcGLogin"),
            {
              type: "icon",
              size: "large",
              locale: "en-GB",
              text: "signin",
              shape: "circle",
              theme: "outline",
            } // customization attributes
          );
        }
      }, 1500);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss">
.login-custom {
  width: fit-content;
  margin: 10px auto;
}
.pcLogin {
  &-resetTitle {
    height: 62px;
    line-height: 62px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    @include flexRow;
  }

  &-oneLine {
    @include flexRowBetween;
  }
  &-sendBtn {
    height: 62px;
    width: 158px;
    border-radius: 8px;
    background: #159046;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border: none;
    margin-top: 20px;
    margin-left: 12px;
  }
  b {
    color: #17cc5f;
    cursor: pointer;
  }
  .van-tab--active {
    background: linear-gradient(
      180deg,
      rgba(23, 204, 95, 0) 16%,
      rgba(23, 204, 95, 0.2) 100%
    );
  }
  .van-cell {
    background: #0d0f1a;
    border-radius: 10px;
    margin-top: 30px;
    font-size: 20px;
    padding: 10px 15px;
  }
  .van-tab {
    font-size: 20px;
    font-weight: bold;
  }
  .van-tabs--line .van-tabs__wrap {
    height: 64px;
  }
  .van-checkbox__label {
    color: #fff;
    line-height: normal;
  }
  &-split {
    font-size: 18px;
    margin-top: 100px;
    margin-bottom: 20px;
  }
  &-tips {
    padding: 12px 0;
    color: #fff;
    font-size: 18px;
    text-align: right;
    cursor: pointer;
    &:last-of-type {
      text-align: center;
      margin-top: 20px;
    }
  }
  &-check {
    margin-top: 20px;
    font-size: 18px;
  }
  &-validate {
    font-size: 16px;
    height: 0;
    color: $text-gray4;
    text-align: right;
    margin-top: 4px;
    // margin: 4px auto;
  }
  &-btn {
    height: 62px;
    background: #159046;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-top: 90px;
  }
  &-wrap {
    @include flexRowCenter;
    align-items: stretch;
    height: 100%;
    position: relative;
    border-radius: 10px;
    &-left {
      @include flexColumn;
      // justify-content: center;
      background: #0d1121;
      overflow: hidden;
      img {
        width: 100%;
      }

      font-size: 20px;
      color: #fff;
      font-weight: bold;
    }

    &-right {
      background: #121625;
      padding: 30px 38px 10px 38px;
      box-sizing: border-box;
      overflow: auto;
    }
  }

  &-aside {
    flex: 1;
    position: relative;
    &-content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 70px;
      text-align: center;
      color: #fff;
      p:first-of-type {
        font-weight: 900;
        font-size: 35px;
      }
      p:last-of-type {
        font-weight: 400;
        opacity: 0.4;
        font-size: 18px;
      }
      img {
        width: 65%;
        display: block;
        margin: 6px auto 20px auto;
      }
    }
  }
}
.pcLogin-s-m-t {
  margin-top: 30px;
}
</style>
