<template>
  <div
    class="game-item"
    :style="{
      width: $px2rem(imgWidth + 'px'),
      'flex-basis': $px2rem(imgWidth + 'px'),
    }"
  >
    <img
      :src="imgUrl"
      :alt="title"
      :style="{
        width: $px2rem(imgWidth + 'px'),
        height: $px2rem(imgWidth + 'px'),
      }"
    />
    <span class="game-item-name" :title="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "GameItem",
  components: {},
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    imgWidth: {
      type: Number,
      default: 208,
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.game-item {
  &:hover {
    @media screen and (min-width: 720px) {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  flex: {
    shrink: 0;
    grow: 0;
  }

  border-radius: 8px;
  overflow: hidden;
  background: $block-main;
  text-align: center;
  color: $text-gray;
  font-size: 29px;

  img {
    width: 100%;
    display: block;
  }

  &-name {
    padding: 12px 8px 10px 8px;
    display: block;
    text-align: center;
    width: calc(100% - 10px);
    @include ellipse1;
    @media screen and (min-width: 720px) {
      font-size: 23px;
    }
  }
}
</style>
