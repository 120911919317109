<template>
  <transition :name="transitionName">
    <div
      v-show="visible"
      :class="['back-to-ceiling', className]"
      @click="backToTop"
    >
      <img src="@icon/top.png" alt="toTop" />
    </div>
  </transition>
</template>

<script>
export default {
  name: "BackToTop",
  props: {
    className: {
      type: String,
      default: "",
    },
    isDivScroll: {
      type: Boolean,
      default: false,
    },
    divScrollHeight: {
      type: Number,
      default: 0,
    },
    visibilityHeight: {
      type: Number,
      default: 450,
    },
    backPosition: {
      type: Number,
      default: 0,
    },
    customStyle: {
      type: Object,
      default: function () {
        return {
          right: "50px",
          bottom: "50px",
          width: "40px",
          height: "40px",
          "border-radius": "4px",
          "line-height": "45px",
          background: "#e7eaf1",
        };
      },
    },
    transitionName: {
      type: String,
      default: "fade",
    },
  },
  data() {
    return {
      visible: false,
      interval: null,
      isMoving: false,
    };
  },
  watch: {
    divScrollHeight(val) {
      if (this.isDivScroll) {
        this.visible = val > this.visibilityHeight;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.visible = window.pageYOffset > this.visibilityHeight;
    },
    backToTop() {
      if (this.isDivScroll) {
        this.$emit("handleCompScroll");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
      return (-c / 2) * (--t * (t - 2) - 1) + b;
    },
  },
};
</script>

<style scoped lang="scss">
.back-to-ceiling {
  position: fixed;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  right: 40px;
  bottom: 340px;
  img {
    width: 75px;
    height: 75px;
  }
}
.gamesBackTo {
  bottom: 200px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.back-to-ceiling .Icon {
  fill: #9aaabf;
  background: none;
}
</style>
