<template>
  <div class="pcOperate">
    <van-popup
      :value="openOperate"
      :close-on-click-overlay="false"
      :style="{
        width: '414px',
        height: '90%',
        background: '#121625',
        overflow: 'auto',
        borderRadius: '8px',
      }"
    >
      <van-icon
        name="cross"
        color="#71737C"
        size="20"
        class="pop-close"
        @click="handleClose"
      />
      <p class="pcOperate-title">
        <van-icon
          name="arrow-left"
          color="#fff"
          size="20"
          class="pcOperate-back"
          @click="handleBack"
          v-show="operate == 'withdraw2'"
        />{{
          operate == "contact"
            ? $t("account.contactus")
            : operate == "recharge"
            ? $t("wallet.deposit")
            : $t("wallet.withdraw")
        }}
      </p>
      <!-- <recharge-view /> -->
      <!-- <withdraw-view /> -->
      <component
        :is="
          operate == 'contact'
            ? 'contactView'
            : operate == 'recharge'
            ? 'RechargeView'
            : operate == 'withdraw2'
            ? 'withdrawForm'
            : 'WithdrawView'
        "
        @typeAmount="typeAmount"
        :amountW="amount"
      />
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
import RechargeView from "./rechargeView.vue";
import WithdrawView from "./withdrawView.vue";
import withdrawForm from "./withdrawForm.vue";
import contactView from "@views/account/contactView.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "pcOperate",
  components: {
    RechargeView,
    WithdrawView,
    withdrawForm,
    contactView,
    [Popup.name]: Popup,
  },
  props: {},
  data() {
    return {
      amount: 0,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["openOperate", "operate"]),
  },
  methods: {
    ...mapMutations("user", ["SET_OpenOearate", "SET_TYPE"]),
    handleClose() {
      this.SET_OpenOearate(false);
    },
    typeAmount(amount) {
      this.amount = Number(amount);
    },
    handleBack() {
      this.SET_TYPE("withdraw");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.pcOperate {
  &-title {
    font-size: 30px;
    height: 80px;
    line-height: 80px;
    align-items: end;
    color: #fff;
    text-align: center;
    font-weight: bold;
    position: relative;
    // border-radius: 10px;
  }
  &-back {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
  }
  ::v-deep .recharge {
    .van-notice-bar {
      font-size: 24px;
    }
    height: calc(100% - 80px);
    @include scrollBar;
    background: #121625;
    .account-field {
      font-size: 30px;
      height: 80px;
    }
    .account-field-label {
      font-size: 32px;
    }
    .page-bottom .hasColor {
      font-size: 24px;
      height: 74px;
      border-radius: 10px;
    }
    .recharge-choose {
      gap: 16px 8px;
      &-item {
        width: calc((100% - 24px) / 3);
        height: 64px;
        font-size: 20px;
        align-items: flex-end;
        justify-content: flex-start;
        color: #fff;
        padding-bottom: 5px;
        padding-left: 15px;
        border-radius: 8px;
        overflow: hidden;
      }
    }
    .right-top-fixed {
      border-radius: 0 0 0 8px;
    }
    .recharge-block {
      margin-bottom: 10px;
      padding-top: 0;
    }
    .recharge-block-mainTitle {
      font-size: 24px;
      font-weight: 500;
    }
    .recharge-desc-short {
      font-size: 20px;
      margin: 12px 0;
    }
    .radio-item {
      font-size: 20px;
      padding: 30px 0;
    }
    .radio-item img {
      height: 35px;
    }
    .page-bottom {
      height: 116px;
    }
  }
  ::v-deep .withdraw {
    .van-notice-bar {
      font-size: 24px;
    }
    .hasColor {
      font-size: 26px;
      height: 84px;
      border-radius: 10px;
    }
    .withdraw-block-item {
      font-size: 24px;
      padding: {
        top: 24px;
        bottom: 24px;
      }
    }
    .account-field {
      font-size: 30px;
      height: 80px;
    }
    .account-field-label {
      font-size: 32px;
    }
    .wallet-level {
      height: 62px;
      &-left {
        font-size: 30px;
        img {
          width: 44px;
          height: 44px;
        }
      }
    }
  }
  ::v-deep .withdrawf {
    height: calc(100% - 80px);
    .withdrawf-title {
      font-size: 24px;
    }
    .account-field {
      font-size: 24px;
      height: 80px;
      line-height: 80px;
    }
    &-optionList {
      top: 75px;
      p {
        height: 80px;
        font-size: 24px;
        padding-left: 30px;
      }
    }

    .van-dropdown-menu__bar {
      height: 80px;
      line-height: 80px;
    }
    .hasColor {
      height: 84px;
      font-size: 26px;
    }
  }
  ::v-deep .contact {
    height: calc(100% - 80px);
    .contactTel {
      font-size: 20px;
      &-right {
        font-size: 20px;
      }
      &-desc {
        font-size: 20px;
      }
      padding: {
        left: 10px;
        right: 10px;
      }
    }
    &-ques {
      font-size: 24px;
    }
    &-answer {
      font-size: 24px;
    }
    &-time {
      font-size: 22px;
    }
    .inputTitle {
      font-size: 24px;
      padding: 20px;
    }
    &-send {
      padding: 32px;
      font-size: 28px;
    }
    &-btn {
      height: 78px;
      border-radius: 8px;
      margin-top: 30px;

      font: {
        weight: 500;
        size: 28px;
      }
    }
  }
}
</style>
