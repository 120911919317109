<template>
  <div class="pageNav">
    <div class="mainContent-nav van-ellipsis" v-show="device == 'desktop'">
      <van-icon name="arrow-left" size="22" @click="goBack('back')" />

      <span @click="goPage('home')">{{ this.$t("app.home") }}</span>
      <!-- <van-icon name="arrow" size="22" /> -->>
      <span v-if="level2" @click="goPage('other')">{{ level2 }}</span>
      <template v-if="level3">
        <!-- <van-icon name="arrow-left" size="22" /> -->
        >
      </template>
      <span v-if="level3"> {{ level3 }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PageNav",
  components: {},
  props: {
    level2: {
      type: String,
      default: "",
    },
    level2Path: {
      type: String,
      default: "",
    },
    level3: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters(["device"]),
  },
  methods: {
    goPage(type) {
      if (type == "home") {
        this.$router.replace("/");
      } else {
        if (this.level2Path) {
          if (this.level2Path == "back") {
            this.$router.go(-1);
          } else {
            this.$router.push(this.level2Path);
          }
        }
      }
    },
    goBack() {
      if (!this.level2Path) {
        this.$router.replace("/");
      } else {
        this.$router.go(-1);
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.mainContent-nav {
  color: #fff;
  font-size: 30px;
  padding: 20px;
  max-width: 600px;
  @include flexRow;
  span {
    cursor: pointer;
    margin: 0 8px;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}
</style>
