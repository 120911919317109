<!-- eslint-disable prettier/prettier -->
<template>
  <div class="header" v-show="device=='mobile'||needShow">
    <div class="header-left">
      <!-- 首页Logo @click="goPage('home')"-->
      <!-- <a :href="link" target="_blank">
        <img src="@icon/logo.png" alt="Brando88" v-show="isHome" class="homeLogo" />
      </a> @click="goPage('home')" @click="dwnPrompt"-->
      <img src="@icon/indialogo.png" alt="Brando88" v-show="isHome" class="homeLogo" @click="goPage('home')" />

      <!-- 返回按钮 -->
      <div class="back" v-show="hasHistory && !isTabPage && device!='desktop'" @click="goBack">
        <img src="@icon/back.png" alt="back" class="back" />
      </div>
      <!-- 返回主页 -->
      <img src="@icon/toHome.png" class="toHome" alt=""
        v-show="hasHistory && !isTabPage && !isHome && device != 'desktop'" @click="goPage('home')" />
      <!-- 游戏标题 -->
      <span v-show="isGamePage" class="header-left-title">{{ pageTitle }}</span>
    </div>
    <div class="header-center" v-show="pageTitle && !isHome &&!isGamePage">{{ pageTitle }}</div>
    <div class="header-right">
      <!-- PC--START -->
      <div class="header-lr">
        <!-- 未登录 -->
        <template v-if="!token&&isHome">
          <div class="header-lr-login" @click="handleLogin(1)">{{ $t("account.loginBtn") }}</div>
          <div class="header-lr-reg" @click="handleLogin(2)">{{ $t("account.regBtn") }}</div>
        </template>
        <!-- 已登录 -->
        <template v-if="userInfo && isHome">
          <!-- @click="goPage('profile')" -->
          <!-- <van-popover class="customerPop" v-model="showPopover2" placement="bottom-start" theme="dark" trigger="click"
            :actions="actions2" @select="handleSelect">
            <template #reference>
              <div class="header-lr-user">
                <img :src="userInfo.avatar" alt="">{{ userInfo.username }}
              </div>
            </template>
          </van-popover> -->
          <div class="header-lr-user">
            <img :src="userInfo.avatar" alt="" class="header-lr-user-avatar">{{ userInfo.username | filterName }}
            <div class="header-select">
              <p class="header-select-item" v-for="(item, index) in actions2" :key="index" @click="handleSelect(index)">
                <img :src="item.icon" alt="">{{ item.text }}
              </p>
            </div>
          </div>

        </template>
      </div>
      <!-- PC--END -->
      <!-- <img src="@icon/news.png" alt="news" class="news"> -->
      <!-- 首页语言切换 -->
      <!-- <template v-if="isHome">
        <van-popover class="customerPop" v-model="showPopover" :placement="device == 'desktop'?'bottom':'bottom-end'"
          theme="dark" trigger="click" :actions="actions" :offset="[0,10]" @select="onSelect">
          <template #reference>
            <div class="lang-choose">
              <img src="@icon/lang.png" alt="language" class="lang">
              <span v-show="device=='desktop'">{{ language == 'en' ? 'English' : 'Indonesia' }}</span>
            </div>
          </template>
        </van-popover>
      </template> -->
      <!-- 个人中心保存按钮 unSave-->
      <div class="userSave " v-show="isUserProfile" @click="onSave">{{ $t("account.save") }}</div>
      <!-- 游戏按钮 -->
      <div class="gameBtn" v-show="isGamePage">
        <template v-if="device=='mobile'">
          <game-collect @handleCollect="handleCollect" :isCollect="isCollect" :gameId="gameId" />
          <div class="btn" @click="$toLink('Brand888://gateway->transIn')">Deposit</div>
        </template>
        <template v-if="device == 'desktop'">
          <img :src="isFullscreen ? require('@icon/exit.png') : require('@icon/fullscreen.png')" alt=""
            @click="handleScreen">
        </template>
      </div>
    </div>
    <van-popup v-model="showExit" :style="{ background:'#17181B'}">
      <div class="exitPop">
        <van-icon name="cross" color="#747476" @click="showExit=false" size="24" class="exitPop-close" />
        <p class="exitPop-title">{{ $t('account.signOut') }}</p>
        <p class="exitPop-desc">{{ $t('account.confirmSignOut') }}</p>
        <div class="exitPop-btns">
          <div class="exitPop-btns-btn" style="background:#2E3035" @click="showExit=false">{{ $t('account.cancel') }}
          </div>
          <div class="exitPop-btns-btn" @click="handleSignOut">{{ $t('account.signOut') }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showChange" :style="{ background: '#17181B' }">
      <div class="exitPop changePop">
        <van-icon name="cross" color="#747476" @click="showChange = false" size="24" class="exitPop-close" />
        <change-pwd @changeSuc="showChange = false" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popover, Popup } from "vant";
import { mapGetters, mapActions, mapMutations } from "vuex";
import changePwd from "@/views/account/changePwd.vue";
export default {
  components: {
    changePwd,
    [Popover.name]: Popover,
    [Popup.name]: Popup,
  },
  name: "PageHeader",
  props: {
    pageTitle: {
      type: String,
      default: "Home",
    },
    isHome: {
      type: Boolean,
      default: true,
    },
    isTabPage: {
      type: Boolean,
      default: true,
    },
    isGamePage: {
      type: Boolean,
      default: false,
    },
    isUserProfile: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "#1c1f2e",
    },
    isCollect: {
      type: Boolean,
      default: false,
    },
    gameId: {
      type: String,
      default: "",
    },
    needShow: {
      type: Boolean,
      default: false,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    filterName(name) {
      let spliceName = "";
      if (name) {
        if (name.length > 1) {
          spliceName =
            name.substring(0, 1) + "***" + name.substring(name.length - 1);
        } else {
          spliceName = name + "***" + name;
        }
      }
      return spliceName;
    },
  },
  data() {
    return {
      eventRef: null,
      showChange: false,
      showExit: false,
      hasHistory: true,
      showPopover: false,
      showPopover2: false,
      actions: [{ text: "English" }, { text: "Indonesia" }],
      actions2: [
        {
          text: "Profile",
          icon: require("@icon/profile.png"),
          className: "customPop",
        },
        {
          text: "Wallet",
          icon: require("@icon/wallet-w.png"),
          className: "customPop",
        },
        {
          text: "Password",
          icon: require("@icon/password.png"),
          className: "customPop",
        },
        {
          text: "Sign out",
          icon: require("@icon/sign-out.png"),
          className: "customPop",
        },
      ],
    };
  },
  mounted() {
    // window.addEventListener("beforeinstallprompt", (e) => {
    //   e.preventDefault(); // Prevents prompt display
    //   this.eventRef = e;
    // });
  },
  computed: {
    ...mapGetters(["token", "userInfo", "device", "language"]),
  },
  methods: {
    ...mapActions("user", ["setOpen"]),
    ...mapMutations("app", ["TOGGLE_LANGUAGE"]),
    ...mapMutations("user", ["logOut"]),
    dwnPrompt() {
      this.eventRef.prompt();
    },
    handleLogin(type) {
      this.setOpen(type == 2 ? "2" : true);
    },
    handleSignOut() {
      this.logOut();
      this.showExit = false;
      this.$toast({
        message: this.$t("account.success"),
      });
    },
    goBack() {
      this.$router.go(-1);
      // this.$router.replace("/");
    },
    onSave() {
      this.$emit("save");
    },
    handleCollect() {
      this.$emit("modifyStatus");
    },
    goPage(type) {
      if (type == "home") {
        this.$router.replace("/");
      }
      if (type == "profile") {
        this.$router.push("/profile");
      }
    },
    handleScreen() {
      this.$emit("toggle");
    },
    onSelect(actions) {
      let lang = actions.text == "English" ? "en" : "india";
      if (this.language != lang) {
        this.TOGGLE_LANGUAGE(lang);
        this.$i18n.locale = lang;
        if (this.device == "desktop") {
          window.history.replaceState(null, "", document.url);
          window.location.replace("/");
        }
      }
    },
    handleSelect(index) {
      if (index == 0) {
        this.$router.push("/profile");
      }
      if (index == 1) {
        this.$router.push("/wallet");
      }
      if (index == 2) {
        this.showChange = true;
      }
      if (index == 3) {
        this.showExit = true;
      }
    },
  },
};
</script>
<style lang="scss">
.van-popover[data-popper-placement="bottom-end"] .van-popover__arrow {
  right: 3.9% !important;
}
.van-hairline--bottom {
  font-size: 26px;
}
.customPop {
  font-size: 26px;
}
.customPop .van-icon {
  font-size: 24px;
}
</style>
<style lang="scss" scoped>
.header {
  ::v-deep .change {
    .account-field {
      font-size: 28px;
      // margin-top: 30px;
      height: 80px;
      // ;
    }

    .tips {
      font-size: 22px;
    }
    .hasColor {
      height: 88px;
      line-height: 88px;
    }
  }
  .exitPop {
    position: relative;
    padding: 50px 42px;
    width: 610px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 26px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      &:hover {
        transform: rotate(-90deg);
        transition: all 0.5s;
      }
    }
    &-desc {
      color: $text-gray4;
      padding: 34px 0;
      font-size: 22px;
    }
    &-btns {
      @include flexRowBetween;
      gap: 0 30px;
      cursor: pointer;
      margin-top: 20px;
      &-btn {
        flex: 1;
        background: #159046;
        border-radius: 5px;
        height: 70px;
        line-height: 70px;
      }
    }
  }
  .changePop {
    width: 700px;
  }
  &-lr {
    display: none;
    @media screen and (min-width: 720px) {
      @include flexRow;
    }

    .header-lr-login {
      // width: 150px;
      height: 60px;
      padding: 0 30px;
      @include flexRowCenter;
      border-radius: 8px;
      color: #17cc5f;
      font-size: 24px;
      font-weight: 900;
      margin-right: 30px;
      cursor: pointer;
    }
    .header-lr-reg {
      @extend .header-lr-login;
      background: #17cc5f;
      color: #fff;
      &:hover {
        // box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
        // -webkit-transform: scale(1.1);
        // transform: scale(1.1);
        opacity: 0.9;
      }
    }
    .header-lr-user {
      @include flexRow;
      margin-right: 40px;
      position: relative;
      font-size: 28px;
      height: 100px;
      width: 230px;
      padding: 0 30px;
      box-sizing: border-box;
      font-weight: 900;
      cursor: pointer;
      color: #fff;
      &-avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 15px;
      }
      &:hover {
        background: #2b2f45;
        transition: all 0.5s;
        .header-select {
          transition: all 0.5s;
          opacity: 1;
          height: 288px;
        }
      }
      .header-select {
        opacity: 0.5;
        height: 0;
        overflow: hidden;
        background: #2b2f45;
        &-item {
          height: 72px;
          padding: 0 30px;
          @include flexRow;
          color: $text-gray7;
          font-size: 24px;
          font-weight: normal;
          img {
            width: 32px;
            height: 32px;
            margin-right: 20px;
          }
          &:hover {
            opacity: 0.7;
          }
        }
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        z-index: 2;
      }
    }
  }
  background: #1c1f2e;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
  /* prettier-ignore */
  padding: 15px 20px;
  height: 100px;
  @include flexRowBetween;
  box-sizing: border-box;
  img {
    display: block;
  }

  &-left {
    max-width: 50vw;
    @include flexRow;
    &-title {
      flex: 1;
      @include ellipse1;
      color: #fff;
      font-size: 32px;
      font-weight: 500;
      margin-left: 30px;
    }
    .homeLogo {
      cursor: pointer;
      // height: fit-content;
      width: 267px;
    }
    .back {
      width: 34px;
      height: 34px;
      @include expand-click-area;
    }
    .toHome {
      width: 38px;
      height: 38px;
      @include expand-click-area;
      margin-left: 40px;
    }
  }

  &-center {
    // flex: 1;
    color: #fff;
    font-size: 38px;
    font-weight: 500;
    text-align: center;
    max-width: 50vw;
    @include ellipse1;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  &-right {
    @include flexRow;
    .lang {
      width: 48px;
      height: 48px;
    }
    .lang-choose {
      font-size: 24px;
      color: #fff;
      font-weight: 500;
      @include flexRowCenter;
      cursor: pointer;
      span {
        padding: 0 30px 0 10px;
      }
    }
    .news {
      @extend .lang;
      margin-right: 22px;
    }
    .userSave {
      color: $text-green;
      font-size: 32px;
    }
    .unSave {
      color: $text-gray;
    }
    .gameBtn {
      @include flexRowCenter;
      & > img {
        width: 25px;
        height: 25px;
      }
    }
    .btn {
      width: 188px;
      height: 74px;
      @include btnStyle;
      border-radius: 8px;
      line-height: 74px;
      margin-left: 42px;
      font-size: 34px;
      font-weight: bold;
    }
  }
}
</style>
