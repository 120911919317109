import request from "@/service/request";

export function accountPage() {
  return request({
    url: "/version/config/profile",
    method: "get",
  });
}

export function apiLogin(data) {
  return request({
    url: "/auth/login",
    method: "post",
    data,
  });
}

export function otherLogin(data) {
  return request({
    url: "/auth/thirdParty/login",
    method: "post",
    data,
  });
}

export function apiRegist(data) {
  return request({
    url: "/auth/register",
    method: "post",
    data,
  });
}

// 忘记密码发送验证码
export function apiSendCode(data) {
  return request({
    url: "/auth/forget-password",
    method: "post",
    data,
  });
}
// 重设密码_忘记
export function apiResetPwd(data) {
  return request({
    url: "/auth/reset-password-by-token",
    method: "post",
    data,
  });
}

// 修改密码
export function apiModifyPwd(data) {
  return request({
    url: "/user/reset-password",
    method: "post",
    data,
  });
}

export function guestLogin(data) {
  return request({
    url: "/auth/guest",
    method: "post",
    data,
  });
}

export function apiUserInfo(params) {
  return request({
    url: "/user/detail",
    method: "get",
    params,
  });
}

export function apiUserBalance(data) {
  return request({
    url: "/user/balance",
    method: "post",
    data,
  });
}

export function refreshBalance(data) {
  return request({
    url: "/user/balance/refresh",
    method: "post",
    data,
  });
}

export function modifyUserInfo(data) {
  return request({
    url: "/user/modify-info",
    method: "post",
    data,
  });
}

export function userBalance(data) {
  return request({
    url: "/user/balance",
    method: "post",
    data,
  });
}

export function levelPage(params) {
  return request({
    url: "/user/level-info",
    method: "get",
    params,
  });
}

// 上传图片
export function apiUpload(data) {
  return request({
    url: "/api/tool/upload",
    method: "post",
    data,
  });
}

export function apiQes(data) {
  return request({
    url: "/service/question",
    method: "post",
    data,
  });
}

export function quesList(data) {
  return request({
    url: "/service/list",
    method: "post",
    data,
  });
}

export function getDownLoad(link, params) {
  return request({
    url: `/invite/app/${link}`,
    method: "get",
    params,
  });
}
