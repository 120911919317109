<template>
  <div class="change">
    <page-header
      :isHome="false"
      :isTabPage="false"
      :pageTitle="$t('account.changepwd')"
    />

    <van-field
      v-model="rawPassword"
      :placeholder="$t('account.originalpassword')"
      :border="false"
      class="blockWidth account-field"
    />
    <van-field
      v-model="newPassword"
      :placeholder="$t('account.newpassword')"
      :border="false"
      class="blockWidth account-field"
      maxlength="32"
    />
    <p class="blockWidth tips">{{ $t("account.newpassword1") }}</p>
    <van-field
      v-model="confirmNewPassword"
      :placeholder="$t('account.confirmpassword')"
      :border="false"
      class="blockWidth account-field"
    />

    <van-button
      class="blockWidth hasColor"
      @click="handleReset"
      block
      :disabled="!newPassword || !rawPassword || !confirmNewPassword"
      :loading="loading"
    >
      {{ $t("wallet.ok") }}
    </van-button>
  </div>
</template>

<script>
import { Field } from "vant";
import { apiModifyPwd } from "@api/account";
import { mapGetters } from "vuex";
export default {
  name: "changePwd",
  components: {
    [Field.name]: Field,
  },
  props: {},
  data() {
    return {
      loading: false,
      rawPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["device"]),
  },
  methods: {
    handleReset() {
      if (this.newPassword.length < 8 || this.newPassword.length > 32) {
        return this.$toast(this.$t("account.newpassword1"));
      }
      if (this.newPassword != this.confirmNewPassword) {
        return this.$toast(this.$t("account.pwdNotComplete"));
      }
      this.loading = true;
      apiModifyPwd({
        rawPassword: this.rawPassword,
        newPassword: this.newPassword,
        confirmNewPassword: this.confirmNewPassword,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.loading = false;
              },
            });
            if (this.device == "desktop") {
              this.$emit("changeSuc");
            } else {
              this.$router.go(-1);
            }
          } else {
            this.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.hasColor {
  background: $btn-green;
  color: #fff;
  border: none;
  font-weight: 500;
  font-size: 34px;
  border-radius: 16px;
  height: 104px;
  margin-top: 100px;
}
.account-field {
  margin-top: 60px;
  background: #1c1f2e;
  font-size: 32px;
  font-weight: normal;
}
.tips {
  color: $text-gray;
  font-size: 26px;
  padding: 5px 0 0 0;
  height: 0;
  text-align: right;
}
</style>
