<template>
  <div class="home">
    <!-- 头 -->
    <page-header
      :isHome="true"
      :isTabPage="true"
      pageTitle="Home"
      v-show="device == 'desktop'"
      :needShow="true"
    />
    <div class="home-wrap">
      <!-- 左侧 -->
      <div class="home-wrap-left">
        <PageAside :device="device" />
      </div>
      <div class="home-wrap-right">
        <div class="home-wrap-right-center">
          <div class="home-wrap-right-center-content">
            <van-skeleton :row="6" :loading="pageLoading">
              <keep-alive
                include="promosiView,inviteView,HomeView,withdrawView"
              >
                <!-- 需要缓存的视图组件 -->
                <router-view />
              </keep-alive>
            </van-skeleton>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="home-wrap-right-right">
          <home-right v-show="showRight" />
        </div>
      </div>
    </div>
    <!-- login -->
    <div
      class="blockWidth login-notify"
      v-show="!token && device == 'mobile' && showLogin"
    >
      <p>
        <img src="@icon/pointer.png" alt="login" />
        {{ $t("account.goLogin") }}
      </p>
      <div class="login-notify-btn" @click="$openl">
        {{ $t("account.loginBtn") }}
      </div>
    </div>
    <!-- <back-to-top /> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Skeleton } from "vant";
import PageAside from "./aside.vue";
import HomeRight from "@views/home/HomeRight.vue";
import { refreshBalance } from "@api/account.js";

export default {
  name: "layoutIndex",
  components: {
    HomeRight,
    PageAside,
    [Skeleton.name]: Skeleton,
  },
  data() {
    return {
      current: 0,
      homeData: [],
      tList: [],
      pageLoading: false,
      showRight: false,
      showLogin: true,
    };
  },
  watch: {
    token(newVal) {
      if (newVal) {
        // this.onGetHomeCard();
      }
    },
    $route: {
      handler(route, beforeRoute) {
        if (beforeRoute && beforeRoute.name == "gamesPlay") {
          refreshBalance().then((res) => {
            if (res.code == 200) {
              this.SET_USERBALANCE(res.data);
            }
          });
        }
        if (route.path == "/") {
          this.showRight = true;
        } else {
          this.showRight = false;
        }
        if (route.path == "/download") {
          this.showLogin = false;
        } else {
          this.showLogin = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["device", "token", "userInfo"]),
  },
  created() {},
  mounted() {},
  methods: {
    ...mapMutations("user", ["SET_USERBALANCE"]),
  },
};
</script>

<style lang="scss" scoped>
.home {
  // @include scrollBar;
  // padding-bottom: 80px;

  &-wrap {
    @include flexRow;
    width: 100vw;
    align-items: flex-start;

    &-left {
      display: none;
      width: 325px;
      min-height: 100vh;
      padding: 30px 15px;
      box-sizing: border-box;
      background: #121625;
      box-shadow: 15px 0 12px 0px rgba(0, 0, 0, 0.25);

      @media screen and (min-width: 720px) {
        @include flexColumn;
      }
    }

    &-right {
      flex: 1;

      @media screen and (max-width: 720px) {
        width: 100vw;
      }

      @media screen and (min-width: 720px) {
        width: calc(100vw - 325px);
        padding: 25px 25px 0 0;
        box-sizing: border-box;
        @include flexRow;
        align-items: flex-start;
      }

      &-center {
        flex: 1;

        &-content {
          @media screen and (max-width: 720px) {
            width: 100%;
          }

          @media screen and (min-width: 720px) {
            max-width: 1500px;
            width: calc(100vw - 400px - 325px);
          }
        }

        @media screen and (min-width: 720px) {
          @include flexColumn;
          justify-content: center;
        }
      }

      &-right {
        display: none;
        width: 400px;
        min-height: 100vh;
        @media screen and (min-width: 720px) {
          display: block;
        }
      }
    }
  }
}

.login-notify {
  position: fixed;
  bottom: 140px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;
  z-index: 2;
  background: linear-gradient(91deg, #242737 0%, #33374e 100%);
  box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.67);
  border-radius: 13px;
  @include flexRowBetween;
  font-weight: bold;

  p {
    @include flexRowCenter;
  }

  img {
    width: 54px;
    height: 50px;
    margin-right: 20px;
  }

  color: #fff;
  font-size: 28px;

  &-btn {
    @include btn(150px, 62px, 8px, 28px, bold);
  }
}
</style>
