<script>
import { checkEmpty, validateSpechars, checkEmail } from "@/utils/tools";
import { apiLogin, apiRegist, apiSendCode, apiResetPwd } from "@api/account";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "mixin-log-reg",
  data() {
    return {
      username: "",
      password: "",
      loginLoading: false,
      reg: {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        invitedCode: "",
        fullName: "",
      },
      regLoading: false,
      checked: false,
      find: {
        email: "",
        verifyCode: "",
        verifyToken: "",
        resetPassword: "",
        confirmResetPassword: "",
      },
      resetLoading: false,
      sendLoading: false,
    };
  },
  computed: {
    ...mapGetters(["device"]),
  },
  methods: {
    ...mapActions("user", [
      "setToken",
      "setOpen",
      "getUserInfo",
      "getUserBalance",
    ]),
    resetValue() {
      this.username = "";
      this.password = "";
      this.reg = {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        invitedCode: "",
        fullName: "",
      };
    },
    handleLogin() {
      if (checkEmpty(this.username) || checkEmpty(this.password)) {
        return;
      }
      this.loginLoading = true;
      apiLogin({ username: this.username, password: this.password })
        .then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.loginLoading = false;
                // 注册过来的
                this.regLoading = false;
              },
            });
            if (this.device == "mobile") {
              this.$emit("onlogSuccess", res.data.authToken);
              this.resetValue();
            } else {
              this.setToken(res.data.authToken);
              this.setOpen(false);
              this.resetValue();
              this.getUserInfo();
              this.getUserBalance();
            }
          } else {
            this.loginLoading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loginLoading = false;
        });
    },
    handleRegister() {
      if (validateSpechars(this.reg.username) || this.reg.username.length < 6) {
        return this.$toast(this.$t("account.nameValidate"));
      }

      if (!checkEmail(this.reg.email)) {
        return this.$toast(this.$t("account.wrongEmail"));
      }

      if (this.reg.password.length < 8) {
        return this.$toast(this.$t("account.pwdValidate"));
      }
      if (this.reg.password != this.reg.confirmPassword) {
        return this.$toast(this.$t("account.pwdNotComplete"));
      }
      if (!this.checked) {
        return this.$toast(this.$t("account.checkFirst"));
      }
      this.regLoading = true;
      let postData = { ...this.reg };
      delete postData.confirmPassword;
      apiRegist(postData)
        .then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: res.message,
              onClose: () => {
                // this.regLoading = false;
              },
            });
            this.username = this.reg.username;
            this.password = this.reg.password;
            this.handleLogin();
            // if (this.device == "mobile") {
            //   this.showReg = false;
            // } else {
            //   this.active = 0;
            // }
          } else {
            this.regLoading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.regLoading = false;
        });
    },
    handleSend() {
      if (
        (this.btnText != "Get" &&
          this.btnText != "Send" &&
          this.btnText != "Resend") ||
        this.sendLoading
      )
        return;
      if (!checkEmail(this.find.email)) {
        return this.$toast(this.$("account.wrongEmail"));
      }
      this.sendLoading = true;
      apiSendCode({ email: this.find.email })
        .then((res) => {
          if (res.code == 200) {
            // 倒计时
            this.onCountDown();
            this.find.verifyToken = res.data;
            this.sendLoading = false;
          } else {
            this.sendLoading = false;
          }
        })
        .catch(() => {
          this.sendLoading = false;
        });
    },
    onCountDown() {
      let time = 60;
      let interval = setInterval(() => {
        time--;
        if (time <= 0) {
          this.btnText = "Resend";
          clearInterval(interval);
        } else {
          this.btnText = time + "s";
        }
      }, 1000);
    },
    handleReset() {
      // if (!this.find.verifyToken) {
      //   return this.$toast("请先获取验证码");
      // }
      if (this.find.resetPassword.length < 8) {
        return this.$toast(this.$t("account.pwdValidate"));
      }
      if (this.find.resetPassword != this.find.confirmResetPassword) {
        return this.$toast(this.$t("account.pwdNotComplete"));
      }
      this.resetLoading = true;
      apiResetPwd({ ...this.find })
        .then((res) => {
          if (res.code == 200) {
            this.showFind = false;
            this.resetLoading = false;
          } else {
            this.resetLoading = false;
          }
        })
        .catch(() => {
          this.resetLoading = false;
        });
    },
  },
};
</script>
