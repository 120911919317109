const state = {
  appname: localStorage.getItem("appName") || process.env.VUE_APP_NAME,
  device: "desktop",
  language: "en", //en 和 india
  pageInfo: null,
  gameUrl: "",
};

const mutations = {
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  TOGGLE_LANGUAGE: (state, language) => {
    state.language = language;
  },
  SET_PAGEINFO: (state, pageInfo) => {
    state.pageInfo = pageInfo;
  },
  setUrl: (state, gameUrl) => {
    state.gameUrl = gameUrl;
  },
};

const actions = {
  toggleDevice({ commit }, device) {
    commit("TOGGLE_DEVICE", device);
  },
  toggleLanguage({ commit }, language) {
    commit("TOGGLE_LANGUAGE", language);
  },
  setPageInfo({ commit }, info) {
    commit("SET_PAGEINFO", info);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
