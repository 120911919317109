<template>
  <div class="contact">
    <!-- 标题 -->
    <div class="header-wrap">
      <div class="header-wrap-header">
        <page-header
          :isHome="false"
          :isTabPage="false"
          :pageTitle="$t('account.contactus')"
        />
      </div>
    </div>
    <!-- <page-nav :level2="$t('account.contactus')" /> -->

    <!-- 头部联系 -->
    <div class="contactTel" @click="$toLink(link)">
      <div class="contactTel-left">
        {{ text }}: <b>+62 813-9384-3827</b>
        <p class="contactTel-desc">{{ $t("account.service") }}</p>
      </div>
      <div class="contactTel-right">
        <van-icon name="arrow" color="#A73C00" />
      </div>
    </div>
    <!-- 对话 -->
    <div class="blockWidth contact-body" ref="scrollDiv">
      <div v-for="(item, index) in list" :key="index">
        <p class="contact-time">{{ item.createTime | parseTime }}</p>
        <div class="contact-queswrap">
          <div class="contact-ques" v-html="item.question"></div>
          <img
            class="contact-qimg"
            :src="item.images"
            @click="showImg(item.images)"
          />
        </div>

        <div class="contact-answer" v-if="item.answer">{{ item.answer }}</div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="contact-send-wrap">
      <div class="contact-send" @click="show = true">
        {{ $t("account.write") }}
        <van-icon name="guide-o" size="23" />
      </div>
    </div>

    <!-- 输入 -->
    <van-popup
      v-model="show"
      position="bottom"
      :style="{ height: '62%', background: '#1C1F2E' }"
    >
      <van-icon
        name="cross"
        color="#71737C"
        size="20"
        class="contact-close"
        @click="show = false"
      />
      <p class="inputTitle">{{ $t("account.enterquestion") }}</p>
      <van-field
        v-model="message"
        rows="5"
        label=""
        type="textarea"
        :placeholder="$t('account.write')"
        :border="false"
        class="blockWidth"
        :style="{ background: '#11141E', borderRadius: '4px' }"
      />
      <p class="inputTitle">{{ $t("account.uploadpic") }}</p>
      <van-uploader :after-read="afterRead" v-model="fileList" :max-count="1">
        <div class="-uploader"><van-icon name="plus" color="#fff" /></div>
      </van-uploader>
      <van-button
        class="blockWidth contact-btn"
        type="primary"
        @click="postQuestion"
        :loading="loading"
        block
        :disabled="!message"
      >
        {{ $t("account.submitBtn") }}
      </van-button>
    </van-popup>
  </div>
</template>

<script>
import { serviceConfig } from "@api/home";
import { apiQes, apiUpload, quesList } from "@api/account";
import { Popup, Field, Uploader, ImagePreview } from "vant";
import { mapGetters } from "vuex";
export default {
  name: "contactView",
  components: {
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  props: {},
  data() {
    return {
      text: "",
      show: false,
      message: "",
      link: "",
      img: "",
      list: [],
      loading: false,
      fileList: [],
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["device"]),
  },
  methods: {
    onGetData() {
      serviceConfig().then((res) => {
        this.text = res.data.text;
        this.link = res.data.link;
      });
      quesList({
        // appUserId: "",
        type: 1,
        pageNum: 1,
        pageSize: 100,
      }).then((res) => {
        this.list = res.data.list;
        setTimeout(() => {
          if (this.list.length > 5) {
            window.scrollTo(0, document.body.clientHeight);
            // let quesDiv = this.$refs.scrollDiv;
            // quesDiv.scrollTop = quesDiv.scrollHeight;
          }
        }, 1000);
      });
    },
    showImg(url) {
      ImagePreview([url]);
    },
    postQuestion() {
      this.loading = true;
      apiQes({
        // appUserId: "",
        type: 1,
        question: this.message,
        images: this.img,
      })
        .then((res) => {
          if (res.code == 200) {
            // 请求列表
            this.loading = false;
            this.show = false;
            this.onGetData();
            if (this.fileList.length > 0) {
              this.fileList.splice(0, 1);
            }
            this.message = "";
            // this.list = res.data.list;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    afterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      apiUpload(formData).then((res) => {
        if (res.code == 200) {
          this.img = res.data;
          console.log(this.fileList);
        }
      });
    },
  },
  created() {
    this.onGetData();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.contact {
  @include flexColumn;
  height: 100%;
  width: 100%;
  align-items: stretch;
  &-close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    font-weight: bold;
    cursor: pointer;
  }
  &-time {
    color: #a0a3a7;
    font-size: 24px;
    text-align: center;
    margin-top: 60px;
  }
  &-queswrap {
    @include flexColumn;
    align-items: end;
    // justify-content: flex-end;
  }
  &-ques {
    padding: 20px;
    background: #043630;
    border-radius: 8px;
    color: #fff;
    font-size: 32px;
    margin-top: 40px;
    width: fit-content;
  }
  &-qimg {
    width: 220px;
    display: block;
    margin-top: 40px;
  }
  &-answer {
    @extend .contact-ques;
    background: #333646;
  }
  &-body {
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 100px;
  }
  &-send {
    background: $block-main;
    padding: 42px;
    color: #a0a3a7;
    font-size: 32px;
    @include flexRowBetween;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &-wrap {
      height: 124px;
    }
  }

  &-btn {
    height: 104px;
    border-radius: 8px;
    margin-top: 40px;

    font: {
      weight: 500;
      size: 32px;
    }
  }
}
.inputTitle {
  color: #a0a3a7;
  font-size: 32px;
  padding: 32px;
}
.contactTel {
  cursor: pointer;
  padding: 12px 20px;
  background: #ffc242;
  color: #a73c00;
  font-size: 32px;
  @include flexRowBetween;
  white-space: nowrap;

  &-desc {
    font-size: 20px;
    margin-top: 12px;
  }
  &-right {
    font-weight: 500;
  }
}
.-uploader {
  width: 146px;
  height: 146px;
  border-radius: 8px;
  background: #333646;
  margin-left: 20px;
  @include flexRowCenter;
}
</style>
