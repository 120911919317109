var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pcOperate"},[_c('van-popup',{style:({
      width: '414px',
      height: '90%',
      background: '#121625',
      overflow: 'auto',
      borderRadius: '8px',
    }),attrs:{"value":_vm.openOperate,"close-on-click-overlay":false}},[_c('van-icon',{staticClass:"pop-close",attrs:{"name":"cross","color":"#71737C","size":"20"},on:{"click":_vm.handleClose}}),_c('p',{staticClass:"pcOperate-title"},[_c('van-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.operate == 'withdraw2'),expression:"operate == 'withdraw2'"}],staticClass:"pcOperate-back",attrs:{"name":"arrow-left","color":"#fff","size":"20"},on:{"click":_vm.handleBack}}),_vm._v(_vm._s(_vm.operate == "contact" ? _vm.$t("account.contactus") : _vm.operate == "recharge" ? _vm.$t("wallet.deposit") : _vm.$t("wallet.withdraw"))+" ")],1),_c(_vm.operate == 'contact'
          ? 'contactView'
          : _vm.operate == 'recharge'
          ? 'RechargeView'
          : _vm.operate == 'withdraw2'
          ? 'withdrawForm'
          : 'WithdrawView',{tag:"component",attrs:{"amountW":_vm.amount},on:{"typeAmount":_vm.typeAmount}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }