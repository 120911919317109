<template>
  <div @click="handleCollect">
    <div class="collect-wrap" v-show="!isCollect">
      <img src="@icon/collection.png" alt="uncollect" />
    </div>
    <div class="collect-wrap" v-show="isCollect">
      <img src="@icon/collection1.png" alt="collect" />
    </div>
  </div>
</template>

<script>
import { apiCollect } from "@api/home";
export default {
  name: "GameCollect",
  components: {},
  props: {
    isCollect: {
      type: Boolean,
      default: false,
    },
    gameId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleCollect() {
      if (this.pending) {
        return;
      }
      let postUrl = "";
      if (this.isCollect) {
        // 取消收藏
        postUrl = "/user/delete-collection?gameId=" + this.gameId;
      } else {
        // 收藏
        postUrl = "/user/set-collection?gameId=" + this.gameId;
      }
      this.pending = true;
      apiCollect(
        { gameId: this.gameId },
        postUrl,
        this.isCollect ? "DELETE" : "post"
      )
        .then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.pending = false;
              },
            });
            this.$emit("handleCollect", this.gameId);
          } else {
            this.pending = false;
          }
        })
        .catch(() => {
          this.pending = false;
        });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.collect-wrap {
  @include flexRowCenter;
  img {
    display: block;
    width: 46px;
    height: 46px;
  }
}
</style>
