<template>
  <div>
    <div class="home-nav">
      <div
        class="home-nav-item"
        v-show="!token"
        @click="goPages('Brand888://gateway->wallet', -99)"
      >
        <img src="@icon/btn2.png" alt="" class="item-img" />
        {{ $t("app.wallet") }}
      </div>
      <div class="wallet-item" v-if="token">
        <img src="@icon/wimg.png" class="wallet-item-wimg" />
        <div
          class="wallet-item-title"
          @click="goPages('Brand888://gateway->wallet', -99)"
        >
          <p>
            <img src="@icon/wallet.png" alt="wallet" class="_icon" />
            {{ $t("app.wallet") }}
          </p>
          <van-icon name="arrow" color="#B8B9BE" size="15" />
        </div>
        <div class="wallet-item-amount">{{ balance | toThousandFilter }}</div>
        <div class="wallet-item-dbtn" @click="handleDeposit">
          {{ $t("wallet.deposit") }}
        </div>
      </div>
      <div
        class="home-nav-item"
        @click="goPages('Brand888://gateway->invite', -99)"
      >
        <img src="@icon/btn1.png" alt="" class="item-img" />
        {{ $t("account.invitefri") }}
        <span class="fixTop">{{ $t("account.bonus") }}</span>
      </div>
    </div>
    <div
      class="home-level"
      @click="goPages('Brand888://gateway->vipPage', -99)"
    >
      <img src="@icon/level.png" alt="" />
      <span class="home-game-nav-name"
        ><b>{{ $t("account.level") }}</b></span
      >
      <span class="home-game-nav-num" style="color: #fff" v-if="userInfo">
        <b>Lv.{{ userInfo?.membershipLevel }}</b>
        <van-icon name="arrow" color="#B8B9BE" size="15" />
      </span>
    </div>

    <div
      :class="['home-game-nav', chosed == -1 ? 'click_nav' : '']"
      @click="goGames('All', -1)"
    >
      <img src="@icon/allGames.png" alt="" />
      <span class="home-game-nav-name"
        ><b>{{ $t("account.allGames") }}</b></span
      >
      <!-- <span class="home-game-nav-num">396</span> -->
    </div>
    <div
      :class="['home-game-nav', chosed == index ? 'click_nav' : '']"
      v-for="(i, index) in tList"
      :key="index"
      @click="goGames(i.name, index)"
    >
      <img :src="i.logo" alt="" />
      <span class="home-game-nav-name">{{ i.name }}</span>
      <!-- <span class="home-game-nav-num">{{ i.rank }}</span> -->
    </div>

    <div
      :class="['home-game-nav', 'nav-last', chosed == -2 ? 'click_nav' : '']"
      @click="goPages('faq', -2)"
    >
      <img src="@icon/about.png" alt="" />
      <span class="home-game-nav-name">{{ $t("account.faq") }}</span>
    </div>
  </div>
</template>

<script>
import { typeList } from "@api/home";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "pageAside",
  components: {},
  props: {
    device: {
      type: String,
      default: "mobile",
    },
  },
  data() {
    return {
      tList: [],
      chosed: -99,
    };
  },
  watch: {
    device: {
      handler: function (val) {
        if (val == "desktop") {
          this.onGetTypes();
        }
      },
      immediate: true,
    },
    $route: {
      handler(val) {
        if (val.name == "HomeView") {
          this.chosed = -99;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["token", "userInfo", "balance"]),
  },
  methods: {
    ...mapMutations("user", ["SET_OpenOearate", "SET_TYPE"]),
    onGetTypes() {
      typeList().then((res) => {
        this.tList = res.data;
      });
    },
    goGames(type, index) {
      this.chosed = index;
      this.$router.push("/games?classify=" + type);
    },
    handleDeposit() {
      this.chosed = -99;
      this.SET_OpenOearate(true);
      this.SET_TYPE("recharge");
    },
    goPages(type, index) {
      this.chosed = index;
      if (type == "faq") {
        this.$router.push("/faq");
      } else {
        this.$toLink(type);
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.wallet-item-wimg {
  position: absolute;
  right: 12px;
  bottom: 4px;
  width: 108px;
  height: 70px;
}
.wallet-item {
  width: 276px;
  height: 180px;
  padding: 12px;
  box-sizing: border-box;
  background: linear-gradient(90deg, #1a2cfc 0%, #7b01ec 100%);
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 15px;
  position: relative;

  &-dbtn {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    background: linear-gradient(90deg, #159046 0%, #23e65a 100%);
    border-radius: 5px 5px 5px 5px;
    min-width: 124px;
    width: fit-content;
    padding: 0 6px;
    height: 42px;
    @include flexRowCenter;
    margin-top: 15px;
    cursor: pointer;
  }
  &-title {
    @include flexRow;
    justify-content: space-between;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    width: 100%;
    ._icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    & > p {
      @include flexRowCenter;
    }
  }
  &-amount {
    background: rgba(19, 22, 35, 0.4);
    border-radius: 8px 8px 8px 8px;
    padding: 0 10px;
    font-size: 24px;
    font-weight: bold;
    color: #00ff29;
    text-align: left;
    height: 50px;
    margin-top: 10px;
    @include flexRowCenter;
  }
}
.home-game-nav {
  width: 300px;
  border-radius: 6px;
  color: #fff;
  font-size: 24px;
  padding: 15px;
  box-sizing: border-box;
  // font-weight: 900;
  background: #1f2232;
  cursor: pointer;
  @include flexRowBetween;
  margin-top: 8px;
  text-align: left;
  &:hover {
    background: #159046;
  }
  img {
    width: 34px;
    height: 34px;
    margin-right: 15px;
  }

  &-name {
    flex: 1;
  }

  &-num {
    color: $text-gray4;
    font-weight: normal;
  }
}
.click_nav {
  background: #159046;
}
.nav-last {
  margin-top: 25px;
}

.home-level {
  @extend .home-game-nav;
  background: #32364e;
  margin: 25px auto;
}

.home-nav {
  padding: 12px 12px 1px 12px;
  // background: #07080f;
  background: rgba(50, 54, 78, 0.3);
  border-radius: 12px;

  &-item {
    width: 276px;
    // width: 300px;
    height: 64px;
    border-radius: 6px;
    margin-bottom: 15px;
    background: linear-gradient(90deg, #192cfc 0%, #7a00ec 100%);
    position: relative;
    @include flexRow;
    justify-content: flex-start;
    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }

    .fixTop {
      position: absolute;
      right: 0;
      top: 0;
      background: #159046;
      z-index: 1;
      width: 58px;
      height: 20px;
      @include flexRowCenter;
      border-radius: 0 6px 0 6px;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
    }

    img {
      width: 216px;
      height: 74px;
    }
    .item-img {
      height: 64px;
      width: fit-content;
      display: inline;
    }
    font: {
      weight: 900;
      size: 24px;
    }

    line-height: 74px;
    color: #fff;

    &:last-of-type {
      background: linear-gradient(
        37deg,
        #b81ac5 11%,
        #7a3dfc 17%,
        #5f0036 100%
      );
    }
  }
}
</style>
