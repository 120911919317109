<template>
  <div class="loading-comp">
    <div class="loading">
      <div class="shape shape-1"></div>
      <div class="shape shape-2"></div>
      <div class="shape shape-3"></div>
      <div class="shape shape-4"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "com-loaing",
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.loading {
  width: 100px;
  height: 10px;
  position: relative;
  @include flexRowCenter;
  gap: 0 20px;
}

.shape {
  width: 10px;
  height: 10px;
  // position: absolute;
  // top: 40%;
  opacity: 0.5;
  border-radius: 50%;
}

.shape-1 {
  background-color: #1875e5;
  // left: -20px;
  animation: animationShape1 1s ease infinite;
}

.shape-2 {
  background-color: #c5523f;
  // left: 0px;
  animation: animationShape2 1s ease infinite 0.3s;
}

.shape-3 {
  background-color: #499255;
  // left: 20px;
  animation: animationShape3 1s ease infinite 0.5s;
}

.shape-4 {
  background-color: #f2b736;
  // left: 40px;
  animation: animationShape4 1s ease infinite 0.7s;
}

@keyframes animationShape1 {
  0% {
    transfrom: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transfrom: scale(0.5);
    opacity: 0.5;
  }
}

@keyframes animationShape2 {
  0% {
    transfrom: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transfrom: scale(0.5);
    opacity: 0.5;
  }
}

@keyframes animationShape3 {
  0% {
    transfrom: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transfrom: scale(0.5);
    opacity: 0.5;
  }
}

@keyframes animationShape4 {
  0% {
    transfrom: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transfrom: scale(0.5);
    opacity: 0.5;
  }
}
</style>
