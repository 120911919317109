import request from "@/service/request";

export function promosiType(params) {
  return request({
    url: "/article/type-list-v2",
    method: "get",
    params,
  });
}

export function promosiList(data) {
  return request({
    url: "/article/list-v2",
    method: "post",
    data,
  });
}
