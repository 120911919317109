<template>
  <div class="home">
    <!-- 头 -->
    <page-header :isHome="true" :isTabPage="true" :pageTitle="$t('app.home')" />
    <div v-for="item in homeData" :key="item.id">
      <!-- 标题 -->
      <block-title
        v-if="
          item.type != 101 &&
          item.type != 102 &&
          item.type != 103 &&
          item.type != 106
        "
        class="blockWidth"
        :title="
          item.type == 105 && item.content == 'Favorites'
            ? 'Favorites'
            : item?.title
        "
        :icon="item?.imageUrl"
        :hasRight="item.btnName ? true : false"
        :right="item?.btnName"
        :link="item?.link"
      />

      <!-- banner -->
      <template v-if="item.type == 101">
        <swiper-combine
          :bannerList="item.subCardList"
          :swiperOptions="swiperOptions(item.subCardList.length)"
          :classname="device == 'desktop' ? 'bottomUnder' : ''"
          :class="device == 'desktop' ? 'blockWidth' : ''"
        />
      </template>

      <!-- 滚动通知条 -->
      <div class="blockWidth bar-wrap" v-if="item.type == 102">
        <van-notice-bar
          :text="item.content"
          :color="
            device == 'mobile' ? 'rgba(255,255,255,.9)' : 'rgba(255,255,255,.7)'
          "
          speed="40"
          :background="device == 'mobile' ? '#0C0F1C' : '#1F2232'"
          scrollable
          :left-icon="require('@icon/notice.png')"
        />
      </div>

      <!-- 数额滚动 -->
      <div
        v-if="item.type == 103"
        class="number_wrap"
        :style="{
          backgroundImage:
            'url(' +
            (device == 'desktop'
              ? require('@icon/jackpot.png')
              : item.imageUrl) +
            ')',
        }"
      >
        <!-- 点 -->
        <template v-if="device == 'desktop'">
          <div class="line-top-dot"><span v-for="i in 14" :key="i"></span></div>
          <div class="line-top-dot line-top-dot-right">
            <span v-for="i in 14" :key="i"></span>
          </div>
          <div class="line-bottom-dot">
            <span v-for="i in 36" :key="i"></span>
          </div>
          <div class="line-left-dot">
            <span v-for="i in 2" :key="i"></span>
          </div>
          <div class="line-right-dot">
            <span v-for="i in 2" :key="i"></span>
          </div>
        </template>

        <div class="number">IDR&ensp;{{ startRoll(item.content) }}</div>
      </div>

      <!-- 中奖滚动 -->
      <template v-if="item.type == 104">
        <vue-seamless-scroll
          :data="item.subCardList"
          class="blockWidth winRoll"
          :class-option="classOption"
          :style="cssVars(item.subCardList.length)"
        >
          <!-- $px2rem(item.subCardList.length * 144) -->
          <ul class="winRoll-ul">
            <li
              class="winRoll-item"
              v-for="(prize, index) in item.subCardList"
              :key="index"
              @click="onRollClick(prize.link)"
            >
              <div class="winRoll-item-left">
                <img :src="prize.imageUrl" :alt="prize.title" />
                {{ prize.title }}
              </div>
              <div class="winRoll-item-right">
                <p class="van-ellipsis">{{ prize.content }}</p>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </template>

      <!-- 热门 -->
      <div class="wide" v-if="item.type == 105 && item.content != 'Favorites'">
        <game-item
          v-for="hot in item.subCardList"
          :key="hot.id"
          :imgUrl="hot.imageUrl"
          :title="hot.title"
          :imgWidth="104"
          @click.native="$toLink(hot.link)"
        ></game-item>
      </div>

      <!-- 分类 -->
      <div class="tabs" v-if="item.type == 106" ref="tabsWrap">
        <div class="tabs-title wide" ref="tabs">
          <span
            :class="[{ active: index === current }, 'tabs-title-item']"
            v-for="(tab, index) in item.subCardList"
            :key="tab.id"
            @click="handleTabChange($event, index)"
          >
            <img :src="tab.imageUrl" alt="index" />{{ tab.title }}
          </span>
        </div>
        <div>
          <div
            class="blockWidth classify"
            v-for="(tabs, index) in item.subCardList"
            :key="tabs.id"
            v-show="index === current"
          >
            <game-item
              v-for="i in tabs.subCardList"
              :key="i.id"
              :imgUrl="i.imageUrl"
              :title="i.title"
              :imgWidth="device == 'mobile' ? 111 : 91"
              @click.native="$toLink(i.link)"
            />
            <empty-block
              :text="$t('message.ept')"
              v-show="!tabs.subCardList || tabs.subCardList.length <= 0"
            />
          </div>
          <div class="more" @click="$toLink(item.link)">
            {{ item.btnName }}
          </div>
        </div>
      </div>

      <!-- 收藏 -->
      <div
        :class="[
          'wide',
          { 'wide-vw': item.subCardList.length <= 0 || !item.login },
        ]"
        v-if="item.type == 105 && item.content == 'Favorites'"
      >
        <template v-if="item.login">
          <template v-if="item.subCardList.length > 0">
            <game-item
              v-for="fav in item.subCardList"
              :key="fav.id"
              :imgUrl="fav.imageUrl"
              :title="fav.title"
              :imgWidth="104"
              @click.native="$toLink(fav.link)"
            />
          </template>
          <template v-else>
            <empty-block />
          </template>
        </template>
        <template v-else>
          <div class="needLogin">
            <div class="needLogin-btn" @click="$openl">
              {{ $t("account.loginBtn") }}
            </div>
            <p>{{ $t("account.logShowFav") }}</p>
          </div>
        </template>
      </div>

      <!-- 提供商 -->
      <div class="blockWidth flexWrap provider" v-if="item.type == 107">
        <div
          class="provider-item"
          v-for="provider in item.subCardList"
          :key="provider.id"
          @click="$toLink(provider.link)"
        >
          <img :src="provider.imageUrl" :alt="provider.title" />
          <span class="provider-item-name" :title="provider.title">{{
            provider.title
          }}</span>
        </div>
      </div>
    </div>
    <!-- login -->
    <!-- <div class="blockWidth login-notify" v-show="!token && device == 'mobile'">
      <p>
        <img src="@icon/pointer.png" alt="login" /> {{ device }} klik untuk
        mendaftar atau masuk
      </p>
      <div class="login-notify-btn" @click="$openl">login</div>
    </div> -->
    <comp-loading class="_compLoaing" v-show="pageLoading" />
    <!-- fixed两个按钮 v-show="device == 'mobile'" -->
    <back-to-top :visibility-height="device == 'mobile' ? 450 : 1000" />
    <more-support v-show="device == 'mobile'" />
    <!-- 首页弹窗 -->
    <van-overlay :show="popShow" :z-index="3">
      <div class="popWrap">
        <img :src="popImg" alt="" @click="$toLink(popUrl)" />
        <van-icon
          name="close"
          color="#fff"
          size="30"
          @click="popShow = false"
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { NoticeBar, Overlay } from "vant";
import vueSeamlessScroll from "vue-seamless-scroll";
import BackToTop from "./BackToTop.vue";
import MoreSupport from "./MoreSupport.vue";
import SwiperCombine from "@/components/SwiperCombine.vue";
import BlockTitle from "@/components/BlockTitle.vue";
import { homeCard, homePop } from "@api/home";
import EmptyBlock from "@/components/EmptyBlock.vue";
import { toThousands } from "@/utils/index";

export default {
  name: "HomeView",
  components: {
    vueSeamlessScroll,
    BackToTop,
    MoreSupport,
    SwiperCombine,
    BlockTitle,
    EmptyBlock,
    [NoticeBar.name]: NoticeBar,
    [Overlay.name]: Overlay,
  },
  data() {
    return {
      current: 0,
      homeData: [],
      pageLoading: true,
      interval: null,
      numShow: 0,
      popShow: false,
      popImg: "",
      popUrl: "",
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log(from, to);
  //   next();
  // },
  watch: {
    token() {
      this.onGetHomeCard();
    },
    language() {
      this.onGetHomeCard();
    },
  },
  computed: {
    ...mapGetters(["device", "token", "userInfo", "language", "showPop"]),
    classOption() {
      let obj = null;
      if (this.device == "desktop") {
        obj = {
          step: 0.5,
          direction: 2,
          // waitTime: 1500,
          openTouch: false,
          limitMoveNum: 10,
          // singleWidth: 2.13,
          // isSingleRemUnit: true,
        };
      } else {
        obj = {
          step: 0.3,
          direction: 2,
          limitMoveNum: 6,
          openTouch: false,
          // singleWidth: 1.91,
          waitTime: 1000,
          hoverStop: false,
          // isSingleRemUnit: true,
        };
      }
      return obj;
    },

    startRoll() {
      return function (num) {
        if (!this.interval) {
          this.numShow = num;
          this.onRollFunc();
        }
        return toThousands(this.numShow);
      };
      // return toThousands(this.numShow);
    },
  },
  created() {
    this.onGetHomeCard();
  },
  mounted() {},
  methods: {
    ...mapMutations("user", ["SET_POP"]),
    swiperOptions(length) {
      let obj = {
        spaceBetween: 10,
        centeredSlides: true,
        effect: "coverflow",
        slidesPerView: "auto",
        loop: true, //循环
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          click: (e) => {
            this.$toLink(e.target.dataset.link);
          },
        },
      };
      if (this.device == "desktop") {
        obj.effect = "slide";
        obj.centeredSlides = false;
        if (length < 2) {
          obj.loop = false;
          delete obj.pagination;
          delete obj.autoplay;
        } else {
          obj.slidesPerView = 2;
          obj.navigation = {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          };
        }
      }
      return obj;
    },
    cssVars(length) {
      return {
        "--length": length,
      };
    },
    onRollFunc() {
      this.interval = setInterval(() => {
        this.numShow = parseInt(
          Math.floor(Math.random() * (9999 - 1000)) +
            1000 +
            parseInt(this.numShow)
        );
      }, 2000);
    },
    onRollClick(url) {
      this.$toLink(url);
    },

    handleTabChange(e, i) {
      this.$nextTick(() => {
        this.current = i;
        let tabs = this.$refs.tabsWrap[0].firstChild,
          nowaElement = e.target;
        let width =
          nowaElement.offsetLeft -
          (tabs.offsetWidth - nowaElement.offsetWidth) / 2;
        tabs.scrollTo({ left: width < 0 ? 0 : width, behavior: "smooth" });
      });
    },
    getPopData() {
      homePop().then((res) => {
        if (res.code == 200 && res.data) {
          this.popImg = res.data.imageUrl;
          this.popUrl = res.data.link;
          this.popShow = true;
          this.SET_POP(true);
        }
      });
    },
    onGetHomeCard() {
      this.pageLoading = true;
      homeCard()
        .then((res) => {
          if (res.code == 200) {
            this.homeData = res.data?.cardList;
            this.pageLoading = false;
            if (!this.showPop) {
              this.getPopData();
            }
          } else {
            // TODO 失败页
            this.pageLoading = false;
          }
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 80px;
  position: relative;
  .popWrap {
    width: 80%;
    @media screen and (min-width: 720px) {
      width: 20%;
    }
    height: 100%;
    @include flexColumn;
    justify-content: center;
    margin: auto;
    font-weight: bold;
    img {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      border-radius: 10px;
    }
  }
}
.line-top-dot {
  position: absolute;
  top: 36px;
  left: 100px;
  width: calc((100% - 200px - 300px) / 2);
  @media screen and (min-width: 720px) and (max-width: 900px) {
    width: calc((100% - 200px - 80px) / 2);
    left: 50px;
  }
  right: 0;
  @include flexRowBetween;
  span {
    width: 15px;
    height: 15px;
    @media screen and (min-width: 720px) and (max-width: 900px) {
      width: 10px;
      height: 10px;
    }
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 10px 2px #ff5c00;
    &:nth-child(2n + 1) {
      // animation: name duration timing-function delay iteration-count direction fill-mode;
      animation: showLight 0.8s ease 0.4s infinite;
    }
    &:nth-child(2n) {
      animation: showLight 0.8s ease infinite;
    }
  }
}
.line-top-dot-right {
  @extend .line-top-dot;
  right: 100px;
  left: auto;
  @media screen and (min-width: 720px) and (max-width: 900px) {
    width: calc((100% - 200px - 80px) / 2);
    right: 50px;
  }
}
.line-bottom-dot {
  @extend .line-top-dot;
  top: auto;
  bottom: 8px;
  right: 100px;
  @media screen and (min-width: 720px) and (max-width: 900px) {
    right: 50px;
    left: 50px;
    width: calc((100% - 100px));
  }
  width: calc((100% - 200px));
}
.line-left-dot {
  @include flexColumn;
  position: absolute;
  top: 100px;
  gap: 5px;
  left: 8px;
  @media screen and (min-width: 720px) and (max-width: 900px) {
    left: 4px;
  }
  span {
    width: 15px;
    height: 15px;
    background: #ffffff;
    border-radius: 50%;
    @media screen and (min-width: 720px) and (max-width: 900px) {
      width: 10px;
      height: 10px;
    }
    box-shadow: 0 0 10px 2px #ff5c00;
    &:nth-child(2n + 1) {
      // animation: name duration timing-function delay iteration-count direction fill-mode;
      animation: showLight 0.8s ease 0.4s infinite;
    }
    &:nth-child(2n) {
      animation: showLight 0.8s ease infinite;
    }
  }
}
.line-right-dot {
  @extend .line-left-dot;
  right: 8px;
  left: auto;
  @media screen and (min-width: 720px) and (max-width: 900px) {
    right: 4px;
  }
}

.more {
  color: #17cc5f;
  border-bottom: 1px solid #17cc5f;
  font-size: 32px;
  width: fit-content;
  margin: 42px auto 0 auto;
  @include flexRowCenter;
  cursor: pointer;
}
@keyframes showLight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.tabs-title {
  @include flexRow;
  gap: 0 20px;
  padding-right: 20px;
  &-item {
    cursor: pointer;
    flex: {
      wrap: wrap;
      shrink: 0;
      grow: 0;
    }
    &:hover {
      background: rgb(23 121 62 / 47%);
    }
    border-radius: 8px;
    // min-width: 208px;
    padding: 0 14px;
    height: 65px;
    box-sizing: border-box;
    @include flexRowCenter;
    margin: 40px 0 30px 0;
    background: $tab-unchoose;
    font: {
      weight: bold;
      size: 28px;
    }
    color: #fff;
    img {
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }
  }
  &-item.active {
    background: $tab-choose;
  }
}

.flexWrap {
  @include flexRow;
  flex: {
    wrap: wrap;
    shrink: 0;
    grow: 0;
  }
}
.classify {
  gap: 20px 20px;
  @include flexRow;
  flex-wrap: wrap;
}
.provider {
  gap: 20px 12px;
  @media screen and (min-width: 720px) {
    width: calc(100% - 20px);
    margin: auto 0 0 20px;
  }
  &-item {
    background: $block-main;
    border-radius: 8px;
    width: 168px;
    height: 206px;
    @include flexColumn;
    justify-content: center;
    color: #fff;
    font-size: 25px;
    text-align: center;
    &:hover {
      @media screen and (min-width: 720px) {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    @media screen and (min-width: 1000px) {
      width: calc((100% - 5 * 12px) / 8);
    }
    @media screen and (min-width: 720px) and (max-width: 1000px) {
      width: calc((100% - 4 * 12px) / 6);
    }
    img {
      width: 104px;
      height: 104px;
      margin-bottom: 20px;
    }
    &-name {
      width: 80%;
      @include ellipse1;
    }
  }
}

.winRoll {
  margin: auto;
  // background: #0a0c17;
  // border-radius: 17px;
  overflow: hidden;
  height: 185px;
  font-weight: 900;

  @media screen and (min-width: 720px) {
    background: none;
    height: 223px;
    // width: calc(var(--length) * 160px);
  }
  &-ul {
    width: calc(var(--length) * 141px);
    @media screen and (min-width: 720px) {
      width: calc(var(--length) * 160px);
    }
  }
  &-item {
    @include flexColumn;
    display: inline-flex;
    justify-content: center;
    margin-left: 16px;
    &:hover {
      @media screen and (min-width: 720px) {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    @media screen and (min-width: 720px) {
      /* prettier-ignore */
      // width: 144PX;
      padding: 0 0 10px 0;
      background: #1c1f2e;
      border-radius: 6px;
      /* prettier-ignore */
      // margin-left: 20PX;
      // float: left;
    }
    &-left {
      @include flexColumn;
      img {
        width: 125px;
        height: 125px;
        border-radius: 8px;
        margin-bottom: 8px;
        // margin-right: 20px;
        @media screen and (min-width: 720px) {
          width: 144px;
          /* prettier-ignore */
          height: 144px;
          // margin-bottom: 10px;
          // margin-right: 0;
        }
      }
      font: {
        size: 24px;
      }
      color: #fff;
      @media screen and (min-width: 720px) {
        font-weight: normal;
        color: rgba(255, 255, 255, 0.9);
      }
    }
    &-right {
      @include flexRow;
      color: $text-green;
      max-width: 125px;
      // @include ellipse1;
      font: {
        size: 20px;
      }
      @media screen and (min-width: 720px) {
        margin-top: 8px;
        // font-size: 26px;
        @include ellipse1;
      }
    }
  }
}
.number_wrap {
  height: fit-content;
  margin: 20px auto -10px auto;
  width: 100%;
  position: relative;
  height: 208px;
  background: {
    size: 100% 100%;
    repeat: no-repeat;
    position: top left;
  }
  @media screen and (min-width: 720px) {
    width: calc(100% - 40px);
  }
  position: relative;
  img {
    width: 100%;
  }
  .number {
    width: 100%;
    position: absolute;
    top: 41%;
    left: 0;
    right: 0;
    z-index: 2;
    @include flexRowCenter;
    @media screen and (min-width: 720px) {
      top: 44%;
    }
    color: #f9d008;
    font: {
      weight: bold;
      size: 60px;
    }
    letter-spacing: -2px;
  }
}

.needLogin {
  text-align: center;
  font-size: 32px;
  color: $text-gray4;
  width: 100%;
  margin-bottom: 20px;
  &-btn {
    width: 226px;
    height: 80px;
    color: #fff;
    font-weight: bold;
    font-size: 32px;
    border-radius: 8px;
    background: $btn-green;
    margin: 20px auto 40px auto;
    line-height: 80px;
    cursor: pointer;
  }
}
.wide-vw {
  margin-left: 0;
}

.login-notify {
  position: fixed;
  bottom: 140px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;
  z-index: 2;
  background: linear-gradient(91deg, #242737 0%, #33374e 100%);
  box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.67);
  border-radius: 13px;
  @include flexRowBetween;
  font-weight: bold;
  p {
    @include flexRowCenter;
  }
  img {
    width: 54px;
    height: 50px;
    margin-right: 20px;
  }
  color: #fff;
  font-size: 28px;
  &-btn {
    @include btn(150px, 62px, 8px, 28px, bold);
  }
}

.bar-wrap {
  background: #0c0f1c;
  border-radius: 18px;
  margin: 30px auto 0 auto;
  overflow: hidden;
}
</style>
