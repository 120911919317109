<template>
  <div class="empty">
    <img src="@icon/empty.png" alt="empty" />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "EmptyBlock",
  components: {},
  props: {
    text: {
      type: String,
      default() {
        return this.$t("account.noFav");
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.empty {
  width: 100%;
  @include flexColumn;
  text-align: center;
  color: $text-gray4;
  font-size: 32px;
  margin-bottom: 20px;
  img {
    width: 120px;
    height: 116px;
    display: block;
    margin: 0 auto 40px auto;
  }
}
</style>
