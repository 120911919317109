<template>
  <div class="homeRight">
    <!-- Notify 暂无-->
    <!-- <div class="homeRight-block">
      <block-title
        :isPcRight="true"
        title="Notify"
        :hasRight="true"
        :icon="require('@icon/nav_promosi1.png')"
      />
      <div class="notify-item" v-for="i in 3" :key="i">
        {{ i }}. Valor a ser desbloqueado Valor a serai desbloqueado Valor aerai
        desbloqueado Valor aerai desbloqueado Valor a ser.
      </div>
    </div> -->

    <!-- Promosi -->
    <div class="homeRight-block" v-show="list.length > 0">
      <block-title
        :isPcRight="true"
        title="Promosi"
        :hasRight="true"
        :icon="require('@icon/nav_promosi1.png')"
        @click.native="handleClick"
      />
      <swiper class="promosi-swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <img :src="item.banner" alt="" class="promosi-swiper-img" />
          <div class="promosi-swiper-content">
            {{ item.title }}
          </div>
        </swiper-slide>
        <div
          class="swiper-pagination swiper-pagination2"
          slot="pagination"
        ></div>
      </swiper>
    </div>

    <!-- Contact -->
    <div class="homeRight-block">
      <block-title
        :isPcRight="true"
        title="Contact Us"
        :icon="require('@icon/pccontact.png')"
      />
      <div class="contact-item">
        <img src="@icon/tel.png" alt="" class="contact-item-icon" />
        <div
          class="contact-item-center"
          v-clipboard:copy="'+62 81393843827'"
          v-clipboard:success="onCopied"
          v-clipboard:error="onFailed"
        >
          Whats App
          <span class="contact-item-green">+62 81393843827</span>
        </div>
        <!-- @click="goTel" -->
        <img
          src="@icon/copy.png"
          v-clipboard:copy="'+62 81393843827'"
          v-clipboard:success="onCopied"
          v-clipboard:error="onFailed"
          alt=""
          class="contact-item-copy"
        />
      </div>
      <div
        class="contact-item"
        style="margin-bottom: 0"
        @click="$toLink('Brand888://gateway->connectUs')"
      >
        <img src="@icon/kf.png" alt="" class="contact-item-icon" />
        <div class="contact-item-center">Live Support</div>
        <van-icon name="arrow" color="#fff" />
      </div>
    </div>

    <!-- Download -->
    <!-- <div class="homeRight-block">
      <block-title
        :isPcRight="true"
        title="Download App"
        :icon="require('@icon/pcdwn.png')"
      />
      <img src="@icon/appCode.png" alt="" class="appUrl" />
    </div> -->

    <!-- about us -->
    <!-- <div class="homeRight-block">
      <block-title
        :isPcRight="true"
        :icon="require('@icon/about.png')"
        title="About Us"
      />
      <div class="aboutus">
        <img src="" alt="" />
        1. A invites B, and after B successfully registers and recharges, A will
        receive 3% of B's recharge amount;<br />
        2. A invites B,B invites C, and after C successfully registers and
        recharges, B gets 3% of C's recharge amount, A gets 1% of C's recharge
        amount; There is a delay in receiving top-up success data, so rewards
        may be posted to your account later; The Bonnus balance cannot be
        withdrawn.<br />
        3. You can use it to play games and earn profits, and we will deduct
        them first.The additional earnings generated can be used for
        withdrawal;5. Any invitation relationship created through illegal or
        impermissible cheating will be considered abnormal.<br />
        4. After verification, we will take certain measures against you,
        including but not limited to deducting bonuses, closing accounts, etc.
        Hope you can invite new users through the normal way and have fun.
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { promosiList } from "@api/promosi";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "homeRight",
  components: {
    swiper,
    swiperSlide,
  },
  props: {},
  data() {
    return {
      swiperOption: {
        spaceBetween: 10,
        // centeredSlides: true,
        effect: "slide",
        slidesPerView: 1,
        loop: true, //循环
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination2",
          clickable: true,
        },
      },
      list: [],
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["device"]),
  },
  methods: {
    goTel() {
      window.location.href = "tel://+62 81393843827";
    },
    onGetPromosi() {
      promosiList({
        type: "ARTICLE",
        pageNum: 1,
        pageSize: 15,
      }).then((res) => {
        this.list = res.data.list;
      });
    },
    onCopied() {
      this.$toast(this.$t("message.copied"));
    },
    onFailed() {
      this.$toast(this.$t("message.copyfailed"));
    },
    handleClick() {
      this.$router.push("/promosi");
    },
    goDetail(detail) {
      if (detail.content.startsWith("http")) {
        window.location.href = detail.content;
      } else {
        this.$store.dispatch("app/setPageInfo", detail);
        this.$router.push("/promosiDetail");
      }
    },
  },
  created() {},
  mounted() {
    if (this.device == "desktop") {
      // 请求promosi
      this.onGetPromosi();
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep.swiper-pagination2 {
  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
  }
  .swiper-pagination-bullet-active {
    background-color: $text-green;
  }
}
.promosi-swiper {
  cursor: pointer;
  width: 350px;
  margin-top: 10px;
  .swiper-slide {
    // width: 100%;
    margin: auto;
    // padding-bottom: 20px;
  }
  &-img {
    width: 100%;
    // height: 154px;
    border-radius: 4px;
  }
  &-content {
    margin: 10px 0;
    padding-bottom: 20px;
    color: $text-gray7;
    font-size: 20px;
    @include ellipse1;
  }
}
.homeRight {
  box-shadow: -15px 0 12px 0px rgba(0, 0, 0, 0.25);
  &-block {
    cursor: pointer;
    background: #1f2232;
    border-radius: 6px;
    padding: 20px 22px;
    margin-bottom: 25px;

    .contact-item {
      // width: 362px;
      // height: 62px;
      margin-bottom: 10px;
      @include flexRowBetween;
      background: #111423;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #1f272f;
      padding: 20px 12px;
      box-sizing: border-box;
      color: $text-gray7;
      font-size: 20px;
      font-weight: bold;
      white-space: nowrap;

      &-center {
        flex: 1;
        padding-left: 10px;
      }
      &-green {
        color: #17cc5f;
        font-weight: normal;
        font-size: 18px;
      }

      &-icon {
        width: 30px;
        height: 30px;
      }
      &-copy {
        width: 20px;
        height: 20px;
      }
    }

    .notify-item {
      color: $text-gray7;
      font-size: 20px;
      @include ellipse2;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      // padding: 20px 0;
      padding-top: 20px;
      margin-top: 20px;
      max-height: 100px;
      overflow: hidden;
      box-sizing: border-box;
      &:nth-of-type(2) {
        border-top: none;
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    .appUrl {
      width: 164px;
      height: 164px;
      border-radius: 6px;
      margin: 0 auto 24px auto;
      display: block;
      border: 1px solid #fff;
    }

    .aboutus {
      color: $text-gray7;
      font-size: 16px;
      line-height: 1.5;
      padding: 0 4px;

      img {
        width: 110px;
        height: 110px;
        display: block;
        margin: 20px auto;
      }
    }
  }
}
</style>
