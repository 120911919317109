function isNull(val) {
  if (val === null) return true;
  return false;
}
// 手机号效验
export const validatePhone = (rule, value, callback) => {
  const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
  if (value === "") {
    callback(new Error("请输入手机号"));
  } else if (!reg.test(value)) {
    callback(new Error("手机号格式不正确"));
  } else {
    callback();
  }
};

// 非必填邮箱验证
export function checkEmail(value) {
  if (value === "" || value === undefined || value === null) {
    return true;
  } else {
    return /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
      value
    );
  }
}

export function checkEmpty(value) {
  return value === "" || value === undefined || value === null;
}

// 非必填手机号效验
export const validatePhoneUn = (rule, value, callback) => {
  // const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
  const reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
  if (value === "" || value === undefined || value === null) {
    callback();
  } else if (!reg.test(value)) {
    callback(new Error("手机号格式不正确"));
  } else {
    callback();
  }
};
// 只能输入英文、数字
export const validateFloatEnglist = (rule, value, callback) => {
  if (isNull(value)) return callback();
  if (!/^[A-Za-z0-9]+$/.test(value)) {
    return callback(new Error("请输入英文字母或数字"));
  } else {
    return callback();
  }
};
// 只能输入英文、数字、汉字
export const englishNumbersChineseCharacters = (rule, value, callback) => {
  if (isNull(value)) return callback();
  if (!/^[A-Za-z0-9\u4E00-\u9FA5]+$/.test(value)) {
    return callback(new Error("请输入汉字、英文（含大小写）和数字"));
  } else {
    return callback();
  }
};

// 不能输入汉字
export const validateNotChineseCharacters = (rule, value, callback) => {
  if (/[\u4E00-\u9FA5]/g.test(value)) {
    return callback(new Error("不能输入汉字"));
  } else {
    return callback();
  }
};

// 不能输入空格
export const validateSpace = (rule, value, callback) => {
  if (!/^[^\s]*$/.test(value)) {
    return callback(new Error("不能输入空格"));
  } else {
    return callback();
  }
};

// 网址校验
export const validateURL = (rule, value, callback) => {
  if (
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/.test(
      value
    )
  ) {
    return callback(new Error("网址格式有误"));
  } else {
    return callback();
  }
};

// 不能输入特殊字符
export const validateSpechars = (value) => {
  const flag = new RegExp(
    "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
  );
  return flag.test(value);
};
// 可保留两位小数点的数字
export const validateFloatTwo = (rule, value, callback) => {
  if (isNull(value)) return callback();
  if (!/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
    return callback(new Error("请输入数字（允许保留两位小数点）"));
  } else {
    return callback();
  }
};
// 可保留四位小数点的数字
export const validateFloatFour = (rule, value, callback) => {
  if (isNull(value)) return callback();
  if (!/^[0-9]+(.[0-9]{1,4})?$/.test(value)) {
    return callback(new Error("请输入数字（允许保留四位小数点）"));
  } else if (value > 99999999999999.9999) {
    return callback(new Error("数字超出最大限制"));
  } else {
    return callback();
  }
};
// 数字
export const validateNumber = (rule, value, callback) => {
  if (isNull(value)) return callback();
  if (!/^[0-9]+$/.test(value)) {
    return callback(new Error("请输入数字"));
  } else {
    return callback();
  }
};
// 验证码4-6位数字
export const verificationCode = (rule, value, callback) => {
  if (!/[0-9]{6}/g.test(value)) {
    return callback(new Error("请输入6位数字"));
  } else {
    return callback();
  }
};

// 非必填数字
export const validateNumberNu = (rule, value, callback) => {
  if (value === "" || value === undefined || value === null) {
    callback();
    return;
  }
  if (!/^[0-9]+$/.test(value)) {
    return callback(new Error("请输入数字"));
  } else {
    return callback();
  }
};
// 密码校验 数字+字母；字母+特殊字符，特殊字符+数字
export const validatePassword = (rule, value, callback) => {
  if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/.test(value)) {
    return callback(
      new Error("请输入包含大小写字母、数字、特殊符号任意两种的6到20位的密码")
    );
  } else {
    return callback();
  }
};
// 密码校验 数字+字母；字母+特殊字符，特殊字符+数字 (指定特殊字符)
export const validatePassword1 = (rule, value, callback) => {
  const reg =
    /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![!#$%^&*]+$)(?!([^(0-9a-zA-Z)])+$)^.{8,20}$/;

  if (!reg.test(value)) {
    // ^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)^.{8,16}$
    return callback(
      new Error("数字、大小写字母、特殊符号至少包含两种，长度8-20位")
    );
  } else {
    return callback();
  }
};

// 获取按钮权限
export function getCurrentBtn(menuList, route) {
  let btnlist = "";
  const getCode = (list) => {
    list.map((item) => {
      if (item.name === route) {
        if (item.buttons) {
          btnlist = item.buttons;
        }
      }
      if (item.children && item.children.length > 0) {
        getCode(item.children);
      }
    });
  };
  getCode(menuList);
  return btnlist || [];
}

// 递归获取切换的路由是否有权限
export function getCurrentPath(menuList, route) {
  let menuPath = "";
  const getCode = (list) => {
    list.map((item) => {
      if (item.path === route) {
        menuPath = item.path;
      }
      if (item.children && item.children.length > 0) {
        getCode(item.children);
      }
    });
  };
  getCode(menuList);
  return menuPath || "/404";
}

export function dealBigMoney(n) {
  const fraction = ["角", "分"];
  const digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  const unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"],
  ];
  const head = n < 0 ? "欠" : "";
  n = Math.abs(n);

  let s = "";

  for (let i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);

  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = "";
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
}

let timeout;
export function _Debounce(func, wait = 500, immediate = false) {
  // 清除定时器
  if (timeout !== null) clearTimeout(timeout);
  // 立即执行，此类情况一般用不到
  if (immediate) {
    const callNow = !timeout;
    timeout = setTimeout(function () {
      timeout = null;
    }, wait);
    if (callNow) typeof func === "function" && func();
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(function () {
      typeof func === "function" && func();
    }, wait);
  }
}

export function _getChildNode(list) {
  const aList = [];
  for (let index = 0; index < list.length; index++) {
    const length = list[index].length;
    aList.push(list[index][length - 1]);
  }
  return aList;
}
