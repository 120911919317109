import store from "@/store";

const { body } = document;
const WIDTH = 719; // Surface 3

export default {
  // watch: {
  //   $route() {
  //     if (this.device === "mobile" && this.sidebar.opened) {
  //       store.dispatch("app/closeSideBar", { withoutAnimation: false });
  //     }
  //   },
  // },
  beforeMount() {
    window.addEventListener("resize", this.$_resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.$_resizeHandler);
  },
  mounted() {
    const isMobile = this.$_isMobile();
    if (isMobile) {
      store.dispatch("app/toggleDevice", "mobile");

      // store.dispatch("app/closeSideBar", { withoutAnimation: true });
    } else {
      store.dispatch("app/toggleDevice", "desktop");
      // this.setScale();
    }
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile();

        store.dispatch("app/toggleDevice", isMobile ? "mobile" : "desktop");

        if (isMobile) {
          store.commit("user/SET_OpenOearate", false);
          store.commit("user/SET_OPEN", false);
          // store.dispatch("app/closeSideBar", { withoutAnimation: true });
        } else {
          // this.setScale();
        }
      }
    },
    getScale() {
      // let designWidth = 1500; //设计稿的宽度，根据实际项目调整
      let designHeight = 843; //设计稿的高度，根据实际项目调整
      // let scale =
      //   document.documentElement.clientWidth /
      //     document.documentElement.clientHeight <
      //   designWidth / designHeight
      //     ? document.documentElement.clientWidth / designWidth
      //     : document.documentElement.clientHeight / designHeight;
      let scale = document.documentElement.clientHeight / designHeight;
      return scale;
    },
    setScale() {
      const rect = body.getBoundingClientRect().width;
      if (rect <= 1024) {
        let scale = this.getScale();
        this.$refs.box.style.setProperty("--scale", scale);
      }
    },
  },
};
