import router from "@/router";
import store from "@/store";
import { Toast } from "vant";
import { gameUrl } from "@api/home";
const toLink = (url) => {
  if (!url) {
    return;
  }
  let u = url.substr(0, 4);
  if (!u) {
    return;
  }
  if (u === "http") {
    // TODO
    if (store.getters.device == "desktop") {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }

    // router.push({
    //   path: `/gamesPlay?url=${url}`,
    // });
  } else {
    // Brand888://gateway->transIn
    let star = url.indexOf("->");
    let paramIndex = url.indexOf("{");
    let route = url.indexOf("&") > 0 ? url.indexOf("&") : 100;
    let param =
      url.indexOf("&") > 0 ? JSON.parse(url.substring(paramIndex)) : {};
    let page = url.substring(star + 2, route);
    console.log(page, "page------");
    console.log(param, "param-----");

    switch (page) {
      case "gamePlay":
        // router.push({
        //   path: `/gamesDetail?param=${param}`,
        // });
        router.push({ path: "/gamesDetail", query: { ...param } });
        break;
      case "openGame":
        console.log(param);
        Toast.loading({
          message: "",
          forbidClick: true,
          duration: 0,
        });
        gameUrl({
          gameId: param.id,
          demo: false,
        })
          .then((res) => {
            if (res.code == 200) {
              store.commit("app/setUrl", res.data);
              router.push({
                path: "/gamesPlay",
                query: {
                  id: param.id,
                  name: param.gameName,
                  isCollect: -1,
                  type: "",
                },
              });
              Toast.clear();
            } else {
              //
              Toast.clear();
            }
          })
          .catch(() => {
            Toast.clear();
          });
        // router.push({ path: "/gamesDetail", query: { ...param } });
        break;
      case "allGames":
        router.push({ path: "/games", query: { ...param } });
        break;
      case "transIn":
        //  充值页面
        if (store.getters.device == "desktop") {
          store.commit("user/SET_OpenOearate", true);
          store.commit("user/SET_TYPE", "recharge");
        } else {
          router.push({
            path: `/recharge`,
          });
        }
        break;
      case "transOut":
        //  提现页面
        if (store.getters.device == "desktop") {
          store.commit("user/SET_OpenOearate", true);
          store.commit("user/SET_TYPE", "withdraw");
        } else {
          router.push({
            path: `/withdraw`,
          });
        }
        break;
      case "transRecord":
        router.push({
          path: `/transaction`,
        });
        break;
      case "pwdChange":
        router.push({
          path: `/change`,
        });
        break;
      case "chrome":
        //  跳转外部浏览器
        try {
          // eslint-disable-next-line no-undef
          brand88Game.openLuckyBrowser(param.url);
          // openLuckyBrowser(param.url);
        } catch (error) {
          if (store.getters.device == "desktop") {
            window.open(param.url);
          } else {
            window.location.href = param.url;
          }
        }
        // window.location.href = param.url;
        break;
      case "connectUs":
        if (store.getters.device == "desktop") {
          if (store.getters.token) {
            store.commit("user/SET_OpenOearate", true);
            store.commit("user/SET_TYPE", "contact");
          } else {
            store.dispatch("user/setOpen", true).then(() => {
              // next();
            });
          }
        } else {
          router.push({
            path: `/contact`,
          });
        }
        break;
      case "vipPage":
        if (router.app._route.path != "/level") {
          router.push({
            path: `/level`,
          });
        }
        // router.push({
        //   path: `/level`,
        // });
        break;
      case "wallet":
        if (router.app._route.path != "/wallet") {
          router.replace({
            path: `/wallet`,
          });
        }
        // router.replace({
        //   path: `/wallet`,
        // });
        break;
      case "favorites":
        router.push({
          path: `/collect`,
        });
        break;
      case "invite":
        router.replace({
          path: `/invite`,
        });

        break;
    }
    return;
  }
};
export const link = toLink;
