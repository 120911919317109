import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store";
// 从语言包文件中导入语言包对象
import india from "./in";
import en from "./en";

Vue.use(VueI18n);

const messages = {
  india,
  en,
};
console.log(store.getters.language);
const i18n = new VueI18n({
  messages,
  locale: store.getters.language,
});
export default i18n;
