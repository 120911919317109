<template>
  <div class="login">
    <van-popup :value="openLogin" position="bottom" :style="{ height: '100%' }">
      <div class="login-wrap">
        <van-icon name="cross" color="#fff" @click="close" class="loginClose" />
        <img src="@/assets/indiaLogo.png" alt="logo" class="logo" />
        <p>{{ $t("account.welcome") }}</p>
        <p>{{ $t("account.loginTip") }}</p>
        <van-button
          class="login-btn hasColor"
          block
          :loading="loading"
          @click="handleGuestLogin"
        >
          {{ $t("account.gLogin") }}
        </van-button>
        <van-divider>{{ $t("account.or") }}</van-divider>
        <div class="login-btn-wrap">
          <div
            class="login-btn"
            block
            v-for="(item, index) in loginList"
            :key="index"
            @click="handleLogin(index)"
          >
            <img :src="item.icon" alt="login" />
          </div>
          <div class="login-btn" id="signin_button" ref="signin_button"></div>
        </div>
        <!-- <div
          class="login-btn"
          block
          v-for="(item, index) in loginList"
          :key="index"
          @click="handleLogin(index)"
        >
          <img :src="item.icon" alt="login" />
        </div> -->
      </div>
    </van-popup>
    <van-popup
      v-model="showLogin"
      position="bottom"
      closeable
      :style="{ height: '85%', background: 'none' }"
      round
    >
      <account-log @onlogSuccess="onlogSuccess"></account-log>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Popup, Divider } from "vant";
import AccountLog from "./accountLog.vue";
import { guestLogin, otherLogin } from "@api/account";
import { jwtDecode } from "jwt-decode";
export default {
  name: "MobileLogin",
  components: {
    AccountLog,
    [Popup.name]: Popup,
    [Divider.name]: Divider,
  },
  props: {},
  data() {
    return {
      showLogin: false,
      loading: false,
      loginList: [
        {
          icon: require("@icon/loginUser.png"),
          text: this.$t("account.aLogin"),
        },

        { icon: require("@icon/fb.png"), text: this.$t("account.fLogin") },
        // {
        //   icon: require("@icon/google.png"),
        //   text: this.$t("account.googleLogin"),
        // },
      ],
      clientId:
        // "772030329973-un996slel3s2s2lkhmh4im4m5a01vj5u.apps.googleusercontent.com",
        "482861086536-a0e028nm5gubmv9f4vsdsp91uk7d3078.apps.googleusercontent.com", //测试
    };
  },
  watch: {
    openLogin: {
      handler(val) {
        if (val && !this.$refs.signin_button) {
          console.log("once");
          this.rejectLoginBtn();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["openLogin", "appname"]),
  },
  methods: {
    ...mapActions("user", [
      "setToken",
      "setOpen",
      "getUserInfo",
      "getUserBalance",
    ]),
    close() {
      this.setOpen(false);
    },
    handleCredentialResponse(res) {
      const responsePayload = jwtDecode(res.credential);
      this.loginGoogle(responsePayload);
    },
    loginGoogle(data) {
      otherLogin({
        token: "",
        invitedCode: "",
        appName: this.appname,
        collapse: "0",
        thirdParty: 2,
        gddddd: "",
        network: "",
        googleUserId: data.sub,
        googleUserName: data.name,
        googleUserEmail: data.email,
      }).then((res) => {
        if (res.code == 200) {
          this.$toast({
            message: res.message,
          });
          this.onlogSuccess(res.data.authToken);
        }
      });
    },
    handleLogin(type) {
      if (type == 0) {
        this.showLogin = true;
      }
      if (type == 1) {
        // fb登录
        document.getElementById("signin_button").click();
        console.log(document.getElementById("signin_button"));
        // console.log(this.$refs.signin_button.$el);
        // this.$refs.signin_button.$el.click();
      }
      if (type == 2) {
        // google登录
      }
    },
    handleGuestLogin() {
      this.loading = true;
      guestLogin()
        .then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: res.message,
              onClose: () => {
                this.loading = false;
              },
            });
            this.onlogSuccess(res.data.authToken);
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onlogSuccess(token) {
      if (this.showLogin) {
        this.showLogin = false;
      }
      this.setToken(token);
      this.setOpen(false);
      this.getUserInfo();
      this.getUserBalance();
    },
    rejectLoginBtn() {
      var that = this;
      setTimeout(() => {
        if (window.google) {
          window.google.accounts.id.renderButton(
            that.$refs.signin_button,
            {
              size: "large",
              locale: "en-GB",
              theme: "filled_black",
              type: "icon",
              shape: "circle",
            } // customization attributes
          );
        }
      }, 1000);
    },
  },
  created() {},
  mounted() {
    let googleScript = document.createElement("script");
    googleScript.src = "https://accounts.google.com/gsi/client";
    document.head.appendChild(googleScript);
    googleScript.onload = () => {
      // const client = window.google.accounts.oauth2.initCodeClient({
      //   client_id: this.this.clientId,
      //   scope: "https://www.googleapis.com/auth/calendar.readonly",
      //   ux_mode: "popup",
      //   callback: (response) => {
      //     const xhr = new XMLHttpRequest();
      //     xhr.open("POST", code_receiver_uri, true);
      //     xhr.setRequestHeader(
      //       "Content-Type",
      //       "application/x-www-form-urlencoded"
      //     );
      //     // Set custom header for CRSF
      //     xhr.setRequestHeader("X-Requested-With", "XmlHttpRequest");
      //     xhr.onload = function () {
      //       console.log("Auth code response: " + xhr.responseText);
      //     };
      //     xhr.send("code=" + response.code);
      //   },
      // });
      window.google.accounts.id.initialize({
        client_id: this.clientId,
        callback: this.handleCredentialResponse,
        ux_mode: "popup",
      });
      // window.google.accounts.id.prompt();
    };
  },
};
</script>
<style lang="scss" scoped>
.login-wrap {
  background: linear-gradient(180deg, #003033 0%, #000000 100%);
  width: 100%;
  height: 100%;
  padding: 120px 38px 0 38px;
  box-sizing: border-box;
  .logo {
    width: 144px;
    height: 144px;
  }
  p {
    font-weight: bold;
    color: #fff;
    font-size: 50px;
    margin: 30px auto 0 auto;
  }

  .hasColor {
    background: #159046;
    margin: 160px auto 60px auto;
    border-color: #159046;
  }
  .loginClose {
    position: absolute;
    top: 50px;
    right: 40px;
  }
  .login-btn-wrap {
    @include flexRowCenter;
    gap: 0 40px;
    padding-top: 20px;
    .login-btn {
      border: none;
      height: auto;
      margin: 0;
      img {
        /* prettier-ignore */
        width: 36PX;
        /* prettier-ignore */
        height: 36PX;
        margin-left: 0;
      }
    }
  }
}
</style>
