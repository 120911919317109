<template>
  <div class="withdraw">
    <page-header
      :isHome="false"
      :isTabPage="false"
      :pageTitle="pageData?.pageTheme"
    />
    <van-notice-bar
      color="#ED7B2F"
      background="#FEF3E6"
      :left-icon="notice ? notice.noticePicture : require('@icon/notice.png')"
      mode="closeable"
      v-show="notice"
    >
      {{ notice?.noticeContent }}
    </van-notice-bar>
    <div class="blockWidth withdraw-block">
      <div class="withdraw-block-item border-bottom">
        <div class="withdraw-block-desc">{{ pageData?.firstOptionName }}</div>
        <div class="withdraw-block-value">{{ pageData?.firstOptionValue }}</div>
      </div>
      <div class="withdraw-block-item">
        <div class="withdraw-block-desc">{{ pageData?.secondOptionName }}</div>
        <div class="withdraw-block-value">
          {{ pageData?.secondOptionValue }}
        </div>
      </div>
    </div>
    <!-- TODO -->
    <div
      class="wallet-level"
      :style="{
        backgroundImage: 'url(' + pageData?.levelBackgroundPicture + ')',
      }"
      @click="handleLink(pageData?.levelDetailToUrl)"
    >
      <div class="wallet-level-left">
        <img :src="pageData?.levelPicture" alt="" />
        <span>{{ pageData?.levelContent }}</span>
      </div>

      <div class="wallet-level-right">
        {{ pageData?.levelDetailButton }}
        <!-- View details <van-icon name="arrow" color="#A73C00" size="15" /> -->
      </div>
    </div>
    <div class="blockWidth withdraw-block">
      <div class="withdraw-block-item border-bottom">
        <div class="withdraw-block-desc">{{ pageData?.thirdOptionName }}</div>
        <div class="withdraw-block-value">{{ pageData?.thirdOptionValue }}</div>
      </div>
      <div class="withdraw-block-item">
        <div class="withdraw-block-desc">{{ pageData?.fourthOptionName }}</div>
        <div class="withdraw-block-value">
          {{ pageData?.fourthOptionValue }}
        </div>
      </div>
    </div>
    <div class="blockWidth withdraw-block2">
      <div class="withdraw-block-item">
        <div class="withdraw-block-desc">
          {{ pageData?.fifthDefaultValue }} ({{ pageData?.fifthOptionValue }})
        </div>
      </div>
      <van-field
        v-model="value"
        :label="pageData?.fifthOptionValue"
        :placeholder="pageData?.fifthDefaultValue"
        :border="false"
        class="blockWidth account-field"
        label-class="account-field-label"
        type="number"
        :formatter="formatter"
      />
    </div>
    <van-button
      class="blockWidth hasColor"
      block
      @click="goWidthdraw"
      :disabled="value <= 0 || isNaN(value)"
    >
      {{ pageData?.button }}
    </van-button>
    <div class="blockWidth withdraw-title">{{ pageData?.ruleName }}</div>
    <div class="blockWidth withdraw-desc">
      {{ pageData?.ruleValue }}
    </div>
  </div>
</template>

<script>
import { NoticeBar, Field } from "vant";
import { withdrawPage } from "@api/wallet";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "withdrawView",
  components: {
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
  },
  props: {},
  data() {
    return {
      notice: null,
      pageData: null,
      value: undefined,
      maxAmount: 0,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["device"]),
  },
  methods: {
    ...mapMutations("user", ["SET_OpenOearate", "SET_TYPE"]),
    formatter(value) {
      return value.match(/^\d*(\.?\d{0,2})/g)[0];
    },
    onGetData() {
      withdrawPage().then((res) => {
        this.pageData = res.data;
        this.notice = res.data?.notice;
        this.maxAmount = res.data?.withdrawMaxAmount;
      });
    },
    goWidthdraw() {
      if (this.value > this.maxAmount) {
        return this.$toast(this.$t("wallet.maxWithdraw") + this.maxAmount);
      }
      if (this.device == "desktop") {
        this.SET_TYPE("withdraw2");
        this.$emit("typeAmount", this.value);
      } else {
        this.$router.push("/withdrawForm?amount=" + this.value);
      }
    },
    handleLink(url) {
      if (this.device == "desktop") {
        this.SET_OpenOearate(false);
      }
      this.$toLink(url);
    },
  },
  created() {
    this.onGetData();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.withdraw {
  padding-bottom: 40px;
  .wallet-level {
    margin-bottom: 0;
  }
  &-desc {
    color: $text-gray4;
    font-size: 24px;
    line-height: 1.5;
    white-space: pre-wrap;
  }
  &-title {
    @extend .withdraw-desc;
    font-size: 28px;
  }
  .hasColor {
    background: $btn-green;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 42px;
    border-radius: 16px;
    height: 114px;

    margin: 40px auto 20px auto;
  }
}
.withdraw-block {
  border-radius: 16px;
  background: #1c1f2e;
  margin: 20px auto;
  &-item {
    @include flexRowBetween;
    font-size: 28px;
    padding: 32px 20px;
  }
  &-desc {
    color: rgba(255, 255, 255, 0.7);
  }
  &-value {
    color: #fff;
    font-weight: bold;
  }
}
.withdraw-block2 {
  @extend .withdraw-block;
  padding-bottom: 30px;
}
</style>
