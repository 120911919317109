<template>
  <div class="moreSupport" id="more" :style="{ right: right + 'px' }">
    <van-popover
      v-model="showPopover"
      trigger="click"
      placement="left"
      get-container="#more"
      :offset="[-5, 0]"
    >
      <div class="icons">
        <!-- <img src="@icon/download.png" alt="download" @click="goDwn" /> -->
        <!-- <img
          src="@icon/contact.png"
          alt="contact"
          @click="$toLink('Brand888://gateway->connectUs')"
        /> -->
      </div>
      <template #reference>
        <img
          src="@icon/download.png"
          alt="more"
          class="moreSupport-icon"
          @click="goDwn"
        />
        <!-- 
          v-show="!showPopover"

         -->
        <!-- <van-icon name="close" class="moreSupport-icon" v-show="showPopover" /> -->
        <!-- <img
          src="@icon/more.png"
          alt="more"
          class="moreSupport-icon"
          v-show="showPopover"
        /> -->
      </template>
    </van-popover>
  </div>
</template>

<script>
import { Popover } from "vant";
export default {
  name: "MoreSupport",
  components: {
    [Popover.name]: Popover,
  },
  props: {},
  data() {
    return {
      showPopover: false,
      scrollTop: 0,
      right: 20,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleScroll() {
      this.right = -20;
      if (this.showPopover) {
        this.showPopover = false;
      }
    },
    handleScrollEnd() {
      this.right = 20;
    },
    goDwn() {
      window.location.href =
        "https://lucky777.s3.ap-southeast-3.amazonaws.com/apk/lucky777.apk";
    },
  },
  created() {},
  mounted() {
    let timeoutHdr = null;
    window.addEventListener("scroll", () => {
      this.right = -20;
      if (this.showPopover) {
        this.showPopover = false;
      }
      window.clearTimeout(timeoutHdr);
      timeoutHdr = window.setTimeout(() => {
        this.right = 20;
      }, 200);
    });
  },
  beforeDestroy() {},
};
</script>
<style>
.van-popover[data-popper-placement="left"] .van-popover__arrow {
  border: none;
}
</style>
<style lang="scss" scoped>
.moreSupport {
  position: fixed;
  cursor: pointer;
  @include flexRowCenter;
  z-index: 2;
  right: 40px;
  bottom: 240px;
  transition: all 0.2s;
  &-icon {
    width: 80px;
    height: 80px;
  }
}
.icons {
  @include flexRowCenter;
  gap: 0 30px;
  padding-right: 30px;
  img {
    width: 80px;
    height: 80px;
  }
}
</style>
