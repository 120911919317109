<template>
  <div class="footerWrap" v-if="isTabPage">
    <div class="footer">
      <div
        :class="['footer-nav', { selected: currentPath === item.path }]"
        v-for="item in navList"
        :key="item.index"
        @click="handleChangeRoute(item)"
      >
        <img
          :src="
            require('@icon/' +
              (currentPath === item.path
                ? item.selectedIconPath
                : item.iconPath) +
              '.png')
          "
          :alt="item.text"
          class="navIcon"
        /><span>{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  components: {},
  props: {
    current: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isTabPage: false,
      currentPath: "",
    };
  },
  watch: {
    $route: {
      handler(val) {
        const tabPage = ["/", "/invite", "/wallet", "/promosi", "/account"];
        if (tabPage.indexOf(val.path) > -1) {
          this.isTabPage = true;
          this.currentPath = val.path;
        } else {
          this.isTabPage = false;
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  computed: {
    navList() {
      return [
        {
          iconPath: "nav_home",
          selectedIconPath: "nav_home1",
          text: this.$t("app.home"),
          customIcon: "false",
          index: 1,
          path: "/",
        },
        {
          iconPath: "nav_invite",
          selectedIconPath: "nav_invite1",
          text: this.$t("app.invite"),
          customIcon: "false",
          index: 2,
          path: "/invite",
        },
        {
          iconPath: "nav_wallet",
          selectedIconPath: "nav_wallet1",
          text: this.$t("app.wallet"),
          customIcon: "false",
          index: 3,
          path: "/wallet",
        },
        {
          iconPath: "nav_promosi",
          selectedIconPath: "nav_promosi1",
          text: this.$t("app.promosi"),
          customIcon: "false",
          index: 4,
          path: "/promosi",
        },
        {
          iconPath: "nav_account",
          selectedIconPath: "nav_account1",
          text: this.$t("app.account"),
          customIcon: "false",
          index: 5,
          path: "/account",
        },
      ];
    },
  },
  methods: {
    handleChangeRoute(item) {
      if (item.path == this.currentPath) {
        return;
      }
      this.$router.replace(item.path);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.footerWrap {
  height: 116px;
  padding-top: 50px;
}
.footer {
  background: #1c1f2e;
  display: flex;
  height: 116px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  &-nav {
    flex: 1;
    font-weight: 500;
    font-size: 21px;
    color: #494c58;
    @include flexColumn;
    justify-content: flex-end;
    padding-bottom: 23px;
    .navIcon {
      width: 40px;
      height: 40px;
      margin-bottom: 6px;
    }
    &:nth-child(3) {
      .navIcon {
        width: 56px;
        height: 56px;
      }
    }
  }
  .selected {
    color: #fff;
  }
}
</style>
