import axios from "axios";
import store from "@/store";
import { Toast } from "vant";
const contentTypeFiles = [
  "application/vnd.ms-excel;charset=gb2312",
  "application/vnd.ms-excel;charset=utf-8",
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/pdf",
  "image/jpg",
  "application/octet-stream",
];
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  // withCredentials: true,
  // validateStatus(status) {
  //   return status >= 200 && status <= 500; // default
  // },
  timeout: 15000, // request timeout
  headers: {
    Authorization: "Bearer ",
    "Content-Type": "application/json",
    "x-app-version": 101,
    // "x-app-name": localStorage.getItem("appName") || process.env.VUE_APP_NAME,
  },
});
service.interceptors.request.use(
  (config) => {
    console.log(process.env.VUE_APP_NAME);
    config.headers["Accept-Language"] =
      store.getters.language == "en" ? "en" : "in";
    config.headers["x-app-name"] = store.getters.appname;
    config.headers["x-agent-type"] =
      store.getters.device == "desktop" ? "PC" : "H5";
    if (store.getters.token) {
      config.headers["Authorization"] = "Bearer " + store.getters.token;
    }
    if (config.url.indexOf("upload") > -1) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    if (config.method === "post") {
      const isFormData = config.data instanceof FormData;
      if (!isFormData) {
        config.data = {
          ...config.data,
        };
      }
    } else if (config.method === "get") {
      config.params = {
        ...config.params,
      };
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    const resContentType = response.headers["content-type"];
    if (contentTypeFiles.indexOf(resContentType) >= 0) {
      const resBlob = new window.Blob([res], { type: resContentType });
      return resBlob;
    } else {
      if (Number(res.code) !== 200) {
        if (Number(res.code) === 404) {
          Toast({
            message: res.message || "error",
            duration: 5 * 1000,
          });
          store.commit("user/logOut");
          store.dispatch("user/setOpen", true);
        } else {
          Toast({
            message: res.message || "fail",
            duration: 2 * 1000,
          });
        }
        return Promise.reject(new Error(res.message || res.msg || "Error"));
      } else {
        return res;
      }
    }
  },
  (error) => {
    const message = error.message.includes("timeout") ? "error" : error.message;
    Toast({
      message: message,
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
