<template>
  <div class="dwn_wrap">
    <div class="dwn_content">
      <div class="dwn_content_wrap">
        <img src="@imgs/coin.png" alt="download" class="coin" />
        <p class="dwn_content_title">Daftar</p>
        <div class="">
          <van-field
            v-model="reg.username"
            placeholder="Username"
            class="dwnField"
            required
            maxlength="12"
          />
          <p class="dwnField-tips">6-12 huruf, tanpa simbol</p>
          <van-field v-model="reg.email" placeholder="Email" class="dwnField" />
          <van-field
            v-model="reg.password"
            placeholder="Kata Sandi"
            class="dwnField"
            required
            maxlength="32"
          />
          <p class="dwnField-tips">
            8-32 karakter, masukkan kata sandi baru Anda
          </p>
          <van-field
            v-model="reg.confirmPassword"
            placeholder="Konfirmasi kata sandi"
            class="dwnField"
            required
            maxlength="32"
          />
          <van-field
            v-model="reg.invitedCode"
            placeholder="Kode Undangan"
            class="dwnField"
            :border="true"
            input-align="right"
            label="Kode Undangan"
            readonly
          />
          <p></p>
        </div>
        <div class="dwn_content_btns">
          <div class="dwn_content_btn btns-left" @click="handleDownload">
            Unduh
          </div>
          <van-button
            class="dwn_content_btn btns-right"
            :loading="regLoading"
            :disabled="!reg.username || !reg.password || !reg.confirmPassword"
            @click="handleRegister"
            >Daftar Dan Unduh
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validateSpechars, checkEmail } from "@/utils/tools";
import { apiRegist, getDownLoad } from "@api/account";

import { Field } from "vant";
export default {
  name: "downloadView",
  components: {
    [Field.name]: Field,
  },
  props: {},
  data() {
    return {
      reg: {
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        invitedCode: "",
        fullName: "",
      },
      regLoading: false,
      downLoadUrl: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleDownload() {
      if (!this.downLoadUrl) {
        this.$toast("error");
        return;
      }
      window.location.href = this.downLoadUrl;
    },
    getDownLoadUrl() {
      getDownLoad(this.reg.invitedCode, {}).then((res) => {
        this.downLoadUrl = res.data || "";
      });
    },
    handleRegister() {
      if (validateSpechars(this.reg.username) || this.reg.username.length < 6) {
        return this.$toast("6-12 huruf, tanpa simbol");
      }

      if (!checkEmail(this.reg.email)) {
        return this.$toast("Kotak pesan salah format");
      }

      if (this.reg.password.length < 8) {
        return this.$toast("8-32 karakter, masukkan kata sandi baru Anda");
      }
      if (this.reg.password != this.reg.confirmPassword) {
        return this.$toast("Kata sandi tidak konsisten");
      }
      this.regLoading = true;
      let postData = { ...this.reg };
      delete postData.confirmPassword;
      apiRegist(postData)
        .then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: res.message,
              duration: 4000,
              onClose: () => {
                this.regLoading = false;
              },
            });
            this.handleDownload();
          } else {
            this.regLoading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.regLoading = false;
        });
    },
  },
  created() {},
  mounted() {
    this.reg.invitedCode = this.$route.query.inviteCode || "";
    this.getDownLoadUrl();
  },
};
</script>
<style lang="scss">
.dwnField::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 0.42667rem;
  bottom: 0;
  left: 0.42667rem;
  transform: scaleY(0.5);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
}
.dwnField .van-field__control {
  font-size: 32px;
  color: #000000;
}
.dwnField input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.dwnField .van-field__label {
  font-size: 32px;
  color: #000;
  width: 50%;
}
.dwnField {
  margin-top: 30px;
}
.dwn_wrap {
  width: 100%;
  height: 100vh;
  background: url("@imgs/reg_p_bg.png") top left no-repeat;
  background-size: cover;
  position: relative;
}
.dwn_content {
  position: absolute;
  width: 708px;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  bottom: 0;
  border-radius: 24px 24px 0 0;
  padding: 30px 20px;
  box-sizing: border-box;
  z-index: 1;
  .dwn_content_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    .coin {
      position: absolute;
      z-index: 2;
      left: -43px;
      width: auto;
      height: 226px;
      top: -160px;
    }
  }

  &_title {
    font-weight: bold;
    font-size: 42px;
    color: #029c44;
    text-align: center;
  }
  &_btns {
    @include flexRowBetween;
    margin-top: 62px;
    .btns-left {
      background: #fc8800;
      width: 230px;
    }
    .btns-right {
      background: #019b7d;
      width: 414px;
    }
  }
  &_btn {
    height: 110px;
    line-height: 110px;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: #ffffff;
    border-radius: 16px;
  }

  .dwnField-tips {
    text-align: right;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.3);
    padding-top: 5px;
    height: 0;
  }
}
</style>
