import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

import HomeView from "@/views/home/homeView.vue";
import downloadView from "@/views/download/indexView.vue";
import Layout from "@/views/layout/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: "layoutIndex",
    component: Layout,
    children: [
      {
        path: "/",
        name: "HomeView",
        component: HomeView,
        meta: {
          title: "app.home",
        },
      },
      {
        path: "/download",
        name: "downloadView",
        component: downloadView,
        meta: {
          title: "app.home",
        },
      },
      // invite
      {
        path: "/invite",
        name: "inviteView",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/invite/inviteView.vue"),
      },
      {
        path: "/inviteRecord",
        name: "inviteRecord",
        component: () => import("@/views/invite/inviteRecord.vue"),
      },
      // wallet
      {
        path: "/wallet",
        name: "walletView",
        component: () => import("@/views/wallet/walletView.vue"),
        meta: {
          title: "app.wallet",
        },
      },
      {
        path: "/transaction",
        name: "transactionView",
        component: () => import("@/views/wallet/transactionView.vue"),
      },
      {
        path: "/recharge",
        name: "rechargeView",
        component: () => import("@/views/wallet/rechargeView.vue"),
      },
      {
        path: "/confirm",
        name: "confirmPay",
        component: () => import("@/views/wallet/confirmPay.vue"),
      },
      {
        path: "/pend",
        name: "pendView",
        component: () => import("@/views/wallet/pendView.vue"),
      },
      {
        path: "/withdraw",
        name: "withdrawView",
        component: () => import("@/views/wallet/withdrawView.vue"),
      },
      {
        path: "/withdrawForm",
        name: "withdrawForm",
        component: () => import("@/views/wallet/withdrawForm.vue"),
      },
      // prmosi
      {
        path: "/promosi",
        name: "promosiView",
        component: () => import("@/views/promosi/promosiView.vue"),
      },

      {
        path: "/promosiDetail",
        name: "promosiDetail",
        component: () => import("@/views/promosi/promosiDetail.vue"),
      },
      // account
      {
        path: "/account",
        name: "accountView",
        component: () => import("@/views/account/accountView.vue"),
      },
      {
        path: "/profile",
        name: "profileView",
        component: () => import("@/views/account/profileView.vue"),
      },
      {
        path: "/contact",
        name: "contactView",
        component: () => import("@/views/account/contactView.vue"),
      },
      {
        path: "/faq",
        name: "fAq",
        component: () => import("@/views/account/faq.vue"),
      },
      {
        path: "/level",
        name: "levelView",
        component: () => import("@/views/account/levelView.vue"),
      },
      {
        path: "/change",
        name: "changePwd",
        component: () => import("@/views/account/changePwd.vue"),
      },

      {
        path: "/games",
        name: "gamesView",
        component: () => import("@/views/games/gamesView.vue"),
      },
      {
        path: "/gamesDetail",
        name: "gamesDetail",
        component: () => import("@/views/games/gamesDetail.vue"),
      },
      {
        path: "/gamesPlay",
        name: "gamesPlay",
        component: () => import("@/views/games/gamesPlay.vue"),
      },

      {
        path: "/collect",
        name: "collectView",
        component: () => import("@/views/games/collectView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
